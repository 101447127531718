// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../shared/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../../../../../../shared/node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../../../../assets/images/icon_camera_avatar.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".comp_baby_info{display:-webkit-flex;display:flex;-webkit-flex:1.3;flex:1.3}.comp_baby_info a{display:block;position:relative;width:64px;height:64px;border-radius:50%;overflow:hidden}.comp_baby_info .to_add_avatar{position:absolute;bottom:0;left:0;height:12px;width:100%;background:rgba(0,0,0,.5);background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-size:12px 9px;background-position:50%;background-repeat:no-repeat}.comp_baby_info img{width:64px;height:64px;box-shadow:1px 2px 6px 0 rgba(221,219,212,.5)}.comp_baby_info dl{margin-left:10px;display:-webkit-flex;display:flex;-webkit-flex-direction:column;flex-direction:column;-webkit-justify-content:center;justify-content:center;-webkit-align-items:flex-start;align-items:flex-start}.comp_baby_info dl dt{font-weight:700;font-size:16px;line-height:1em;color:#444}.comp_baby_info dl dd{margin-top:8px;font-size:14px;line-height:1em;color:#999}", ""]);
// Exports
module.exports = exports;
