// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../shared/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../../../../shared/node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../../assets/images/mini_exams/questionnaire/course_audio_play.png");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../../../../assets/images/mini_exams/questionnaire/course_audio_playing.gif");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
exports.push([module.id, ".c4_recorder{font-size:14px;font-family:PingFang SC;font-weight:400;line-height:26px;color:#75470d}.c4_recorder,.c4_recorder .on_recording,.c4_recorder .question_audio_start,.c4_recorder .start_record{width:100%;display:-webkit-flex;display:flex;-webkit-flex-direction:column;flex-direction:column;-webkit-align-items:center;align-items:center;-webkit-justify-content:center;justify-content:center}.c4_recorder .question_audio_start_icon{width:70px;height:70px;margin-bottom:2px}.c4_recorder .end_recording,.c4_recorder .recording{display:-webkit-flex;display:flex;-webkit-justify-content:center;justify-content:center;-webkit-align-items:center;align-items:center}.c4_recorder .recording{-webkit-flex-direction:column;flex-direction:column;padding:0 82px}.c4_recorder .complete_record,.c4_recorder .re_record{text-align:center}.c4_recorder .common_record_style{padding:0 30px 0 0;height:34px;background:#f0f8ff;opacity:1;border-radius:50px;text-align:left;margin-bottom:30px}.c4_recorder .common_record_style span{font-size:14px;font-family:PingFang SC;font-weight:400;line-height:34px;color:#7ba6ef;opacity:1}.c4_recorder .play_record{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ")}.c4_recorder .play_record,.c4_recorder .play_record_active{padding-left:48px;background-repeat:no-repeat;background-size:17px;background-position:20px}.c4_recorder .play_record_active{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ")}", ""]);
// Exports
module.exports = exports;
