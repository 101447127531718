// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../shared/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../../../shared/node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../assets/images/icon_keyboard_delete.png");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../../../assets/images/icon_ketboard_close.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
exports.push([module.id, ".forcesToLeft_wrapp_width_keyboard{width:100%;height:100%;position:relative}.forcesToLeft_wrapp_width_keyboard .forcesToLeft_wrapped_component{position:relative}.wrapp_width_keyboard{min-height:100%;width:100%;display:-webkit-flex;display:flex;-webkit-flex-direction:column;flex-direction:column}.wrapp_width_keyboard .wrapped_component{width:100%}.awesome_keyboard{top:1px;left:-.5px;-webkit-flex-direction:column;flex-direction:column;height:100px;position:relative;z-index:9999999;transition:top .3s linear}.awesome_keyboard,.awesome_keyboard_column{width:100%;display:-webkit-flex;display:flex}.awesome_keyboard_column{-webkit-flex-wrap:nowrap;flex-wrap:nowrap;height:50px}.awesome_keyboard_column .delete{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-position:50% 50%;background-repeat:no-repeat;background-size:20px 18px}.awesome_keyboard_column .close{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");background-position:50% 50%;background-repeat:no-repeat;background-size:25px 24px}.awesome_keyboard_column div:first-child{margin-left:.5px}.awesome_keyboard_column div{margin-left:-1px;background:#fff;height:50px;-webkit-flex:1;flex:1;border:1px solid #ddd;display:-webkit-flex;display:flex;-webkit-align-items:center;align-items:center;-webkit-justify-content:center;justify-content:center}.awesome_keyboard_column div img{width:30%}.padding_top{margin-top:-1px}", ""]);
// Exports
module.exports = exports;
