import React from "react";
import mediumPlaybackRateController from "./MediumPlaybackRateController";
import "xgplayer";
import HlsJsPlayer from "xgplayer-hls.js";
import "./WrappedMedium.scss";

// import Hls from "hls.js";
const useHLS = false;

class VideoContainer extends React.Component {
  componentDidMount() {
    this.initializeXGPlayer();
  }

  initializeXGPlayer() {
    let xgplayer = new HlsJsPlayer({
      el: this.container,
      width: '100%',
      height: '100%',
      url: this.props.src,
      controls: false,
      playsinline: true,
    });
    this.xgplayer = xgplayer;
    if (this.props.onCanPlayThrough) {
      xgplayer.on('canplaythrough', this.props.onCanPlayThrough);
    }
    xgplayer.on('play', () => {
      if (this.endedWhenSeeking) {
        xgplayer.pause();
        if (xgplayer.currentTime === 0 && this.props.onTimeUpdate) {
          this.props.onTimeUpdate();
        }
      } else if (this.props.onPlay) {
        this.props.onPlay();
      }
    });
    xgplayer.on('pause', () => {
      if (!xgplayer.ended && this.props.onPause) {
        this.props.onPause();
      }
    });
    xgplayer.on('timeupdate', () => {
      if (this.playInvoked && this.props.onTimeUpdate) {
        this.props.onTimeUpdate();
      }
    });
    xgplayer.on('ended', () => {
      if (this.props.onEnded) {
        this.props.onEnded();
      }
    });
    if (this.props.onError) {
      xgplayer.on('error', this.props.onError);
    }
    xgplayer.on('durationchange', () => {
      let duration = xgplayer.duration;
      if (!isNaN(duration) && isFinite(duration)) {
        if (this.props.onCanPlayThrough) {
          this.props.onCanPlayThrough();
        }
      }
    });

    Object.defineProperty(this, 'duration', { get: () => xgplayer.duration });
    Object.defineProperty(this, 'currentTime', {
      get: () => xgplayer.currentTime,
      set: value => {
        xgplayer.currentTime = value;
      }
    });
    Object.defineProperty(this, 'playbackRate', {
      set: value => {
        let video = this.container.getElementsByTagName('video')[0];
        if (video) {
          video.playbackRate = value;
        }
      }
    });
  }

  play() {
    if (!this.playInvoked) {
      this.playInvoked = true;
    }
    if (!this.xgplayer.hasStart) {
      this.xgplayer.start();
      this.xgplayer.play();
    } else if (this.xgplayer.ended) {
      this.xgplayer.replay();
    } else {
      this.xgplayer.play();
    }
  }

  pause() {
    this.xgplayer.pause();
  }

  render() {
    return (
      <div
        ref={e => { this.container = e; } }
        className="container-xgplayer lesson-video"
        style={{ background: 'transparent' }}
      >
        <video
          width="100%"
          poster={`${this.props.src}?vframe/jpg/offset/0`}>
        </video>
      </div>
    );
  }
}

class WrappedMedium extends React.Component {
  componentDidMount() {
    if (this.props.resourcetype === 'video' && useHLS) {
      this.initializeHls();
    }
    mediumPlaybackRateController.register(this);
  }

  componentWillUnmount() {
    mediumPlaybackRateController.unregister(this);
  }

  initializeHls() {
    if(Hls.isSupported() && this.props.src && this.props.src.endsWith('.m3u8')) {
      this.loadSource(this.props.src);
   } else {
     console.log('不支持m3u8');
   }
  }

  loadSource(src) {
    const hls = new Hls();
    hls.loadSource(src);
    hls.attachMedia(this.audioEl);
    hls.on(Hls.Events.MANIFEST_PARSED,function() {
      // video.play();
    });
  }

  onPlay = () => {
    if (this.props.playbackRateEnabled) {
      this.audioEl.playbackRate = mediumPlaybackRateController.getPlaybackRate();
    }
    if (this.props.onPlay) {
      this.props.onPlay();
    }
  };

  render() {
    const { innerRef, onPlay, playbackRateEnabled, ...props } = this.props;
    if (props.resourcetype === 'audio') {
      return (
        <audio
          ref={audioEl => { this.audioEl = audioEl; innerRef(audioEl); } }
          { ...props }
          onPlay={this.onPlay}
        >
        </audio>
      );
    } else if (props.resourcetype === 'video') {
      if (useHLS) {
        return (
          <video
            ref={audioEl => { this.audioEl = audioEl; innerRef(audioEl); } }
            { ...props }
            onPlay={this.onPlay}
            width="100%"
            className="lesson-video"
            poster={`${this.props.src}?vframe/jpg/offset/0`}
          >
          </video>
        )
      } else {
        return (
          <VideoContainer
            ref={audioEl => { this.audioEl = audioEl; innerRef(audioEl); } }
            { ...props }
            onPlay={this.onPlay}
          />
        );
      }
    }
    return null;
  }
}

export default React.forwardRef((props, ref) => {
  const { mediumRef, ...extraProps } = props;
  return (
    <WrappedMedium {...extraProps} innerRef={ref} ref={mediumRef} />
  );
});
