import React, { Component } from "react";
import dialogPass from "dialog-xy-pass.png";
import Raven from "raven-js";
import {
  request as axios,
  csrfHeaders,
  getAxiosErrorText,
} from "../utils/request";
import BWDetect from "../utils/BWDetect.js";
import {
  promisifiedLoadImage,
  detectedTypeOf,
  showDialog,
  retryFetch,
} from "../utils/helpers";
import { ToastManager } from "../utils/Toast";
import { throttledRequest } from "../utils/helpers";
import { SOE, SoeLog } from "../utils/soe";
import CrystalReward from "../point_reward/CrystalReward";
import FullScreenCrystalReward from "../point_reward/FullScreenCrystalReward";
import AllCorrectAnswer from "point_rewards/all-right-reward-10.png";
import StudyOnTimeIcon from "point_rewards/study-ontime-reward-5.png";
import FinishWholeDayStudyIcon from "point_rewards/finish-current-day-reward-5.png";
import { elementType } from "prop-types";
import { data } from "jquery";

// import { Object } from 'core-js';
const REQUEST_LIMITION = 3;
const audioContent = {
  effect_fail_sound: {
    src: "https://upload.fireflybaby.cn/failure1.mp3",
    time: 784,
    flag: false,
  },
  effect_pass_sound: {
    src: "https://upload.fireflybaby.cn/pass.mp3",
    time: 1210,
    flag: true,
  },
};

const request = axios.create({
  timeout: 15000,
});
// examType 不能用于提交请求的exam_type
export const BasicQuizGenerationNew = (ExtendedComponent, examType) => {
  return class extends ExtendedComponent {
    setCanScrollIcon = () => {
      const generateQuizCon = document.getElementsByClassName(
        "generate_quiz_box"
      )[0];
      if (generateQuizCon) {
        const isScrolledToBottom = () =>
          $(document).scrollTop() + document.body.offsetHeight >=
          generateQuizCon.offsetHeight + 60;
        const isContainerHigherThanViewPort = () =>
          generateQuizCon.offsetHeight > document.body.offsetHeight;
        // console.log('hhahah eval', generateQuizCon.offsetHeight, document.body.offsetHeight, $(document).scrollTop())
        if (isContainerHigherThanViewPort() && !isScrolledToBottom()) {
          this.setState({
            setCanScrollIcon: true,
          });
        }
        const winScroll = () => {
          if (isScrolledToBottom()) {
            this.setState({
              setCanScrollIcon: false,
            });
            window.removeEventListener("scroll", winScroll);
          }
        };
        window.addEventListener("scroll", winScroll);
      }
    };

    // calculationContinueWrong = (() => {
    //   let continueWrongCount = [];
    //   return {
    //     add: function () {
    //       return continueWrongCount.push(1);
    //     },
    //     reset: function () {
    //       return (continueWrongCount.length = 0);
    //     },
    //     total: function () {
    //       return continueWrongCount.length;
    //     },
    //   };
    // })();

    componentDidMount() {
      window.addEventListener("load", () => {
        BWDetect();
      });
      this.start_answer_time = new Date();
      this.setCanScrollIcon();
      // if (options) {
      //   this.setState(
      //     {
      //       totalPoint: options.totalPoint || 0,
      //       showRewardCrystal: false,
      //       allCorrect: this.props.should_reward,
      //       showAllCorrect: false,
      //       isOldVersion: true,
      //       showStudyOnTime: options.showStudyOnTime,
      //     },
      //     () => {
      //       console.log(this.state.allCorrect, "初始化的 allCorrect");
      //     }
      //   );
      // }
    }

    componentDidUpdate(prevProps, prevState) {
      setTimeout(() => {
        if (!this.state.setCanScrollIcon) {
          this.setCanScrollIcon();
        }
      }, 500);

      // 处理填空题, 回车键提交选项
      $("input").keydown(function (e) {
        if (e.which == 13) {
          try {
            $(".fill-btn-submit").trigger("click");
          } catch (e) {}

          return;
        }
      });
    }

    componentWillMount() {
      // this.doSubmittionAndNextQuiz = throttledRequest(
      //   this.doSubmittionAndNextQuiz.bind(this),
      //   7000,
      //   () => {
      //     this.initAnswerState();
      //     this.removeCurrentQuizAnswer();
      //     this.startAnswerTime = new Date();
      //     this.requestSubmitSingleQuiz = 0;
      //   }
      // );
      // 单题答题次数
      // this.requestSubmitSingleQuiz = 0;

      let loadFirstQuizImages = function (quiz) {
        return this.loadingImageWithTimeout(this.loadingQuizImages)(quiz, true);
      };
      //问题中是否存在图片
      this.initQuizImagesState();

      // PRACTICE_LEARNING 模式下忽略首张quiz图片加载的耗时
      if (examType === "PRACTICE_LEARNING") {
        this.startAnswerTime = new Date();
      }

      this.startAnswerTime = new Date();
      // 如果第一题是有图片，显示toast，去加载
      loadFirstQuizImages
        .call(this, this.state.quiz)
        .then(() => {
          // 第一题开始答题的时间
          if (!(examType === "PRACTICE_LEARNING")) {
            this.startAnswerTime = new Date();
          }
        })
        .catch((err) => {
          // 处理图片加载出错的情况
          Raven.captureException(err);
          this.setState(() => ({
            isSuccDisplayQuizImages: false,
          }));
        });
    }

    // componentDidMount() {
    //   // 处理iOS 微信客户端6.7.4 键盘收起页面未下移bug
    //   (/iphone|ipod|ipad/i.test(navigator.appVersion)) && document.addEventListener('blur', (e) => {
    //     document.body.scrollIntoView(false)
    //   }, true)
    // }

    // 填空题输入答案操作
    handleInputChange = (e) => {
      const target = e.target;
      const value = target.value;
      this.setState(() => ({
        // isSelectedOrFilled: true,
        answerText: value?.trimStart(),
      }));
    };
    /**
     * 新版的处理录音数据
     * @param {*录音数据} audioData
     * @returns
     */
    handleRecorderData(audioData) {
      console.log("进入函数", this, audioData);
      const { quiz, subquestion } = this.state;
      this.setState({
        selected_answer: {
          audio: {
            id: "audio_for_quiz_" + quiz.id,
            url: "data:audio/mpeg;base64," + audioData,
          },
        },
      });
      if (subquestion.question_type != 2) {
        // this.submit();
        this.handleSoeData();
        // this.createAudioAndPlay("effect_pass_sound", false).then((flag) => {
        //   // flag 表示是否显示答案解析
        //   // showResultAnswer 表示是否显示答案解析
        //   // if (!flag) {
        //   this.submitQuizAnswer(true, false);
        //   // }
        // });
        return;
      } else {
        let text = subquestion.answer;
        SOE(audioData, text, (r) => {
          let resp = r.Response;
          if (resp.Status === "Finished") {
            SoeLog("Quiz", quiz.id, text, r).then((data) =>
              this.handleSoeData(resp.SuggestedScore, audioData, data.log_id)
            );
          } else if (resp.Error) {
            // alert(resp.Error.Message);
            this.handleSoeData(0, audioData, null)
          }
        });
      }
    }
    /**
     * 录音完成后的处理soe数据
     * @param {录音分数} score
     * @param {录音数据} audioData
     * @param {录音的url——id} soe_log_id
     */
    handleSoeData(score, audioData, soe_log_id) {
      this.end_answer_time = new Date();
      const { quiz, subquestion } = this.state;
      let passed =
        subquestion.question_type != 2 || score >= 40 || quiz.subjective;
      let showResultAnswer =
        !passed || (subquestion.question_type === 2 && quiz.subjective);
      this.data.quiz_id = quiz.id;
      this.data.subquestions = [
        {
          id: subquestion.id,
          question_type: subquestion.question_type,
          answer: soe_log_id,
          start_answer_time: this.start_answer_time,
          end_answer_time: this.end_answer_time,
          is_correct: passed,
        },
      ];
      this.data.is_correct = passed;
      this.setState({ showResultAnswer });
      this.createAudioAndPlay(
        passed ? "effect_pass_sound" : "effect_fail_sound",
        showResultAnswer
      ).then((flag) => {
        // flag 表示是否显示答案解析
        // showResultAnswer 表示是否显示答案解析
        // if (!flag) {
        this.submitQuizAnswer(flag, showResultAnswer);
        // }
      });
    }

    /**
     * 确认答案
     */
    confirmAnswer(item) {
      this.end_answer_time = new Date();
      // const {quiz}=this.state
      console.log(this.state, "观察一下高阶函数里面的内容");
      let selected_answer = item;
      const { answerText, quiz } = this.state;
      // let
      this.data.quiz_id = quiz.id;
      if (answerText === 0 || answerText) {
        //填空题&&语音评测题

        let is_correct =
          answerText === String(quiz.subquestions[0].answer) || quiz.subjective;
        let showResultAnswer = quiz.subjective || !is_correct;
        this.setState({
          selected_answer: { content: answerText },
          showResultAnswer,
        });
        this.data.subquestions = [
          {
            question_type: quiz.subquestions[0].question_type,
            // answer: { content: answerText },
            answer: answerText,
            id: quiz.subquestions[0].id,
            start_answer_time: this.start_answer_time,
            end_answer_time: this.end_answer_time,
            is_correct: is_correct,
          },
        ];
        this.data.is_correct = is_correct;
        // this.setState({ showResultAnswer }, () => {
        this.createAudioAndPlay(
          is_correct ? "effect_pass_sound" : "effect_fail_sound"
        ).then((flag) => {
          // flag 表示是否显示答案解析
          // showResultAnswer 表示是否显示答案解析
          // if (!flag) {

          this.submitQuizAnswer(flag, showResultAnswer).catch(err => { this.setState({ showResultAnswer: false }); alert(err) });
          // }
        }).catch(() => { this.setState({ showResultAnswer: false }) });
        // });
      } else if (selected_answer) {
        //选择题
        let is_correct = selected_answer.correct || quiz.subjective;
        let showResultAnswer = quiz.subjective || !is_correct;
        let newsubs = [];
        // let is_all_correct = true;
        quiz.subquestions.map((itm) => {
          let subquestion = {
            question_type: itm.question_type,
            answer: selected_answer.id,
            id: itm.id,
            start_answer_time: this.start_answer_time,
            end_answer_time: this.end_answer_time,
            is_correct: is_correct,
          };
          newsubs.push(subquestion);
        });
        this.data.is_correct = is_correct;
        this.data.subquestions = [...newsubs];
        // [
        //   // {
        //   //   question_type: quiz.subquestions[0].question_type,
        //   //   answer: selected_answer,
        //   //   id: quiz.subquestions[0].id,
        //   //   start_answer_time: this.start_answer_time,
        //   //   end_answer_time: this.end_answer_time,
        //   //   is_correct: is_correct,
        //   // },
        // ];

        this.setState({ showResultAnswer, selected_answer });
        this.createAudioAndPlay(
          is_correct ? "effect_pass_sound" : "effect_fail_sound"
        ).then((flag) => {
          // 直接提交答案
          // flag表示是否回答正确
          // 1.回答错误不用显示水晶
          // 2.没有配置水晶的课程
          // if (!flag || !this.props.should_reward) {
          this.submitQuizAnswer(flag, showResultAnswer).catch(err => { this.setState({ showResultAnswer: false }); alert(err) });
          // } else {
          // }
        }).catch(() => { this.setState({ showResultAnswer: false }) });
      } else {
        alert("请规范答题");
      }
    }

    /**
     * 提交本地结果获取下一题的题目信息
     * @param {*本题是否答对} flag
     * @returns 本题提交以及下一题的异步请求
     */
    submitQuizAnswer(flag, showResultAnswer) {
      this.toast = ToastManager.showLoading("上传答案...");
      return request({
        url: this.props.submit_quiz_url,
        data: { ...this.data },
        headers: csrfHeaders,
        method: "POST",
      }).then((res) => {
        console.log(
          res,
          "下一题--------",
          flag,
          "设置水晶啦吗",
          this.props.point_reward
        );
        this.toast.cancel();
        // if (res.data.quiz) {
        //非最后一题，答对并且该课程设置了水晶奖励跳出单题水晶，否则直接跳下一题
        if (!res.data.quiz) {
          //课程设置了水晶奖励
          if (this.props.point_reward.should_reward) {
            console.log("设置水晶啦吗");
            this.handleQuizEndReward(res, flag, !showResultAnswer);
            this.resetState(res, showResultAnswer);
            // this.handleLastData(res, flag);
          } else {
            //无水晶直接处理返回的数据
            if (showResultAnswer) {
              //答错停下来显示答案
              this.resetState(res, showResultAnswer);
            } else {
              //答对直接处理下一关
              this.nextSteps(res);
            }
          }
        } else {
          console.log(showResultAnswer, "显示错误答案");
          this.resetState(res, showResultAnswer);
        }
        // } else {
        //   //最后一题，暂存最后数据，弹出单题水晶，全对水晶，当天任务完成水晶
        //   if (this.props.should_reward) {
        //     //课程设置了水晶奖励
        //     this.handleQuizEndReward(res, flag);
        //   } else {
        //     this.resetState(res, flag);
        //   }
        // }
      });
    }
    /**
     * 答对跳出本题弹窗
     */
    handleQuizData() {
      if (!this.props.point_reward.should_reward) {
        return;
      }
      this.setState(({ totalPoint }) => {
        return {
          showRewardCrystal: true,
          quizScore: 1,
          totalPoint: totalPoint + 1,
        };
      });
      setTimeout(() => {
        this.setState({
          showRewardCrystal: false,
          quizScore: 0,
        });
      }, 2000);
    }
    /**
     * 处理当前题目提交后返回的数据（全对&完成当天）
     * @param {得到的数据} res
     * @param {答对答错 true，判断全对，false，直接去判断完成当天} flag
     * @return 本次学习没有水晶奖励
     */
    handleQuizEndReward(res, flag, goToNextStep = true) {
      //flag//判断是否需要考虑全对
      if (!this.props.point_reward.should_reward) {
        this.nextSteps(res);
        return;
      }
      if (flag && res.data.show_all_correct) {
        console.log("进来啦,本次学习全对");
        // const score = this.state.hasSingleReward ? 1 : 0;
        this.setState(({ totalPoint }) => {
          return {
            // showRewardCrystal: flag ? this.props : 0,
            showAllCorrect: true,
            totalPoint:
              totalPoint + this.props.point_reward.all_correct_reward_point,
          };
        });

        // this.setState({ showRewardCrystal: flag });
      } else if (res.data.show_finish_whole_day_study) {
        //当天的课程全部完成了n
        this.setState(({ totalPoint }) => {
          return {
            showFinishWholeDayStudyReward: true,
            totalPoint:
              totalPoint + this.props.point_reward.finish_whole_day_study_point,
          };
        });
      } else if (goToNextStep) {
        this.nextSteps(res);
      }
    }
    nextSteps(res) {
      if (res.data && res.data.hasOwnProperty("rank")) {
        let icon_index = String(res.rank + 1);
        if (res.data.data && !res.data.is_unit_pratice) {
          res.data.data.map((item) => {
            icon_index += String(item.rank);
          });
        }
        this.setState({
          showResultDig: true,
          lessionsList: [...res.data.data],
          rank: res.data.rank,
          icon_index,
        });
      } else {
        window.location.href = this.props.next_page_url;
      }
    }
    //加载图片出错的重新加载点击按钮
    reloadQuizImages = () => {
      this.loadingImageWithTimeout(this.loadingQuizImages)
        .call(this, this.state.quiz, true)
        .then(() => {
          this.setState(() => ({
            isSuccDisplayQuizImages: true,
          }));
          // 初始化开始答题的时间
          this.startAnswerTime = new Date();
        })
        .catch((err) => {
          // 初始化开始答题的时间
          this.startAnswerTime = new Date();
          // 处理图片加载出错的情况
          Raven.captureException(err);
          console.log(err);
        });
    };
    /**
     * 获取当前题目的正确选项
     * @param {当前题目} quiz
     */
    getCorrectAnswer(quiz) {
      let subquestion = quiz.subquestions.length ? quiz.subquestions[0] : null;
      if (!subquestion) {
        const toast = ToastManager.showError("题目出错！");
        return;
      }
      let correct_answer;

      if (subquestion.question_type === 2 || subquestion.question_type === 0) {
        correct_answer = { content: subquestion.answer, correct: true };
      } else if (subquestion.options) {
        subquestion.options.map((item) => {
          if (item.correct) {
            correct_answer = item;
          }
        });
      }
      return { correct_answer, subquestion };
    }

    /**
     * 处理中间过程更换题目
     * @param {*得到的结果} res
     * @param {*本题是否答对} flag
     * @returns 中断函数
     */
    resetState(res, showResultAnswer) {
      if (showResultAnswer) {
        this.setState({
          resultData: { ...res },
          showNextQuiz: showResultAnswer,
          // showResultAnswer: showResultAnswer,
        });
        return;
      } else if (!res.data.quiz) {
        this.setState({
          resultData: { ...res },
          // showResultAnswer: showResultAnswer,
        });
        return;
      }
      this.debounce = false;
      const old_data = this.state.article;
      const {
        quiz,
        article,
        quiz_audio_enable,
        quiz_position,

        // has_point_reward,
        // baby_point,
      } = res.data;
      const { correct_answer, subquestion } = this.getCorrectAnswer(quiz);
      this.setState(
        {
          quiz, //当前题目
          article: article || null,
          subquestion, //当前步骤
          correct_answer, //正确答案
          onPlayAudio: false, //播放音频
          onPlayAudioId: false, //播放音频Id
          showResultDig: false, //显示环节结束弹窗
          showResultAnswer: false, //显示本题答案以及解析
          selected_answer: null,
          showNextQuiz: false,
          quiz_position,
          resultData: null,
          // has_point_reward,
          // baby_point,

          rank: 0, //环节页星级
          canISelect: true, //是否可以答题状态
          answerText: "",
        },
        () => {
          if (
            old_data &&
            article &&
            old_data.poetry_article_id !== article.poetry_article_id
          ) {
            //    针对R系列竖屏
            $("#practice_question_bady").scrollTop(0, 0);
            // window.scrollTo(0, 0);
          }
          this.start_answer_time = new Date();
        }
      );
    }
    /**
     * 处理最后一题，答题页最后要做什么，最好调用个答题页的方法（不同答题页不同的处理逻辑）
     * @param {得到的结果} res
     */
    handleQuizzesEnd(res) {
      if (res && res.data) {
        if (res.data.hasOwnProperty("redirect_url")) {
          window.location.href = res.data.redirect_url;
        } else if (
          res.data.hasOwnProperty("rank") &&
          res.data.hasOwnProperty("steps")
        ) {
          const { rank, steps, next_url } = res.data;
          // let current_quiz_i = 2; //最大索引是2
          let star_icon_index = Number(rank) + 1; //记录每一关的得星情况
          // star_icon_index = Number(res.data.rank) + 1; //是否得星，关系到上面的星星是亮是暗
          steps &&
            steps.map((item, index) => {
              if (item && item.pass) {
                star_icon_index += String(item.rank + 1);
              }
            });
          this.setState(
            {
              showResultDig: true,
              next_url,
              rank,
              steps,
              star_icon_index,
            },
            () => {
              this.promisifiedPlayEffectAudio(
                rank ? "get_star_success" : "get_star_fail"
              );
            }
          );
        }
      } else {
        alert("服务器出错，请联系老师吧！");
      }
    }

    // 答题开始，弹框提示
    /**
     * 初始化答题函数
     * @param {题目} quiz
     * @param {回调函数} clickCallBack
     * @returns
     */
    initRecoverRecord(quiz, clickCallBack) {
      let todoQuiz = quiz ?? this.props.quiz;
      this.answers = [];
      this.costTimeGroup = [];
      console.log(examType, "-----");

      if (!this.props.is_fresh) {
        let dialogOptions = {
          text: "接着上次的进度继续做吗？",
          mainLabel: "继续",
          subLabel: "重新开始",
          mainCallback: () => {
            // 加载图片
            this.loadingImageWithTimeout(this.loadingQuizImages)
              .call(this, todoQuiz, true)
              .then(() => {
                // 初始化开始答题时间
                this.start_answer_time = new Date();
                // this.startAnswerTime = new Date();
              })
              .catch((err) => {
                this.setState({
                  isSuccDisplayQuizImages: false,
                });
              });
          },
          subCallback: () => {
            // 初始化开始答题时间
            window.location.href = this.props.restart_examination_url;
          },
        };
        showDialog.call(this, dialogOptions);
      } else {
        clickCallBack && clickCallBack();
      }
      // 初始化开始答题时间
      this.startAnswerTime = new Date();
      return todoQuiz;
    }

    /**
     * 是否是重做
     * @returns null
     */
    isRedoFun() {
      return this.isRedo;
    }
    /**
     * 初始化答题界面状态
     */
    initAnswerState() {
      this.setState(() => ({
        quiz: null,
        subquestion: null,
        correct_answer: null,
        onPlayAudio: false, //播放音频
        onPlayAudioId: false, //播放音频Id
        showResultDig: false, //显示环节结束弹窗
        showResultAnswer: false, //显示本题答案以及解析
        selected_answer: null,
        rank: 0, //环节页星级
        canISelect: true, //是否可以答题状态
        answerText: "",
        // selectedIndex: false,
        // isCorrect: "",
        // selectedOption: "",
        // answerText: "",
        // setCanScrollIcon: false,
        // isSelectedOrFilled: false,
        // showRewardCrystal: false,
        // hasSingleReward: false,
      }));
      // this.debonce
    }

    /**
     * 一个quiz的image集合
     * @param {题目} quiz
     * @returns
     */
    collectQuizImages = function (quiz) {
      return new Promise((resolve, reject) => {
        if (detectedTypeOf(quiz) !== "object") {
          reject(new Error("please pass a quiz object for collectQuizImage"));
        }
        let preLoadImage = [];
        if (quiz.question_photo_url) {
          preLoadImage.push(quiz.question_photo_url);
        }
        if (examType === "PRACTICE_LEARNING") {
          quiz.subquestions.forEach((subquestion) => {
            if (subquestion.question_photo_url || subquestion.photo_url) {
              preLoadImage.push(subquestion.question_photo_url);
            }
          });
          if (quiz.subquestions.length === 1) {
            const subQuiz = quiz.subquestions[0];
            if (subQuiz.options) {
              subQuiz.options.map((option) => {
                if (option.photo) {
                  preLoadImage.push(option.photo.url);
                }
              });
            }
          }
          resolve(preLoadImage);
          return;
        }
        if (examType === "SUBQUESTIONS") {
          if (quiz.options) {
            quiz.options.map((option) => {
              if (option.photo) {
                preLoadImage.push(option.photo.url);
              }
            });
          }
          resolve(preLoadImage);
          return;
        }
        if (examType === "INTERVIEW_WRONG_QUIZ") {
          if (quiz && quiz.subquestions && "options" in quiz.subquestions[0]) {
            quiz.subquestions[0].options.map((option) => {
              if (option.photo) {
                preLoadImage.push(option.photo.url);
              }
            });
          }
          resolve(preLoadImage);
          return;
        }

        if (
          examType === "GRADE_EXAM" ||
          examType === "INTERVIEW_EXAM" ||
          examType == "INTERVIEW"
        ) {
          if ("options" in quiz.subquestion) {
            quiz.subquestion.options.map((option) => {
              if (option.photo) {
                preLoadImage.push(option.photo.url);
              }
            });
          }
        } else {
          if (quiz.options) {
            quiz.options.map((option) => {
              if (option.photo) {
                preLoadImage.push(option.photo.url);
              }
            });
          }
        }
        resolve(preLoadImage);
      });
    };

    loadingImageWithTimeout = (fn) => {
      let toast = "";
      let timeout = null;
      let timeoutSec = 15000;
      const withLoading = async (quiz, isShowToast = true) => {
        if (quiz && "isImageLoaded" in quiz && !quiz.isImageLoaded) {
          if (isShowToast) {
            timeout = setTimeout(() => {
              timeout = null;
              toast && toast.cancel();
              const dialogOptions = {
                text: "图片加载缓慢，请检查您的网络后重试",
                mainLabel: "确定",
                mainCallback: async () => {
                  try {
                    await withLoading(quiz, isShowToast);
                  } catch (e) {
                    console.log(e);
                    Raven.captureException(e);
                  }
                },
              };
              showDialog.call(this, dialogOptions);
              Raven.captureException(
                `load quiz image timeout ${quiz.id}. onLine: ${navigator.onLine}`
              );
            }, timeoutSec);
            timeoutSec = timeoutSec + 10000;
            toast = ToastManager.showLoading("加载中...");
          }
        }
        return fn
          .call(this, quiz, isShowToast)
          .then((res) => {
            if (timeout) {
              clearTimeout(timeout);
              timeout = null;
            }
            toast && toast.cancel();
            return res;
          })
          .catch((e) => {
            if (timeout) {
              clearTimeout(timeout);
              timeout = null;
            }
            toast && toast.cancel();
            Raven.captureException(e);
            return Promise.reject(e);
          });
      };
      return withLoading;
    };

    // 加载单个quiz下图片
    loadingQuizImages(quiz) {
      if (!quiz) {
        // 没有可以加载的图片直接resolve
        return Promise.resolve();
      }
      if (quiz && "isImageLoaded" in quiz && !quiz.isImageLoaded) {
        return this.collectQuizImages
          .call(this, quiz)
          .then((res) => {
            if (res.length == 0) {
              return [];
            }
            return promisifiedLoadImage(res);
          })
          .then((loadedImages) => {
            if (loadedImages.length > 0) {
              quiz["isImageLoaded"] = true;
            }
          })
          .catch((err) => {
            console.log(err);
            return Promise.reject(err);
          });
      } else {
        return Promise.resolve();
      }
    }

    // 初始化quiz下图片加载状态
    initQuizImagesState() {
      if (this.props.quiz) {
        let quiz = this.props.quiz;
        // this.props.quiz.forEach((quiz) => {
        if (quiz.question_photo_url) {
          quiz["isImageLoaded"] = false;
        }
        if (examType === "SUBQUESTIONS") {
          if (quiz.options) {
            quiz.options.map((option) => {
              if (option.photo) {
                quiz["isImageLoaded"] = false;
              }
            });
          }
          return;
        }
        if (examType === "PRACTICE_LEARNING") {
          quiz.subquestions.forEach((subquestion) => {
            if (subquestion.photo_url || subquestion.question_photo_url) {
              quiz["isImageLoaded"] = false;
            }
          });
          if (quiz.subquestions.length === 1) {
            const subQuiz = quiz.subquestions[0];
            if (subQuiz.options) {
              subQuiz.options.map((option) => {
                if (option.photo) {
                  quiz["isImageLoaded"] = false;
                }
              });
            }
          }
          return;
        }
        if (
          examType == "INTERVIEW_WRONG_QUIZ" ||
          examType == "WRONG_QUIZ_REVISAL"
        ) {
          if (quiz && quiz.subquestions && "options" in quiz.subquestions[0]) {
            quiz.subquestions[0].options.map((option) => {
              if (option.photo) {
                quiz["isImageLoaded"] = false;
              }
            });
          }
          return;
        }
        if (
          examType === "GRADE_EXAM" ||
          examType === "INTERVIEW_EXAM" ||
          examType == "INTERVIEW"
        ) {
          if ("options" in quiz.subquestion) {
            quiz.subquestion.options.map((option) => {
              if (option.photo) {
                quiz["isImageLoaded"] = false;
              }
            });
          }
        } else {
          if (quiz.options) {
            quiz.options.map((option) => {
              if (option.photo) {
                quiz["isImageLoaded"] = false;
              }
            });
          }
        }
        // });
      }
    }
    submitGradeExamContinusWrongAnswer = () => {
      const currentAnswers = this.answers;
      const uncompletedQuiz = [...this.props.quizzes].splice(
        currentAnswers.length,
        this.props.quizzes.length - 1
      );
      let json;
      uncompletedQuiz.forEach((item) => {
        if (item.question_type == 0) {
          json = {
            id: item.id,
            subquestions: [
              {
                id: item.subquestion.id,
                start_answer_time: "",
                end_answer_time: "",
              },
            ],
            answer: "",
            correct: "",
            product_code: item.product_code,
          };
        } else {
          json = {
            id: item.id,
            subquestions: [
              {
                id: item.subquestion.id,
                option: [],
                start_answer_time: "",
                end_answer_time: "",
              },
            ],
            correct: "",
            product_code: item.product_code,
          };
        }
        this.uncompletedAnswers.push(json);
      });
      let notAllCompletedQuizzes = [
        ...currentAnswers,
        ...this.uncompletedAnswers,
      ];
      this.totalCostTime = this.costTimeGroup.reduce((init, next) => {
        return (init =
          init + (new Date(next.endTime) - new Date(next.startTime)));
      }, 0);

      this.totalCostTime = Math.ceil(this.totalCostTime / 1000);
      console.log("this.totalCostTime: ", this.totalCostTime);

      var quizzes = {
        quizzes: notAllCompletedQuizzes,
        total_time: this.totalCostTime,
        ...this.calculateContinusWrongCorrectness.call(this, this.answers),
      };
      console.log("quizzes参数：", quizzes);
      return retryFetch()(async () => {
        let self = this;
        try {
          const resp = await request({
            url: self.props.save_result_url,
            method: "POST",
            headers: csrfHeaders,
            data: quizzes,
          });
          const result = resp.data;
          const url = result.redirect_url;
          this.setState(() => ({
            testResultRedirectUrl: url,
          }));
        } catch (err) {
          console.log(err);
        }
      });
    };

    // 模拟题连错逻辑
    continusWrongCount() {
      const { isCorrect, quiz, answerText } = this.state;
      const { add, reset, total } = this.calculationContinueWrong;
      const isFillCorrect = answerText.trim() === quiz.subquestion.answer;
      let index = this.props.quizzes.findIndex(function (element) {
        return element.id == quiz.id;
      });
      let prevQuiz = index > 0 && this.props.quizzes[index - 1];
      console.log("已答题quiz: ", quiz, "前一题prevQuiz: ", prevQuiz);

      const quizStageType =
        prevQuiz && quiz.product_code != prevQuiz.product_code;

      // 如果当前答题是最后一题，则不计数弹框
      if (this.isLastQuiz()) {
        return;
      }
      if (isCorrect === "correct" || isFillCorrect) {
        if (quizStageType) {
          reset();
        }
      } else {
        // 如果答错题并且已答题和前一题的题目所处阶段类型不一致，直接重置
        if (quizStageType) {
          reset();
        }
        add();
        if (total() == 5) {
          this.submitGradeExamContinusWrongAnswer.call(this);
          this.setState(() => ({
            continueWrongCountArrive: true,
          }));
          return;
        }
      }
      console.warn(`已连错：${total()}题`);
    }

    // 取下一题
    nextQuiz() {
      // thinking_exam_type 为1的时候是新的结果页
      // if (
      //   examType == "GRADE_EXAM" &&
      //   this.state.quiz?.study_package_category_id &&
      //   this.props.thinking_exam_type == 1
      // ) {
      //   this.continusWrongCount();
      // }

      // 如果下一题是最后一题，就提交全部答案，然后显示结果页
      if (this.isLastQuiz()) {
        this.isLastQuizSelect = true;
        if (examType === "SUBQUESTIONS") return;
        // 是最后一题就提交报告显示结果页, 之前已经提交过最后一题的答案
        // return this.submitAnswersAndshowResult.call(this);
        if (this.state.allCorrect && this.props.should_reward) {
          this.createRewardRecord(
            this.props.allCorrectRewardPoint,
            "all_correct_reward"
          );
          this.setState({
            showAllCorrect: true,
            showRewardCrystal: false,
            totalPoint:
              this.state.totalPoint + this.props.all_correct_reward_point,
          });
        } else {
          return this.submitAnswersAndshowResult.call(this);
        }
        return;
      }

      let preloadNextQuizImages = function (index) {
        let nextSecondQuiz = this.props.quizzes[index + 2];
        if (nextSecondQuiz) {
          return this.loadingQuizImages(nextSecondQuiz, false);
        } else {
          return Promise.resolve();
        }
      };
      let loadCurrentQuizImages = function (quiz) {
        return this.loadingImageWithTimeout(this.loadingQuizImages)(quiz, true);
      };

      let setNextQuiz = function () {
        let quiz = this.state.quiz;
        let index = this.props.quizzes.findIndex(function (element) {
          return element.id == quiz.id;
        });
        let nextQuiz = this.props.quizzes[index + 1];
        this.setState(() => ({
          quiz: nextQuiz,
          answerText: "",
        }));
        // 除第一题外每题开始答题的时间
        this.startAnswerTime = new Date();
        return {
          nextQuiz,
          index,
        };
      };

      // 初始化答题界面状态
      this.initAnswerState.call(this);
      //设置下一题
      let nextQuizAndId = setNextQuiz.call(this);

      // 如果下一题是有图片 并且没有完成预加载就显示toast, 去加载
      loadCurrentQuizImages
        .call(this, nextQuizAndId.nextQuiz)
        .then(() => {
          // 除第一题外每题开始答题的时间
          this.startAnswerTime = new Date();
        })
        .catch((err) => {
          // 处理图片加载出错的情况
          this.setState(() => ({
            isSuccDisplayQuizImages: false,
          }));
          Raven.captureException(err);
        });

      // 对下下题图片预加载
      preloadNextQuizImages.call(this, nextQuizAndId.index).catch((err) => {
        console.log(err);
        Raven.captureException(err);
      });

      // 下一题在顶部显示
      document.body.scrollTop = document.documentElement.scrollTop = 0;
    }

    // 显示全对全屏提示 => R2 课课练 期末复习
    showAllCorrectRewardForSpecialStudy = (res) => {
      setTimeout(() => {
        this.setState({
          showAllCorrect: true,
          showRewardCrystal: false,
          data_res: { ...res },
          shouldHandleData: true,
          totalPoint: this.state.totalPoint + this.props.allCorrectRewardPoint,
        });
      }, 1000);
    };

    // 显示全对奖励和完成全天学习奖励 => 其它的练习题（不包括R2 课课练 期末复习)
    handleFinishWholeDayStudyReward = (res, callback) => {
      setTimeout(() => {
        if (res.data.finish_whole_day_study_reward) {
          console.log("jinru");
          this.setState(({ totalPoint }) => {
            return {
              showFinishWholeDayStudyReward: true,
              showRewardCrystal: false,
              data_res: { ...res },
              totalPoint:
                totalPoint +
                this.props.point_reward.finish_whole_day_study_point,
              callback: callback,
            };
          });
        } else {
          callback.call(this, res);
        }
      }, 1000);
    };
    /**
     *    音频结束播放时调用
     */
    clearPlayAudio() {
      this.setState({
        onPlayAudio: false,
        onPlayAudioId: "",
      });
    }
    /**
     * 全局的audio暂停
     */
    audioForceStop(id) {
      let playIngAudio = document.getElementById(id);
      return new Promise((resolve, reject) => {
        playIngAudio && playIngAudio.pause();
        resolve();
      });
    }
    /**
     * 提示音播放区别于普通语音播放
     * @param {提示音标识} ElementId
     * @returns
     */
    createAudioAndPlay(ElementId) {
      let audioEle = new Audio(audioContent[ElementId].src);
      console.log(audioContent[ElementId].flag, "----答对了吗");
      if (audioContent[ElementId].flag) {
        this.handleQuizData();
      }

      return new Promise((resolve, reject) => {
        if (audioEle) {
          let timeoutHandle = null;
          let resolveCalled = false;
          let callResolve = function() {
            if (resolveCalled) {
              return;
            }
            resolveCalled = true;
            resolve(audioContent[ElementId].flag);
          };

          timeoutHandle = setTimeout(() => { callResolve(); }, 2000);

          audioEle.play();
          audioEle.onended = function () {
            console.log(this, "--------------------audio");

            this.removeAttribute("src");
            this.srcObject = null;
            this.remove();
            clearTimeout(timeoutHandle);
            callResolve();
          };
        } else resolve(audioContent[ElementId].flag);
      });
    }
    // 播放答题音效
    promisifiedPlayEffectAudio = (ElementId, isCorrect) => {
      // console.log("------------", ElementId);
      if (this.state.onPlayAudio) {
        if (ElementId === this.state.onPlayAudioId) {
          return;
        } else {
          this.audioForceStop(this.state.onPlayAudioId).then(() => {
            this.setState({
              onPlayAudioId: ElementId,
            });
          });
        }
      } else {
        this.setState({
          onPlayAudio: true,
          onPlayAudioId: ElementId,
        });
      }

      // if (
      //   typeof isCorrect !== "undefined" &&
      //   isCorrect &&
      //   this.props?.should_reward &&
      //   !this.state.hasSingleReward
      // ) {
      //   this.setState({
      //     showRewardCrystal: true,
      //     quizScore: 1,
      //     totalPoint: this.state.totalPoint + 1,
      //     hasSingleReward: true,
      //   });
      //   this.createRewardRecord(1);

      //   setTimeout(() => {
      //     this.setState({
      //       showRewardCrystal: false,
      //     });
      //   }, 2000);
      // } else {
      //   this.setState({
      //     allCorrect: false,
      //   });
      // }
      return new Promise((resolve, reject) => {
        let audio = document.getElementById(ElementId);
        console.log("------------", audio);
        audio.play();
        setTimeout(() => {
          resolve();
        }, 500);
      });
    };

    // 处理答案选择操作
    handleSelect = (e) => {
      // 如果是最后一题，返回
      if (this.isLastQuizSelect || this.isSubmitting) {
        return;
      }
      let selectTarget = e.currentTarget;
      let selectedIndex = selectTarget.getAttribute("data-index");
      if (
        examType == "INTERVIEW_WRONG_QUIZ" ||
        examType == "WRONG_QUIZ_SET" ||
        examType == "PRACTICE_LEARNING" ||
        examType == "WRONG_QUIZ_REVISAL"
      ) {
        this.setState((preState) => ({
          isSelectedOrFilled: true,
          selectedIndex: selectedIndex,
          isCorrect:
            selectTarget.getAttribute("data-option") === "true"
              ? "correct"
              : "error",
          selectedOption: preState.quiz.subquestions[0].options[selectedIndex],
        }));
        return;
      }

      if (
        examType === "GRADE_EXAM" ||
        examType === "INTERVIEW_EXAM" ||
        examType == "INTERVIEW"
      ) {
        this.setState((preState) => ({
          isSelectedOrFilled: true,
          selectedIndex: selectedIndex,
          isCorrect:
            selectTarget.getAttribute("data-option") === "true"
              ? "correct"
              : "error",
          selectedOption: preState.quiz.subquestion.options[selectedIndex],
        }));
        return;
      }
      this.setState((preState) => ({
        isSelectedOrFilled: true,
        selectedIndex: selectedIndex,
        isCorrect:
          selectTarget.getAttribute("data-option") === "true"
            ? "correct"
            : "error",
        selectedOption: preState.quiz.options[selectedIndex],
      }));
    };

    // 填空题输入答案操作
    // handleInputChange = (e) => {
    //   const target = e.target;
    //   const value = target.value;
    //   this.setState(() => ({
    //     isSelectedOrFilled: true,
    //     answerText: value?.trimStart(),
    //   }));
    // };

    // 处理【分步练习】弹框按钮 ”下一题 or 确定“ 操作
    // deliverNextQuestionStatus = () => {
    //   const isAnswerCorrect =
    //     this.state.answerText.trim() === this.state.quiz.answer;
    //   const question_type = this.state.quiz.question_type;

    //   if (question_type === 0) {
    //     this.setState(() => ({
    //       fillButtonDialog: false,
    //     }));
    //     // 处理填空题后数据操作，提交和取下一题
    //     this.handleAfterUserFill.call(this, isAnswerCorrect);
    //   } else if (question_type === 1) {
    //     let correctOption = "";
    //     correctOption = this.state.quiz.options.find((option) => {
    //       if (option.correct) {
    //         return option;
    //       }
    //     });
    //     this.setState(() => ({
    //       optionButtonDialog: false,
    //     }));
    //     // 处理选择题后数据操作，提交和取下一题
    //     this.handleAfterUserSelection.call(
    //       this,
    //       correctOption,
    //       this.state.selectedOption
    //     );
    //   } else if (question_type === 3) {
    //   }
    // };

    // 点击图片弹框显示
    // handleImgDialog = (boolState) => {
    //   this.setState(() => ({
    //     imgDialogStatus: boolState,
    //   }));
    // };

    // // 处理用户手写题
    // confirmWrite = async (quizID, base64Img, onClearBoard) => {
    //   const toast = ToastManager.showLoading("提交中...");
    //   try {
    //     const resPutImg = await this.submitWrittenImg(quizID, base64Img);
    //     onClearBoard();
    //     console.warn("confirmWrite", resPutImg);
    //     toast.cancel();
    //     this.handleAfterUserWritten.call(this, {
    //       id: resPutImg.id,
    //       url: resPutImg.url,
    //     });
    //   } catch (e) {
    //     toast.cancel();
    //     console.warn("submitWrittenImg error", e);
    //     let dialogOptions = {
    //       text: getAxiosErrorText(e),
    //       mainLabel: "确定",
    //       mainCallback: async () => {
    //         try {
    //           await this.confirmWrite(quizID, base64Img, onClearBoard);
    //         } catch (e) {}
    //       },
    //     };
    //     showDialog.call(this, dialogOptions);
    //     Raven.captureException(e);
    //   }
    // };

    // 处理选择题确定
    // confirmSelect = () => {
    //   console.log(
    //     "让我们看看变化之前allcorrect的值：",
    //     this.state.allCorrect,
    //     "============"
    //   );
    //   if (examType === "GRADE_EXAM") {
    //     this.initTimeOut();
    //   }
    //   if (this.state.isSelectedOrFilled) {
    //     if (!this.props.is_answer_time_limited && !this.state.selectedOption) {
    //       let dialogOptions = {
    //         text: "请先选择选项哦～",
    //         mainLabel: "确定",
    //         mainCallback: () => {
    //           this.initAnswerState.call(this);
    //         },
    //       };
    //       showDialog.call(this, dialogOptions);
    //       return;
    //     }
    //     this.setState(() => ({
    //       isSelectedOrFilled: false,
    //       scrollLeftStatus: true,
    //     }));
    //     let correctOption = "";

    //     if (
    //       examType == "INTERVIEW_WRONG_QUIZ" ||
    //       examType == "WRONG_QUIZ_SET" ||
    //       examType == "PRACTICE_LEARNING" ||
    //       examType == "WRONG_QUIZ_REVISAL"
    //     ) {
    //       correctOption = this.state.quiz.subquestions[0].options.find(
    //         (option) => {
    //           if (option.correct) {
    //             return option;
    //           }
    //         }
    //       );
    //       this.promisifiedPlayEffectAudio(
    //         this.state.isCorrect === "correct"
    //           ? "effect_pass_sound"
    //           : "effect_fail_sound",
    //         this.state.isCorrect === "correct"
    //       )
    //         .then(() => {
    //           this.handleAfterUserSelection.call(
    //             this,
    //             correctOption,
    //             this.state.selectedOption,
    //             this.state.quiz.media_lesson.id
    //           );
    //           console.log(
    //             "让我们看看allcorrect的值：",
    //             this.state.allCorrect,
    //             "============"
    //           );
    //         })
    //         .catch((err) => {
    //           console.log(err);
    //         });
    //       return;
    //     }

    //     if (
    //       examType === "GRADE_EXAM" ||
    //       examType === "INTERVIEW_EXAM" ||
    //       examType == "INTERVIEW"
    //     ) {
    //       correctOption = this.state.quiz.subquestion.options.find((option) => {
    //         if (option.correct) {
    //           return option;
    //         }
    //       });
    //     } else {
    //       correctOption = this.state.quiz.options.find((option) => {
    //         if (option.correct) {
    //           return option;
    //         }
    //       });
    //     }

    //     // 选择题·模拟考不显示音效
    //     if (
    //       // examType == "GRADE_EXAM" ||
    //       examType === "DAY_EXAM" ||
    //       examType == "INTERVIEW_EXAM" ||
    //       examType == "INTERVIEW"
    //     ) {
    //       this.handleAfterUserSelection.call(
    //         this,
    //         correctOption,
    //         this.state.selectedOption
    //       );
    //       return;
    //     }
    //     this.promisifiedPlayEffectAudio(
    //       this.state.isCorrect === "correct"
    //         ? "effect_pass_sound"
    //         : "effect_fail_sound"
    //     )
    //       .then(() => {
    //         // SUBQUESTIONS条件下，选中提交选项是否相等，如相等，则继续下一题，否则继续做
    //         if (examType === "SUBQUESTIONS") {
    //           if (correctOption.correct != this.state.selectedOption.correct) {
    //             return;
    //           } else {
    //             if (this.isLastQuiz()) {
    //               this.setState(() => ({
    //                 optionButtonDialog: true, // 答对选择题,设置状态为true
    //               }));
    //               return;
    //             }
    //           }
    //         }

    //         // 处理选择题后数据操作，提交和取下一题
    //         this.handleAfterUserSelection.call(
    //           this,
    //           correctOption,
    //           this.state.selectedOption
    //         );
    //       })
    //       .catch((err) => {
    //         console.log(err);
    //       });
    //   }
    // };
    // 处理选择题确定
    // confirmSelectNew = (flag) => {
    //   if (examType === "GRADE_EXAM") {
    //     this.initTimeOut();
    //   }
    //   if (this.state.isSelectedOrFilled || flag) {
    //     if (!this.props.is_answer_time_limited && !this.state.selectedOption) {
    //       let dialogOptions = {
    //         text: "请先选择选项哦～",
    //         mainLabel: "确定",
    //         mainCallback: () => {
    //           this.initAnswerState.call(this);
    //         },
    //       };
    //       showDialog.call(this, dialogOptions);
    //       return;
    //     }
    //     this.setState(() => ({
    //       isSelectedOrFilled: false,
    //       scrollLeftStatus: true,
    //     }));
    //     let correctOption = "";

    //     if (
    //       examType == "INTERVIEW_WRONG_QUIZ" ||
    //       examType == "WRONG_QUIZ_SET" ||
    //       examType == "PRACTICE_LEARNING" ||
    //       examType == "WRONG_QUIZ_REVISAL"
    //     ) {
    //       if (flag) {
    //         correctOption = {
    //           answer: this.state.quiz.answer,
    //         };
    //       } else {
    //         correctOption = this.state.quiz.subquestions[0].options.find(
    //           (option) => {
    //             if (option.correct) {
    //               return option;
    //             }
    //           }
    //         );
    //       }

    //       // this.promisifiedPlayEffectAudio(
    //       //   this.state.isCorrect === "correct"
    //       //     ? "effect_pass_sound"
    //       //     : "effect_fail_sound"
    //       // )
    //       //   .then(() => {
    //       this.handleAfterUserSelection.call(
    //         this,
    //         correctOption,
    //         this.state.selectedOption,
    //         this.state.quiz.media_lesson.id
    //       );
    //       // })
    //       // .catch((err) => {
    //       //   console.log(err);
    //       // });
    //       return;
    //     }

    //     if (
    //       examType === "GRADE_EXAM" ||
    //       examType === "INTERVIEW_EXAM" ||
    //       examType == "INTERVIEW"
    //     ) {
    //       correctOption = this.state.quiz.subquestion.options.find((option) => {
    //         if (option.correct) {
    //           return option;
    //         }
    //       });
    //     } else {
    //       correctOption = this.state.quiz.options.find((option) => {
    //         if (option.correct) {
    //           return option;
    //         }
    //       });
    //     }

    //     // 选择题·模拟考不显示音效
    //     if (
    //       // examType == "GRADE_EXAM" ||
    //       examType === "DAY_EXAM" ||
    //       examType == "INTERVIEW_EXAM" ||
    //       examType == "INTERVIEW"
    //     ) {
    //       this.handleAfterUserSelection.call(
    //         this,
    //         correctOption,
    //         this.state.selectedOption
    //       );
    //       return;
    //     }
    //     // this.promisifiedPlayEffectAudio(
    //     //   this.state.isCorrect === "correct"
    //     //     ? "effect_pass_sound"
    //     //     : "effect_fail_sound"
    //     // )
    //     //   .then(() => {
    //     // SUBQUESTIONS条件下，选中提交选项是否相等，如相等，则继续下一题，否则继续做
    //     if (examType === "SUBQUESTIONS") {
    //       if (correctOption.correct != this.state.selectedOption.correct) {
    //         return;
    //       } else {
    //         if (this.isLastQuiz()) {
    //           this.setState(() => ({
    //             optionButtonDialog: true, // 答对选择题,设置状态为true
    //           }));
    //           return;
    //         }
    //       }
    //     }

    //     // 处理选择题后数据操作，提交和取下一题
    //     this.handleAfterUserSelection.call(
    //       this,
    //       correctOption,
    //       this.state.selectedOption
    //     );
    //     // })
    //     // .catch((err) => {
    //     //   console.log(err);
    //     // });
    //   }
    // };

    // 处理语音题目
    // btnSubmitOralAnswer = (audioData) => {
    //   let { is_not_support_recorder } = this.props;
    //   // TODO check this
    //   if (is_not_support_recorder) {
    //     this.isAnswerCorrect(false);
    //     return false;
    //   }

    //   this.setState({
    //     isSelectedOrFilled: true,
    //   });

    //   let quiz = this.state.quiz;
    //   let text = quiz.subquestions[0].answer;

    //   // This request may cost 2s.
    //   SOE(audioData, text, (r) => {
    //     SoeLog("Quiz", quiz.id, text, r);

    //     let resp = r.Response;
    //     if (resp.Status === "Finished") {
    //       this.setState({ answerScore: resp.SuggestedScore });
    //       this.isAnswerCorrect(resp.SuggestedScore >= 60);
    //     }
    //   });
    // };

    // isAnswerCorrect = (b) => {
    //   this.promisifiedPlayEffectAudio(
    //     b ? "effect_pass_sound" : "effect_fail_sound"
    //   ).then(() => {
    //     return new Promise((resolve, reject) => {
    //       if (this.isLastQuiz()) {
    //         // TODO check this
    //         this.setState(() => ({
    //           fillButtonDialog: true, // 答对填空题,设置状态为true
    //         }));
    //         return;
    //       }
    //     });
    //   });

    //   this.handleAfterUserFill(b);
    // };

    // 处理填空题确定
    // btnSubmitFillAnwer = () => {
    //   if (examType === "GRADE_EXAM") {
    //     this.initTimeOut();
    //   }
    //   console.log("======isLastQuizSelect=================");
    //   // 如果是最后一题，返回
    //   if (
    //     this.isLastQuizSelect ||
    //     this.isSubmitting ||
    //     !this.state.isSelectedOrFilled
    //   ) {
    //     return;
    //   }
    //   if (
    //     !this.props.is_answer_time_limited &&
    //     (this.state.answerText.trim() === "" || !this.state.answerText)
    //   ) {
    //     alert("请输入答案");
    //     this.setState(() => ({
    //       isSelectedOrFilled: false,
    //     }));
    //     return;
    //   }

    //   if (this.state.isSelectedOrFilled) {
    //     this.setState(() => ({
    //       isSelectedOrFilled: false,
    //       scrollLeftStatus: true,
    //     }));
    //     if (examType == "SUBQUESTIONS") {
    //       this.promisifiedPlayEffectAudio(
    //         this.state.answerText.trim() === this.state.quiz.answer
    //           ? "effect_pass_sound"
    //           : "effect_fail_sound"
    //       )
    //         .then(() => {
    //           return new Promise((resolve, reject) => {
    //             const isAnswerCorrect =
    //               this.state.answerText.trim() === this.state.quiz.answer;

    //             if (!isAnswerCorrect) {
    //               this.setState({
    //                 answerText: "",
    //               });
    //               return;
    //             } else {
    //               if (this.isLastQuiz()) {
    //                 this.setState(() => ({
    //                   fillButtonDialog: true, // 答对填空题,设置状态为true
    //                 }));
    //                 return;
    //               }
    //             }

    //             // 处理填空题后数据操作，提交和取下一题
    //             this.handleAfterUserFill.call(this, isAnswerCorrect);
    //           });
    //         })
    //         .then(() => {
    //           this.setState({
    //             answerText: "",
    //           });
    //         })
    //         .catch((err) => {
    //           console.log(err);
    //         });
    //       return;
    //     }
    //     if (
    //       examType == "INTERVIEW_WRONG_QUIZ" ||
    //       examType == "WRONG_QUIZ_SET" ||
    //       examType == "PRACTICE_LEARNING" ||
    //       examType == "WRONG_QUIZ_REVISAL"
    //     ) {
    //       const isAnswerCorrect =
    //         this.state.answerText.trim() ===
    //         this.state.quiz.subquestions[0].answer;

    //       this.promisifiedPlayEffectAudio(
    //         isAnswerCorrect ? "effect_pass_sound" : "effect_fail_sound"
    //       )
    //         .then(() => {
    //           return new Promise((resolve, reject) => {
    //             this.handleAfterUserFill.call(
    //               this,
    //               isAnswerCorrect,
    //               this.state.quiz.media_lesson.id
    //             );
    //           });
    //         })
    //         .then(() => {
    //           this.setState({
    //             answerText: "",
    //           });
    //         })
    //         .catch((err) => {
    //           console.log(err);
    //         });
    //       return;
    //     }
    //     // 填空题·模拟考不显示音效
    //     if (
    //       // examType == "GRADE_EXAM" ||
    //       examType === "DAY_EXAM" ||
    //       examType === "INTERVIEW_EXAM" ||
    //       examType == "INTERVIEW"
    //     ) {
    //       this.handleAfterUserFill.call(this);

    //       return;
    //     }
    //     this.promisifiedPlayEffectAudio(
    //       this.state.answerText.trim() ===
    //         (examType == "GRADE_EXAM"
    //           ? this.state.quiz.subquestion.answer
    //           : this.state.quiz.answer)
    //         ? "effect_pass_sound"
    //         : "effect_fail_sound"
    //     )
    //       .then(() => {
    //         return new Promise((resolve, reject) => {
    //           this.handleAfterUserFill.call(this);
    //         });
    //       })
    //       .then(() => {
    //         this.setState({
    //           answerText: "",
    //         });
    //       })
    //       .catch((err) => {
    //         console.log(err);
    //       });
    //   }
    // };
    // btnSubmitFillAnwerNew = () => {
    //   if (examType === "GRADE_EXAM") {
    //     this.initTimeOut();
    //   }

    //   // 如果是最后一题，返回
    //   if (
    //     this.isLastQuizSelect ||
    //     this.isSubmitting ||
    //     !this.state.isSelectedOrFilled
    //   ) {
    //     return;
    //   }
    //   if (
    //     !this.props.is_answer_time_limited &&
    //     (this.state.answerText.trim() === "" || !this.state.answerText)
    //   ) {
    //     alert("请输入答案");
    //     this.setState(() => ({
    //       isSelectedOrFilled: false,
    //     }));
    //     return;
    //   }

    //   if (this.state.isSelectedOrFilled) {
    //     this.setState(() => ({
    //       isSelectedOrFilled: false,
    //       scrollLeftStatus: true,
    //     }));
    //     if (examType == "SUBQUESTIONS") {
    //       this.promisifiedPlayEffectAudio(
    //         this.state.answerText.trim() === this.state.quiz.answer
    //           ? "effect_pass_sound"
    //           : "effect_fail_sound"
    //       )
    //         .then(() => {
    //           return new Promise((resolve, reject) => {
    //             const isAnswerCorrect =
    //               this.state.answerText.trim() === this.state.quiz.answer;

    //             if (!isAnswerCorrect) {
    //               this.setState({
    //                 answerText: "",
    //               });
    //               return;
    //             } else {
    //               if (this.isLastQuiz()) {
    //                 this.setState(() => ({
    //                   fillButtonDialog: true, // 答对填空题,设置状态为true
    //                 }));
    //                 return;
    //               }
    //             }

    //             // 处理填空题后数据操作，提交和取下一题
    //             this.handleAfterUserFill.call(this, isAnswerCorrect);
    //           });
    //         })
    //         .then(() => {
    //           this.setState({
    //             answerText: "",
    //           });
    //         })
    //         .catch((err) => {
    //           console.log(err);
    //         });
    //       return;
    //     }
    //     if (
    //       examType == "INTERVIEW_WRONG_QUIZ" ||
    //       examType == "WRONG_QUIZ_SET" ||
    //       examType == "PRACTICE_LEARNING" ||
    //       examType == "WRONG_QUIZ_REVISAL"
    //     ) {
    //       const isAnswerCorrect =
    //         this.state.answerText.trim() ===
    //         this.state.quiz.subquestions[0].answer;

    //       this.promisifiedPlayEffectAudio(
    //         isAnswerCorrect ? "effect_pass_sound" : "effect_fail_sound"
    //       )
    //         .then(() => {
    //           return new Promise((resolve, reject) => {
    //             this.handleAfterUserFill.call(
    //               this,
    //               isAnswerCorrect,
    //               this.state.quiz.media_lesson.id
    //             );
    //           });
    //         })
    //         .then(() => {
    //           this.setState({
    //             answerText: "",
    //           });
    //         })
    //         .catch((err) => {
    //           console.log(err);
    //         });
    //       return;
    //     }
    //     // 填空题·模拟考不显示音效
    //     if (
    //       // examType == "GRADE_EXAM" ||
    //       examType === "DAY_EXAM" ||
    //       examType === "INTERVIEW_EXAM" ||
    //       examType == "INTERVIEW"
    //     ) {
    //       this.handleAfterUserFill.call(this);
    //       this.setState({
    //         answerText: "",
    //       });
    //       return;
    //     }
    //     this.promisifiedPlayEffectAudio(
    //       this.state.answerText.trim() ===
    //         (examType == "GRADE_EXAM"
    //           ? this.state.quiz.subquestion.answer
    //           : this.state.quiz.answer)
    //         ? "effect_pass_sound"
    //         : "effect_fail_sound"
    //     )
    //       .then(() => {
    //         return new Promise((resolve, reject) => {
    //           this.handleAfterUserFill.call(this);
    //         });
    //       })
    //       .then(() => {
    //         this.setState({
    //           answerText: "",
    //         });
    //       })
    //       .catch((err) => {
    //         console.log(err);
    //       });
    //   }
    // };

    // handleAfterUserWritten(resPutImg) {
    //   // 每题结束答题的时间
    //   this.endAnswerTime = new Date();
    //   if (examType !== "SUBQUESTIONS") {
    //     this.costTimeGroup.push({
    //       quizId: this.state.quiz.id,
    //       startTime: new Date(this.startAnswerTime),
    //       endTime: new Date(this.endAnswerTime),
    //     });
    //   }

    //   this.handleCrystalReward(true).then(() => {
    //     this.handleWrithenAnswerData.call(this, resPutImg);
    //     this.doSubmittionAndNextQuiz.call(
    //       this,
    //       this.requestSubmitSingleQuiz,
    //       REQUEST_LIMITION
    //     );
    //   });
    // }

    // 处理选择题后数据操作，提交和取下一题
    // handleAfterUserSelection(correctOption, option, mediaLessonId) {
    //   // 每题结束答题的时间
    //   this.endAnswerTime = new Date();
    //   if (examType !== "SUBQUESTIONS") {
    //     this.costTimeGroup.push({
    //       quizId: this.state.quiz.id,
    //       startTime: new Date(this.startAnswerTime),
    //       endTime: new Date(this.endAnswerTime),
    //     });
    //   }
    //   // attention！ this data handle step is sync，do not make it async ,or you need promise ！
    //   this.handleCrystalReward(correctOption.id === option.id).then(() => {
    //     this.handleSelectedAnswerData.call(
    //       this,
    //       correctOption,
    //       option,
    //       mediaLessonId
    //     );
    //     this.doSubmittionAndNextQuiz.call(
    //       this,
    //       this.requestSubmitSingleQuiz,
    //       REQUEST_LIMITION
    //     );
    //   });
    // }

    // 处理填空题后数据操作，提交和取下一题
    // handleAfterUserFill(isAnswerCorrect, mediaLessonId) {
    //   this.handleCrystalReward(isAnswerCorrect).then(() => {
    //     // 每题结束答题的时间
    //     this.endAnswerTime = new Date();
    //     if (examType !== "SUBQUESTIONS") {
    //       this.costTimeGroup.push({
    //         quizId: this.state.quiz.id,
    //         startTime: this.startAnswerTime,
    //         endTime: this.endAnswerTime,
    //       });
    //     }

    //     // attention！ this data handle step is sync，do not make it async, or you need promise ！
    //     this.handleFilledAnswerData.call(this, isAnswerCorrect, mediaLessonId);
    //     if (
    //       // examType == "GRADE_EXAM" ||
    //       examType === "DAY_EXAM" ||
    //       examType === "INTERVIEW_EXAM" ||
    //       examType == "INTERVIEW"
    //     ) {
    //       this.setState({
    //         answerText: "",
    //       });
    //     }
    //     this.doSubmittionAndNextQuiz.call(
    //       this,
    //       this.requestSubmitSingleQuiz,
    //       REQUEST_LIMITION
    //     );
    //   });
    // }

    // 提交全部答案和显示结果页
    // submitAnswersAndshowResult() {
    //   const toast = ToastManager.showLoading("加载中...");
    //   this.totalCostTime = this.costTimeGroup.reduce((init, next) => {
    //     return (init =
    //       init + (new Date(next.endTime) - new Date(next.startTime)));
    //   }, 0);

    //   this.totalCostTime = Math.ceil(this.totalCostTime / 1000);
    //   console.log("totalCostTime", this.totalCostTime, examType);
    //   let submitAnswers = function () {
    //     if (examType == "GRADE_EXAM") {
    //       console.log("最后一题结束, 提交结果中....");
    //       // const kb_sign = location.search.split("&");
    //       // const kb_dimension_el = kb_sign[kb_sign.length -1]
    //       // if (kb_dimension_el == "kb_dimension") {
    //       //   this.setState({
    //       //     kbDimensionEleStatus: true
    //       //   })
    //       // }
    //       return this.submitGradeExamAnswers.call(this);
    //     }
    //     if (examType === "INTERVIEW_EXAM") {
    //       return this.submitInterviewExamAnswers.call(this);
    //     }
    //     if (examType === "INTERVIEW") {
    //       return this.submitInterviewAnswers.call(this);
    //     }
    //     if (
    //       examType == "DAY_EXAM" ||
    //       examType == "TODAY_QUIZ" ||
    //       examType == "EXERCISE_QUIZ"
    //     ) {
    //       return this.submitDayExamOrTodayQuizAnswers.call(this);
    //     }
    //     if (examType === "INTERVIEW_WRONG_QUIZ") {
    //       return this.submitInterviewWrongQuizAnswers.call(this);
    //     }
    //     if (examType === "WRONG_QUIZ_SET") {
    //       return this.submitInterviewWrongQuizAnswers.call(this);
    //     }
    //     if (examType === "WRONG_QUIZ_REVISAL") {
    //       return this.submitInterviewWrongQuizAnswers.call(this);
    //     }
    //     if (examType === "PRACTICE_LEARNING") {
    //       return this.submitPracticeLearningAnswers.call(this);
    //     }
    //   };

    //   promisifiedLoadImage([dialogPass]).catch(console.log);

    //   return (
    //     submitAnswers
    //       .call(this)
    //       // .then((res) => this.showResultPage().call(this, res))
    //       .then((res) => {
    //         // console.log("==========让我们看看返回数据吧。。。", res, '================')
    //         this.handleFinishWholeDayStudyReward(
    //           res,
    //           this.showResultPage(this)
    //         );
    //       })
    //       .then(() => toast.cancel())
    //       .catch((err) => {
    //         toast.cancel();
    //         let dialogOptions = {
    //           text: getAxiosErrorText(err),
    //           mainLabel: "确定",
    //           mainCallback: () => {
    //             this.submitAnswersAndshowResult.call(this);
    //           },
    //         };
    //         showDialog.call(this, dialogOptions);
    //         console.log("提交答案 错误返回");
    //         Raven.captureException(err);
    //         console.log(err);
    //       })
    //   );
    // }

    // 提交单个答案记录
    // submitAnswerRecord = () => {
    //   console.log("提交单个答案");
    //   let isLast = false;
    //   // let isFirst = false
    //   let isRedo = false;

    //   if (this.isLastQuiz()) {
    //     isLast = true;
    //   }
    //   // if (this.isFirstQuiz()) {
    //   //   isFirst = true
    //   // }
    //   if (this.isRedo) {
    //     isRedo = true;
    //     this.isRedo = false;
    //   }
    //   this.answer["start_answer_time"] = this.startAnswerTime;
    //   this.answer["end_answer_time"] = this.endAnswerTime;
    //   if (
    //     examType === "PRACTICE_LEARNING" &&
    //     this.state.quiz &&
    //     this.state.quiz.subquestions.length !== 1
    //   ) {
    //     console.warn(" PRACTICE_LEARNING this.costTimeGroup");
    //     this.costTimeGroup.push({
    //       quizId: this.state.quiz.id,
    //       startTime: new Date(this.startAnswerTime),
    //       endTime: new Date(this.endAnswerTime),
    //     });
    //   }

    //   console.log(this.costTimeGroup);
    //   console.warn("cost time", this.endAnswerTime - this.startAnswerTime);
    //   let answer = {
    //     answer: this.answer,
    //     is_last: isLast,
    //     is_redo: isRedo,
    //   };
    //   console.log(answer);
    //   this.isSubmitting = true;
    //   try {
    //     Raven.captureBreadcrumb({
    //       message: "user quiz answer",
    //       category: "submit quiz answer",
    //       data: answer,
    //     });
    //   } catch (e) {}
    //   return retryFetch()(() => {
    //     let self = this;
    //     try {
    //       return request({
    //         url: self.props.exam_progress_save_url,
    //         method: "POST",
    //         headers: csrfHeaders,
    //         data: answer,
    //       });
    //     } catch (err) {
    //       console.log(err);
    //       Raven.captureException(err);
    //     }
    //   });
    // };

    // 计算连错，提交数据
    // calculateContinusWrongCorrectness = (answersGroup) => {
    //   const calculation = (data) => {
    //     let corrects = [];
    //     let totalLength = data.length;
    //     let totalQuizzesLength = this.props.quizzes.length;
    //     console.log("1491_totalQuizzesLength: ", totalQuizzesLength);
    //     data.forEach((answer) => {
    //       if (answer.correct) {
    //         corrects.push({
    //           quizId: answer.quizId,
    //         });
    //       }
    //     });
    //     const correctLength = corrects.length;
    //     return {
    //       correct_count: correctLength,
    //       error_count: totalLength - correctLength,
    //       score: parseInt((correctLength / totalQuizzesLength) * 100),
    //     };
    //   };

    //   if (examType == "GRADE_EXAM") {
    //     const rawAnswerGroup = answersGroup.map((answer) => {
    //       return {
    //         quizId: answer.id,
    //         correct: answer.correct,
    //       };
    //     });
    //     return calculation(rawAnswerGroup);
    //   }
    // };

    // calculateCorrectness = (answersGroup) => {
    //   const calculation = (data) => {
    //     let corrects = [];
    //     let totalLength = data.length;
    //     data.forEach((answer) => {
    //       if (answer.correct) {
    //         corrects.push({
    //           quizId: answer.quizId,
    //         });
    //       }
    //     });
    //     const correctLength = corrects.length;
    //     return {
    //       correct_count: correctLength,
    //       error_count: totalLength - correctLength,
    //       score: parseInt((correctLength / totalLength) * 100),
    //     };
    //   };

    //   if (examType == "GRADE_EXAM") {
    //     const rawAnswerGroup = answersGroup.map((answer) => {
    //       return {
    //         quizId: answer.id,
    //         correct: answer.correct,
    //       };
    //     });
    //     return calculation(rawAnswerGroup);
    //   }

    //   if (examType === "INTERVIEW_EXAM") {
    //     const rawAnswerGroup = answersGroup.map((answer) => {
    //       return {
    //         quizId: answer.media_lesson.tag.quiz.id,
    //         correct: answer.media_lesson.tag.quiz.input_correct,
    //       };
    //     });
    //     return calculation(rawAnswerGroup);
    //   }

    //   if (examType === "INTERVIEW") {
    //   }

    //   if (
    //     examType == "DAY_EXAM" ||
    //     examType == "TODAY_QUIZ" ||
    //     examType == "EXERCISE_QUIZ"
    //   ) {
    //     const rawAnswerGroup = answersGroup.map((answer) => {
    //       return {
    //         quizId: answer.media_lesson.tag.quiz.id,
    //         correct: answer.media_lesson.tag.quiz.input_correct,
    //       };
    //     });
    //     return calculation(rawAnswerGroup);
    //   }

    //   if (examType === "INTERVIEW_WRONG_QUIZ") {
    //     let rawAnswerGroup = [];
    //     answersGroup.forEach((answer) => {
    //       answer.quizzes.forEach((quiz) => {
    //         rawAnswerGroup.push({
    //           quizId: quiz.id,
    //           correct: quiz.subquestions[0].correct,
    //         });
    //       });
    //     });
    //     return calculation(rawAnswerGroup);
    //   }
    //   if (examType === "PRACTICE_LEARNING") {
    //     let rawAnswerGroup = [];
    //     answersGroup.forEach((answer) => {
    //       answer.quizzes.forEach((quiz) => {
    //         rawAnswerGroup.push({
    //           quizId: quiz.id,
    //           correct: true,
    //         });
    //         for (const subquestion of quiz.subquestions) {
    //           if (!subquestion.correct) {
    //             const id = rawAnswerGroup.findIndex((answer) => {
    //               answer.quizId === quiz.id;
    //             });
    //             rawAnswerGroup.splice(id, 1);
    //             rawAnswerGroup.push({
    //               quizId: quiz.id,
    //               correct: false,
    //             });
    //             break;
    //           }
    //         }
    //       });
    //     });
    //     return calculation(rawAnswerGroup);
    //   }
    // };
    // 最后提交答案
    // submitGradeExamAnswers 在GradeExam jsx中
    // submitDayExamOrTodayQuizAnswers = () => {
    //   console.log("提交答案");
    //   console.warn(this.totalCostTime);
    //   var answers = {
    //     answers: this.answers,
    //     id: this.state.day_study_id,
    //     study_package_activity_id: this.props.study_package_activity_id,
    //     exam_type: this.state.exam_type,
    //     total_time: this.totalCostTime,
    //     ...this.calculateCorrectness.call(this, this.answers),
    //   };
    //   if (examType == "DAY_EXAM") {
    //     answers = Object.assign(
    //       {
    //         day_study_exam_id: this.props.day_exam.id,
    //       },
    //       answers
    //     );
    //   }
    //   return retryFetch()(() => {
    //     let self = this;
    //     return request({
    //       url: self.props.save_result_url,
    //       method: "POST",
    //       headers: csrfHeaders,
    //       data: answers,
    //     });
    //   });
    // };

    // handleCrystalReward = (isCorrect) => {
    //   return new Promise((resolve, reject) => {
    //     if (this.props.should_reward && !this.state.hasSingleReward) {
    //       const score = isCorrect ? 1 : 0;
    //       this.setState({
    //         quizScore: score,
    //         showRewardCrystal: isCorrect,
    //         totalPoint: this.state.totalPoint + score,
    //         hasSingleReward: true,
    //       });
    //       this.createRewardRecord(score);
    //     } else {
    //       // const score = isCorrect ? 1 : 0;
    //       // this.setState({
    //       //   quizScore: score,
    //       //   showRewardCrystal: false,
    //       //   totalPoint: this.state.totalPoint + score,
    //       // });
    //     }

    //     if (isCorrect) {
    //       setTimeout(() => {
    //         resolve();
    //       }, 2000);
    //     } else {
    //       this.setState({
    //         allCorrect: false,
    //       });
    //       resolve();
    //     }
    //   });
    // };

    createRewardRecord = (amount, source) => {
      if (amount <= 0) {
        return;
      }
      source = source ?? "quiz";
      request({
        url: this.props.createRewardPath,
        method: "POST",
        headers: csrfHeaders,
        data: {
          study_package_activity_id: this.props.studyPackageActivityId,
          source: source,
          target_id: this.state.quiz.id,
          target_type: "Quiz",
          amount: amount,
        },
      });
    };

    handleAllCorrectConfirmClick = () => {
      this.setState(
        {
          showAllCorrect: false,
        },
        () => {
          //第二个（全对按钮永远为false）
          this.handleQuizEndReward(this.state.resultData, false);
        }
      );

      // R2 的特殊逻辑
      // console.log(this.state.shouldHandleData, "-----------ddd--------");
      // if (this.state.shouldHandleData) {
      //   this.handelData(this.state.data_res);
      // } else {
      //   this.submitAnswersAndshowResult();
      // }
    };

    handleStudyOnTimeConfirmClick = () => {
      this.setState({
        showStudyOnTime: false,
      });
    };

    handleFinishWholeDayConfirmClick = () => {
      this.setState({
        showFinishWholeDayStudyReward: false,
      });
      this.nextSteps(this.state.resultData);
      // console.log("------------", this.state, "===============查看所有参数");

      // // 如果有特定的处理函数，则执行该函数
      // if (this.state.callback && this.state.data_res) {
      //   this.state.callback.call(this, this.state.data_res);
      //   return;
      // }

      // // R2 的特殊逻辑
      // console.log(this.state.shouldHandleData, "-----------ddd--------");
      // if (this.state.shouldHandleData) {
      //   this.handelData(this.state.data_res);
      // } else {
      //   this.submitAnswersAndshowResult();
      // }
    };

    render() {
      return (
        <>
          {this.state.showRewardCrystal &&
            this.props.point_reward.should_reward && (
              <CrystalReward
                point={this.state.quizScore}
                //提前计算了总共数量，避免多个弹窗出现时的混乱
                totalPoint={this.state.totalPoint}
                showBabyPoint={true}
              />
            )}
          {this.state.showAllCorrect &&
          this.props.point_reward.should_reward ? (
            <FullScreenCrystalReward
              point={this.props.point_reward.all_correct_reward_point}
              totalPoint={this.state.totalPoint}
              textUrl={AllCorrectAnswer}
              handleClick={this.handleAllCorrectConfirmClick}
            />
          ) : null}
          {this.state.showStudyOnTime &&
            this.props.point_reward.should_reward && (
              <FullScreenCrystalReward
                point={this.props.point_reward.study_on_time_reward_point}
                totalPoint={this.state.totalPoint}
                textUrl={StudyOnTimeIcon}
                handleClick={this.handleStudyOnTimeConfirmClick}
              />
            )}
          {this.state.showFinishWholeDayStudyReward &&
            this.props.point_reward.should_reward && (
              <FullScreenCrystalReward
                point={this.props.point_reward.finish_whole_day_study_point}
                totalPoint={this.state.totalPoint}
                textUrl={FinishWholeDayStudyIcon}
                handleClick={this.handleFinishWholeDayConfirmClick}
              />
            )}
          {super.render()}
        </>
      );
    }
  };
};
