// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../shared/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../../../../../shared/node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../../../assets/images/upper_icon_back_img.png");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../../../../../assets/images/icon_cancle_upper.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
exports.push([module.id, ".upper-icon{position:fixed;left:0;top:0;height:100%;width:100%;z-index:999;max-width:500px;background:rgba(0,0,0,.75)}.upper-icon .upper-icon-back-img{position:absolute;left:50%;top:50%;-webkit-transform:translate(-50%,-50%);transform:translate(-50%,-50%);background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") 0 0 no-repeat;background-size:cover;width:301px;border-radius:20px;overflow:hidden;padding:133px 39px 0}.upper-icon .upper-icon-back-text-item{margin-top:4px;display:-webkit-flex;display:flex}.upper-icon .upper-icon-back-tips{width:100%;text-align:center;height:28px;font-size:20px;font-family:PingFang SC;font-weight:700;line-height:28px;color:#66d167;opacity:1}.upper-icon .upper-icon-back-text{font-weight:700;margin:12px 0 19px;text-align:center}.upper-icon .upper-icon-back-title{font-weight:700}.upper-icon .upper-icon-back-text-item-con,.upper-icon .upper-icon-back-title{height:20px;font-size:14px;font-family:PingFang SC;line-height:20px;color:#333;opacity:1}.upper-icon .upper-icon-back-text-item-con{font-weight:400}.upper-icon .upper-icon-back-dote{margin:9px 9px 8px -2px;width:4px;height:4px;background:#f8a411;border-radius:50%;opacity:1}.upper-icon .upper-icon-back-text-item-button{margin:0 auto 23px;padding:13px 56px;text-align:center;background:#66d167;opacity:1;border-radius:25px;font-size:18px;font-family:PingFang SC;font-weight:700;line-height:25px;color:#fff}.upper-icon .upper-icon-back-text-item-cancle{width:38px;height:38px;background:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") 0 0 no-repeat;background-size:contain;position:absolute;top:50%;margin-top:165px;left:50%;margin-left:-19px;opacity:1}", ""]);
// Exports
module.exports = exports;
