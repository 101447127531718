/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-expressions */
/* eslint-disable react/prop-types */
/* eslint-disable lines-between-class-members */
import React, { Component, useEffect, useCallback, useState } from "react";
import queryString from "query-string";
import { TabPane, Tabs } from "../utils/Tabs/Tabs.js";
import Picker from "better-picker";
import UserAgent from "../utils/UserAgent";
import { Dialog, DialogManager } from "../utils/Dialog";
import { request, csrfHeaders } from "../utils/request";
import { ToptipsManager } from "../utils/Toptips";
import { ToastManager } from "../utils/Toast.jsx";
import ModalManager from "../utils/Modal/ModalManage.jsx";
import RightFloatIcons from "../study_package_activities/RightFloatIcons.jsx";
import { formatDotDate, formatAudioTime } from "../utils/formatAudioTime";
import InnerModal from "../utils/Modal/InnerModal.jsx";
import { SharePosterProxy } from "../promotion_activities/SharePoster";
// import { GA } from "../utils/Env";
import icon_back_test_shop from "../../../assets/images/icon_back_test_shop.png";
import { GA, GA_new } from "../utils/Env";
import { debounce, showDialog, onNavigate } from "../utils/helpers";
import CourseAuditionCoupon from "../study_packages/CourseAuditionCoupon.jsx";
import XinYaNativeBridge from "../../packs/native_bridges/native_bridge";
import ParentGuide from "../study_packages/poetries/v2/components/ParentGuide";
import WrongBook from "../study_packages/poetries/v2/components/WrongBook";
import icon_back_test from "../../../assets/images/icon_back_test.png";
import UnlockAllCourse from "../study_packages/UnlockAllCourse";
import {
  showUnlockWeekLearnModal,
  showCustomizedQuestionnaireModal,
} from "../study_package_activities/StudyPackageActivityTemplate";
import ReactMarkdown from "react-markdown";
import iconBoyUser from "icon_default_boy.png";
import iconGirlUser from "icon_default_girl.png";
import icon_star_0 from "study_package/icon_star_0.png";
import icon_star_1 from "study_package/icon_star_1.png";
import icon_star_2 from "study_package/icon_star_2.png";
import icon_star_3 from "study_package/icon_star_3.png";
import icon_children_back from "../../../assets/images/icon_children_back.png";
import icon_sigln_item from "../../../assets/images/icon_sigln_item.png";

import icon_back_exec_0 from "study_package/icon_exec_back_0.png";
import icon_back_exec_1 from "study_package/icon_exec_back_1.png";
import icon_back_exec_2 from "study_package/icon_exec_back_2.png";
import icon_back_exec_3 from "study_package/icon_exec_back_3.png";

import icon_warning from "study_package/icon_warning.png";
import icon_back_play_0 from "study_package/icon_back_play_0.png";
import icon_back_play_1 from "study_package/icon_back_play_1.png";
import icon_back_play_2 from "study_package/icon_back_play_2.png";
import icon_back_play_3 from "study_package/icon_back_play_3.png";
import icon_quiz_pass from "icon_quiz_pass.png";
import icon_lock_gray from "study_package/icon_lock_gray.png";
import icon_card_play_icon from "study_package/icon_card_play_icon.png";
// import icon_player from "study_package/icon_player.png";
// import icon_card_play from "study_package/icon_card_play.png";icon_card_play_icon
import icon_pen from "new_study_unit/icon_pen.png";
import icon_package_placeholder from "study_package/icon_package_placeholder.png";
import icon_accomplish from "new_study_unit/icon_accomplish.png";
import icon_accomplish_second from "new_study_unit/icon_accomplish_second.png";
import icon_test_accomplish from "new_study_unit/icon_test_accomplish.png";
import default_photo from "new_study_unit/thumb_placeholder.png";
import default_exam_photo from "new_study_unit/thumb_examinations.png";
import iconGift from "icon-gift.png";
import icon_class_pass from "../../../assets/images/icon_class_pass.png";
import styled from "styled-components";
import "./MultiUnitModeStudyPackages.scss";
const iconStars = [icon_star_0, icon_star_1, icon_star_2, icon_star_3];
const backExecImg = [
  icon_back_exec_0,
  icon_back_exec_1,
  icon_back_exec_2,
  icon_back_exec_3,
];
const backPlayImg = [
  icon_back_play_0,
  icon_back_play_1,
  icon_back_play_2,
  icon_back_play_3,
];
const currentStudyBack = ["#5CC55D", "#C0B05E", "#5BBAAC", "#67AFD4"];
const boxBackColor = [
  " 0px 2px 6px rgba(60, 141, 24, 0.3)",
  "0px 2px 6px rgba(192, 176, 94, 0.3)",
  " 0px 2px 6px rgba(91, 186, 172, 0.3)",
  "  0px 2px 6px rgba(103, 175, 212, 0.3)",
];

const boxStateColor = [
  "0px 1px 2px #3C8D18",
  "0px 1px 2px #B7A027",
  "0px 1px 2px #3AA092",
  "0px 1px 2px #418DB5",
];
const boxBackColorAll = [
  " 0px 0px 6px rgba(60, 141, 24, 0.2)",
  " 0px 0px 6px rgba(192, 176, 94, 0.2)",
  " 0px 0px 6px rgba(91, 186, 172, 0.2)",
  "  0px 0px 6px rgba(103, 175, 212, 0.2)",
];
const backColor_time = ["#F4FDEF", "#FDF9E7", "#EEFFFD", "#EEF9FF"];
const backColor = ["#ECFDE5", "#FFF9D9", "#DBF9F5", "#D9F2FF"];
const groupBackColor = ["#66D167", "#CCBB66", "#5EC2B4", "#71B8DC"];
// const groupShadowColor = ["#66D167", "#CCBB66", "#5EC2B4", "#71B8DC"];
export const idexUper = [
  "一",
  "二",
  "三",
  "四",
  "五",
  "六",
  "七",
  "八",
  "九",
  "十",
  "十一",
  "十二",
  "十三",
  "十四",
  "十五",
  "十六",
  "十七",
  "十八",
  "十九",
  "二十",
  "二十一",
  "二十二",
  "二十三",
  "二十四",
  "二十五",
  "二十六",
  "二十七",
  "二十八",
  "二十九",
  "三十",
  "三十一",
  "三十二",
  "三十三",
  "三十四",
  "三十五",
  "三十六",
  "三十七",
  "三十八",
  "三十九",
  "四十",
  "四十一",
  "四十二",
  "四十三",
  "四十四",
  "四十五",
  "四十六",
  "四十七",
  "四十八",
  "四十九",
  "五十",
];
const titles = ["唤醒测试", "单元测试", "以练代学", "模拟考", "摸底测"];

const StudyExecMoudle = styled.div`
  background-color: #fff;
  background-image: url(${({ backUrl }) => backUrl});
`;
const StudyPackageModulesStyle = styled.div`
  &::before {
    content: "";
    position: absolute;
    top: -24px;
    width: 100%;
    height: ${({ idx }) => (idx == 0 ? "5em" : "10em")};
    background-repeat: no-repeat;
    background-size: contain;
  }

  padding-bottom: 5em;
  margin-top: ${({ idx }) => (idx == 0 ? "15px" : "-15px")};
  padding-top: ${({ idx }) => (idx == 0 ? "0" : "10px")};
`;
// padding-top: ${({ handbook }) => (handbook ? "0" : "10px")};
// background-image: ${({ unitThemeColor }) => `linear-gradient(
//   180deg,
//   hsla(0, 0%, 100%, 0),
//   hsla(0, 0%, 100%, 0) 0%,
//   ${unitThemeColor.background} 25%,
//   ${unitThemeColor.background}
// )`};
const NUMBER = ["日", "一", "二", "三", "四", "五", "六"];

const SliderMenu = React.memo((props) => {
  console.log("SliderMenu_props: ", props);
  const {
    getUnitStudy,
    parent_guide_url,
    get_study_package_url,
    get_wrong_quiz_sets_url,
    units_title,
    renderTabPaneContent,
    study_package_system_time,
    study_package_start_time,
    is_parent_class,
    lock,
    themeColor,
    getCourseDetailStatus,
    upgradePackage,
    thinkingExam,
    currentStudyDate,
    currentStudyDay,
    getRevisalQuizzesStatus,
  } = props;
  // const [activeTabId, setActiveTabId] = useState(props.activeTabId);
  const [activeTabId, setActiveTabId] = useState(
    props.jump_to_wrong_quiz_sets
      ? 1
      : props.activeTabId
      ? props.activeTabId
      : 0
  );
  // const [activeTabId, setActiveTabId] = useState(0);
  const [unitData, setUnitData] = useState(null);
  // const [study_state,setStudyState]=useState(0);
  // const [study_url,setStudyUrl]=useState("");

  // 处理tab_menu_li 导航的颜色
  const positionTabMenuTheme = (activeTabId) => {
    const tab_light = document.getElementsByClassName("head_menu_lis")[
      activeTabId
    ];
    const head_menu_lis = document.getElementsByClassName("head_menu_lis");
    const headMenuLis = [].slice.call(head_menu_lis);

    headMenuLis.forEach((li) => {
      li.style.cssText = `background-color: none;`;
    });
    if (tab_light) {
      tab_light.style.cssText = `background-color: #E7FFD6;color: #50C000`;
      // const preActiveTabId = activeTabId == 0 ? 0 : activeTabId - 1;
      // if (activeTabId == 2 && get_wrong_quiz_sets_url) {
      //   // 控制错题本tab的文字颜色和背景
      //   tab_light.style.cssText = `background-color: #E7FFD6;color: #50C000`;
      // } else {
      //   tab_light.style.cssText = `background-color: ${themeColor[activeTabId].background};color: ${themeColor[activeTabId].font}`;
      // }
      // if (activeTabId != 0) {
      //   head_menu_lis[preActiveTabId].style.cssText = `background-image: none;`;
      // }
    }
  };

  // 自定义头部导航
  // console.log("--------",units_title)
  const UNITS = [
    // parent_guide_url
    //   ? {
    //       name: "家长须知",
    //       Comp: ParentGuide,
    //       requestUrl: parent_guide_url,
    //     }
    //   : "",
    ...(units_title || []).map((unit) => ({
      id: unit.id,
      name: unit.name,
      handbook: unit.handbook,
      Comp: CoursesListSection,
      requestUrl: get_study_package_url,
    })),

    get_wrong_quiz_sets_url
      ? {
          // 增加错题本tab
          name: "错题本",
          Comp: WrongBook,
          requestUrl: get_wrong_quiz_sets_url,
        }
      : "",
    // eslint-disable-next-line eqeqeq
  ].filter((item) => item != "");

  console.log("UNITS: ", UNITS);

  const setDayStudies = (params) => {
    console.log("setDayStudies_params: ", params);
    setUnitData(null);
    const toast = ToastManager.showLoading("加载中");
    const { requestUrl, id, handbook } = params;
    const unitRequest = `${requestUrl}?study_package_activity_id=${id}&handbook=${
      handbook ? "1" : "0"
    }`;
    console.log("137_unitRequest: ", unitRequest);
    getUnitStudy(unitRequest, id)
      .then((res) => {
        console.log("setDayStudies_res: ", res);
        setUnitData(res);
        // setUnitModules(res.study_package_modules);
        // if (res.upgrade_package_required) {
        //   props.getUpgradePackageRequiredStaus(true);
        // } else {
        //   props.getUpgradePackageRequiredStaus(false);
        // }
        // if (res?.data?.quizzes?.length >= 0) {
        //   getRevisalQuizzesStatus(true);
        // } else {
        //   getRevisalQuizzesStatus(false);
        // }
        toast.cancel();
      })
      .catch((err) => {
        setUnitData(null);
        toast.cancel();
        console.log(err);
      });
  };

  const commonSenseModuleStudies = (requestUrl) => {
    // 针对的是通识模块的数据请求
    if (requestUrl) {
      setUnitData(null);
      const toast = ToastManager.showLoading("加载中...");
      request({
        url: requestUrl,
        method: "GET",
        headers: csrfHeaders,
      })
        .then(({ data }) => {
          console.log("getChineseCommonSenseModule_data: ", data);
          if (data?.quizzes?.length >= 0) {
            getRevisalQuizzesStatus(true);
          } else {
            getRevisalQuizzesStatus(false);
          }
          setUnitData(data);
          toast.cancel();
        })
        .catch((err) => {
          setUnitData(null);
          toast.cancel();
          // console.log(err);
        });
    }
  };
  const tabClick = (_e, activeTabId) => {
    let tabs =
      units_title.length == 3
        ? ["tab1", "tab2", "wrong_quiz"]
        : ["tab1", "wrong_quiz"];
    GA("study_package_details", tabs[activeTabId], props.units_title[0].id);
    GA_new(
      "study_package_details",
      tabs[activeTabId],
      props.units_title[0].id,
      props.target_type,
      props.target_id
    );
    // console.log("activeTabId: ", activeTabId);
    if (activeTabId == 0) {
      getRevisalQuizzesStatus(false);
    }
    setActiveTabId(activeTabId);
    positionTabMenuTheme(activeTabId);
    const unitId = UNITS[activeTabId]?.id;
    const unitRequestUrl = UNITS[activeTabId]?.requestUrl;

    // 将触发tab切换的索引存到localStorage
    // localStorage.setItem("activeTabId", activeTabId);

    if (unitId) {
      setDayStudies(UNITS[activeTabId]);
    } else {
      commonSenseModuleStudies(unitRequestUrl);
    }
  };
  useEffect(() => {
    tabClick(null, activeTabId);
  }, []);

  return (
    <Tabs
      defaultAction={tabClick}
      styles="head_menu_ul customized_menu_ul"
      defaultActiveIndex={Number(activeTabId)}
      animatedTabTitle={true}
    >
      {UNITS.map((unit, index) => (
        <TabPane
          tab={
            <div className="tab_wrapper">
              {unit.photoUrl && <img src={unit.photoUrl} alt="" />}
              <p>{unit.name}</p>
            </div>
          }
          styles="head_menu_lis"
          activeStyle="tab_light"
          key={index}
        >
          {unitData
            ? renderTabPaneContent({
                getCourseDetailStatus,
                study_package_system_time,
                study_package_start_time,
                is_parent_class,
                lock,
                unitThemeColor: themeColor[index % (themeColor.length - 1)],
                thinkingExam,
                currentStudyDate,
                currentStudyDay,

                UnitComp: unit.Comp,
                unitName: unit.name,
                unitData,
                requestUrl: unit.requestUrl,
              })
            : ""}
        </TabPane>
      ))}
    </Tabs>
  );
});

export const DateHourData = [
  { text: "不提醒", value: -1 },
  { text: "00点", value: 0 },
  { text: "01点", value: 1 },
  { text: "02点", value: 2 },
  { text: "03点", value: 3 },
  { text: "04点", value: 4 },
  { text: "05点", value: 5 },
  { text: "06点", value: 6 },
  { text: "07点", value: 7 },
  { text: "08点", value: 8 },
  { text: "09点", value: 9 },
  { text: "10点", value: 10 },
  { text: "11点", value: 11 },
  { text: "12点", value: 12 },
  { text: "13点", value: 13 },
  { text: "14点", value: 14 },
  { text: "15点", value: 15 },
  { text: "16点", value: 16 },
  { text: "17点", value: 17 },
  { text: "18点", value: 18 },
  { text: "19点", value: 19 },
  { text: "20点", value: 20 },
  { text: "21点", value: 21 },
  { text: "22点", value: 22 },
  { text: "23点", value: 23 },
];

const setRemainderPicker = (url, reminder_time_hour) => {
  // console.log(reminder_time_hour, "66666666666");
  // const nameEl = dom;
  const picker = new Picker({
    data: [DateHourData],
    selectedIndex: [reminder_time_hour + 1],
    title: "",
  });

  // const config_reminder_time_url = props.config_reminder_time_url;
  // const reminder_time_hour = props.reminder_timeHour;
  picker.on("picker.select", function (selectedVal, selectedIndex) {
    // nameEl.innerText = date_hour_data[selectedIndex[0]].text;
    console.log(selectedIndex, "66666666666");
    // if (selectedIndex[0] - 1 != reminder_time_hour) {
    let params = {
      reminder_hour: selectedIndex[0] - 1,
    };
    // var url = config_reminder_time_url;
    request({
      url: url,
      method: "POST",
      headers: csrfHeaders,
      data: params,
    })
      .then((resp) => {
        window.location.reload();
        // this.props.settings.map(()=>{

        // })
        console.log("正确返回");
      })
      .catch((err) => {
        console.log(err);
      });
    // }
  });
  picker.show();
  // if (props.reminder_time_hour >= -1) {
  //   const time_setup_click = document.getElementById("time_setup_click");

  //   if (time_setup_click) {
  //     time_setup_click.addEventListener("click", function () {
  //       picker.show();
  //       if (UserAgent.isNativeClient()) {
  //         XinYaNativeBridge.requestAuthorization({
  //           authType: "notification",
  //         });
  //       }
  //     });
  //   }
  // }
};

export const UserBabyInfo = ({
  baby,
  reminderTimeHour,
  configReminderTimeUrl,
  handbook,
}) => {
  const setRemainderPicker = useCallback(
    (dom) => {
      const picker = new Picker({
        data: [DateHourData],
        selectedIndex: [reminderTimeHour + 1],
        title: "",
      });
      picker.on("picker.select", function (selectedVal, selectedIndex) {
        dom.innerText = DateHourData[selectedIndex[0]].text;

        if (selectedIndex[0] - 1 != reminderTimeHour) {
          const params = {
            reminder_hour: selectedIndex[0] - 1,
          };
          var url = configReminderTimeUrl;
          request({
            url: url,
            method: "POST",
            headers: csrfHeaders,
            data: params,
          })
            .then((resp) => {
              console.log("正确返回");
            })
            .catch((err) => {
              console.log(err);
            });
        }
      });

      if (reminderTimeHour >= -1) {
        const time_setup_click = document.getElementById("time_setup_click");

        if (time_setup_click) {
          time_setup_click.addEventListener("click", function () {
            picker.show();
            if (UserAgent.isNativeClient()) {
              XinYaNativeBridge.requestAuthorization({
                authType: "notification",
              });
            }
          });
        }
      }
    },
    [configReminderTimeUrl, reminderTimeHour]
  );

  useEffect(() => {
    const pickerDom = document.getElementById("reminder_picker");
    setRemainderPicker(pickerDom);
  }, [setRemainderPicker]);

  return (
    <div
      className="user_baby_info"
      style={handbook ? { marginTop: "10px" } : {}}
    >
      {baby && (
        <a className="baby_info" href={baby.edit_baby_path} target="_blank">
          <div className="avatar_wrapper">
            <img
              src={
                baby.photo_url ||
                (baby.gender === "男" ? iconBoyUser : iconGirlUser)
              }
              alt=""
            />
            {!baby.photo_url && <div className="to_add_avatar"></div>}
          </div>

          <div className="baby_num">
            <span>{baby.nickname}</span>
            <span>{`学号：${baby.sno}`}</span>
          </div>
        </a>
      )}

      <div className="alert_time" id="time_setup_click">
        <div className="icon_alert">上课提醒</div>
        <div className="current_time" id="reminder_picker">
          {reminderTimeHour === -1 ? "不提醒" : `当前${reminderTimeHour}点`}
        </div>
      </div>
    </div>
  );
};

const CourseListSectionComp = React.memo(({ UnitComp, ...restProps }) => {
  if (UnitComp === WrongBook) {
    return <UnitComp {...restProps} margin_top={60} />;
  } else {
    return <UnitComp {...restProps} />;
  }
});

CourseListSectionComp.displayName = "CourseListSectionComp";

const CourseStudyIntroduceBox = ({ onModalClose, count }) => {
  return (
    <div className="study_introduce_modal_box">
      <div className="study_introduce_modal">
        <div className="introduce_content">
          <div className="introduce_circle"></div>
          <div className="p_element">
            <p>
              您有<span>{`${count}节`}</span>课已学，
            </p>
            <p>
              系统已为您<span>同步</span>学习数据。
            </p>
            <p>您可以选择复习或者跳过已学内容。</p>
          </div>
        </div>
        <div className="week_learn_close" onClick={() => onModalClose()}>
          我知道了
        </div>
      </div>
    </div>
  );
};

// 显示课表学习定制提醒弹框
export const showCourseStudyIntroduceDialog = (count) => {
  return new ModalManager({
    render: (_, modalClose) => {
      const onClose = () => {
        modalClose();
      };
      return <CourseStudyIntroduceBox onModalClose={onClose} count={count} />;
    },
    isCreateModalDynamic: true,
  }).open();
};

const ajaxStudyTicketRequest = (getDiscountUrl, setIsDiscountGet) => {
  request({
    url: getDiscountUrl,
    method: "POST",
    headers: csrfHeaders,
    data: {},
  })
    .then((resp) => {
      console.log("resp: ", resp);
      setIsDiscountGet(true);
    })
    .catch((err) => {
      console.log(err);
    });
};

const ActivityDiscountTicketModal = ({
  ecouponsStudyRequired,
  discountGet,
  getDiscountUrl,
  detailsUrl,
}) => {
  const [isDiscountGet, setIsDiscountGet] = useState(discountGet);
  const handleConfirmDialog = () => {
    if (!ecouponsStudyRequired && !discountGet) {
      ajaxStudyTicketRequest(getDiscountUrl, setIsDiscountGet);
    }
    const buttons = [
      {
        label: ecouponsStudyRequired ? "确认" : "去报名",
        onClick: () => {
          confirmDialog.close();
          if (!ecouponsStudyRequired) {
            window.location.href = detailsUrl;
          }
        },
      },
    ];

    const confirmDialog = DialogManager.open(
      <Dialog buttons={buttons}>
        {ecouponsStudyRequired ? (
          <div className="activity_confirm_dialog">请先完成第1次课程。</div>
        ) : (
          <div className="activity_confirm_dialog">
            您已获得《数学培优系列》<span>￥100</span>
            优惠券，立即前往报名吧！
          </div>
        )}
      </Dialog>
    );
  };

  return discountGet ? (
    ""
  ) : (
    <div
      className="activity_discount_ticket_modal1"
      onClick={handleConfirmDialog}
    >
      <img
        src={icon_back_test}
        className="activity_discount_ticket_modal_inner"
      ></img>
      {/* <div className="left_discount_ticket">
        <div className="title">
          《数学培优系列》<span>￥100</span>优惠券
        </div>
        <p>完成第1次课程，即可领取</p>
      </div>
      <div className="right_button">
        {isDiscountGet ? "已领取" : "立即领取"}
      </div> */}
    </div>
  );
};

/**
 * 课程渲染以及家长须知tab内容页
 */

class CoursesListSection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      courseDetailStatus: false,
      courseContentDay: "",
      ajaxSingleStudies: {},
      lesson_times: [],
      subSlideAnchor: -1,
    };
    this.scrolledAnchor = false;
  }

  handleCourseList = (e, subDayStudies) => {
    GA(
      "study_package_details",
      "review",
      this.props.study_package_activities[0].id
    );

    GA_new(
      "study_package_details",
      "review",
      this.props.study_package_activities[0].id,
      this.props.target_type,
      this.props.target_id
    );
    const index = e.currentTarget.getAttribute("data-index");
    const id = e.currentTarget.getAttribute("data-id");
    const date = e.currentTarget.getAttribute("data-date");
    const day = e.currentTarget.getAttribute("data-day");
    const study_record_url = e.currentTarget.getAttribute("data-study-record");
    const singleDayStudies = subDayStudies[index];
    // console.warn("singleDayStudies: ", singleDayStudies);

    const articles = singleDayStudies?.articles;
    if (articles && articles.length <= 1) {
      const url = operateArticleUrl(
        articles[0]?.url,
        this.props.is_user_study_package
      );
      onNavigate(e, url);
      // eslint-disable-next-line no-extra-boolean-cast
      if (!!study_record_url) {
        request({
          url: study_record_url,
          method: "POST",
          headers: csrfHeaders,
        })
          .then((resp) => {})
          .catch((err) => {
            console.error(err);
          });
      }
      return;
    }

    this.setState(() => ({
      courseContentDay: day,
      courseDetailStatus: true,
    }));

    if (!this.props.unitData?.handbook) {
      // 目前仅针对的是 正价课，因为“家长须知”走的是articles的url直接跳转
      this.ajaxEveryDayRequest(id);
    }
  };
  ajaxEveryDayRequest(day_study_id) {
    this.setState(() => ({
      ajaxSingleStudies: {},
    }));
    const url = `${this.props.get_day_study_url}&day_study_id=${day_study_id}`;
    const toast = ToastManager.showLoading("数据加载中...");
    request({
      url: url,
      method: "GET",
      headers: csrfHeaders,
    })
      .then((resp) => {
        const data = resp?.data;
        this.setState(() => ({
          ajaxSingleStudies: data,
        }));
        toast.cancel();
      })
      .catch((err) => {
        toast.cancel();
        console.error(err);
      });
  }

  // 锁定的课时，点击时弹提示
  handleLockedDialog = (day_study) => {
    let text;
    if (day_study.week) {
      text = `课程将于${formatDotDate(
        day_study.week_start
      )}日解锁，请到时再来学习`;
    } else {
      if (this.props.lock == "unlock_by_day") {
        text = `课程将于${formatDotDate(day_study.date)}解锁，请到时再来学习`;
      } else {
        text = `请先学完前一天的内容，或等日期到了解锁`;
      }
    }
    const buttons = [
      {
        label: "我知道了",
        onClick: () => this.confirmDialog.close(),
      },
    ];
    this.confirmDialog = DialogManager.open(
      <Dialog buttons={buttons}>{text}</Dialog>
    );
  };

  handleDetailCloseDialog = () => {
    this.setState(() => ({
      courseDetailStatus: false,
    }));
  };

  // “学前体验” article模块请求
  handleArticleRequest = (e) => {
    const record_url = e.currentTarget.getAttribute("data-url");
    request({
      url: record_url,
      method: "POST",
      headers: csrfHeaders,
    })
      .then((resp) => {})
      .catch((err) => {
        console.log(err);
      });
  };

  // 速度闯关练习类型的弹框提醒
  speedPracticeClick = () => {
    const buttons = [
      {
        label: "我知道了",
        onClick: () => this.confirmDialog.close(),
      },
    ];
    this.confirmDialog = DialogManager.open(
      <Dialog buttons={buttons}>
        请使用手机版微信扫描练习卷上的二维码，完成训练
      </Dialog>
    );
  };

  handleTestEntrance = (e) => {
    const url = e.currentTarget.getAttribute("data-url");
    console.log("试听入口: ", url);
    onNavigate(e, url);
  };

  findScrollAnchorValue = () => {
    let studiesGroups = [];
    const { study_package_modules } = this.props.unitData;
    if (study_package_modules && study_package_modules.length > 0) {
      studiesGroups = this.collectDayStudiesGroups(study_package_modules);
    }
    return studiesGroups;
  };

  scrollAnchorProgressModule = () => {
    const anchorCurrentProgress = document.getElementById("current_progress");
    if (anchorCurrentProgress) {
      let progressMarkTop = anchorCurrentProgress.getBoundingClientRect().top;
      // console.log("progressMarkTop: ", progressMarkTop);

      let isFirstGroupDayAnchor = false;
      const dayStudiesGroups = this.findScrollAnchorValue();
      try {
        // 一天多课时或者单课时的第一项符合定位
        isFirstGroupDayAnchor = !!dayStudiesGroups.find((group) =>
          group.study_package_module.find((item) =>
            item?.subDayStudies.find(
              (it) =>
                it.current_progress_date && item?.subDayStudies.length <= 1
            )
          )
        );
      } catch (e) {}
      // console.log("isFirstGroupDayAnchor: ", isFirstGroupDayAnchor);

      if (isFirstGroupDayAnchor) {
        progressMarkTop -= 100;
      } else {
        progressMarkTop -= 100;
      }
      if (this.props.current_study_day == 1) {
        progressMarkTop = 0; // 默认清零
      }
      $("#_scroll_list").animate({ scrollTop: progressMarkTop }, 500);
      this.scrolledAnchor = true;
    }
  };

  componentDidUpdate(prevProps, prevState) {
    if (!this.scrolledAnchor) {
      this.scrollAnchorProgressModule();
    }
  }

  renderActiveTagPrompt = (group, isActive, day_study, unitThemeColor) => {
    // console.log("renderActiveTagPrompt------------", group);
    return (
      isActive && (
        <>
          {!day_study.handbook && (
            <div className="rest_fifteen_minute">
              <div className="rest_loading"></div>
              <div className="rest_text">休息15分钟，再继续下面的学习吧</div>
            </div>
          )}
          <CurrentProgressText
            group={group}
            dayStudy={day_study}
            unitThemeColor={unitThemeColor}
          />
        </>
      )
    );
  };

  componentDidMount() {
    console.warn("customized portal_root");
    if (!this.scrolledAnchor) {
      this.scrollAnchorProgressModule();
    }
    const portal = document.getElementById("portal_root");
    if (portal && portal.firstChild) {
      portal.removeChild(portal.firstChild);
    }
    if (
      this.props.pre_studied_media_lessons_count &&
      !this.props.unitData?.handbook
    ) {
      showCourseStudyIntroduceDialog(
        this.props.pre_studied_media_lessons_count
      );
    }
    const comp_unlock_ele = document.getElementsByClassName(
      "comp_unlock_all_course"
    );
    const float_icons_ele = document.getElementsByClassName("float_icons");
    if (comp_unlock_ele[0] && float_icons_ele[0]) {
      float_icons_ele[0].style.cssText = "bottom: 75px; z-index: 3;";
    }

    // 有过1天完整的上课记录，未提交过问卷时，进入课表时给出弹框
    if (
      !this.props.unitData?.handbook &&
      this.props.popup_questionnaire &&
      this.props.unitData?.questionnaire_url
    ) {
      showCustomizedQuestionnaireModal(this.props.unitData?.questionnaire_url);
    }

    // 优惠券有效期失效后进入课表，弹框提示，解锁1元学一周
    if (
      !this.props.unitData?.handbook &&
      this.props.unitData?.trial_expired &&
      this.props.unitData?.upgrade_package?.study_package_activity_buy_url
    ) {
      showUnlockWeekLearnModal(
        this.props.unitData?.upgrade_package?.study_package_activity_buy_url
      );
    }
  }

  // 处理dayStudies中相同的日期存放
  dealDayStudiesGroups = (dayStudies) => {
    const dayStudiesGroups = [];
    dayStudies.forEach((el, index) => {
      for (let i = 0; i < dayStudiesGroups.length; i++) {
        // 对比相同的字段key，相同放入对应的数组
        if (dayStudiesGroups[i]?.dateGroup == el?.date) {
          // eslint-disable-next-line no-unused-expressions
          dayStudiesGroups[i]?.subDayStudies.push(dayStudies[index]);
          return;
        }
      }
      dayStudiesGroups.push({
        dateGroup: el.date,
        subDayStudies: [dayStudies[index]],
      });
    });
    return dayStudiesGroups;
  };

  collectDayStudiesGroups = (unitModules) => {
    // console.log("++++++++++++++++", unitModules);
    const dayStudiesGroups = [];
    unitModules.forEach((module, index) => {
      dayStudiesGroups.push({
        id: module.id,
        day_studies_count: module.day_studies_count,
        studied_days: module.studied_days,
        difficulty_stage: module.difficulty_stage,
        main_photo_url: module.main_photo_url,
        name: module.name,
        photo_url: module.photo_url,
        study_package_unit_id: module.study_package_unit_id,
        study_package_module: this.dealDayStudiesGroups(module.day_studies),
      });
    });
    return dayStudiesGroups;
  };

  collectStudyPackageModule = (dayStudiesGroups) => {
    const arr = [];
    let module_collection = [];
    dayStudiesGroups.forEach((module, index) => {
      arr.push(module.study_package_module);
    });
    module_collection = arr.flat(Infinity);
    return module_collection;
  };

  customizedQuestionnaireEntry11 = (flag) => {
    // console.log(
    //   `${this.props.unitData?.upgrade_package?.study_package_activity_buy_url}&ref=note`
    // );
    return (
      <img
        src={icon_children_back}
        className="customized_questionnaire_entry11"
        style={flag ? { margin: "25px 0px  15px" } : {}}
        onClick={() => {
          window.location.href = `${this.props.unitData?.upgrade_package?.study_package_activity_buy_url}&ref=note`;
        }}
      ></img>
      // <a
      //   className="customized_questionnaire_entry11"
      //   href={`${this.props.unitData?.upgrade_package?.study_package_activity_buy_url}&ref=note`}
      //   style={flag ? { margin: "25px 0px  15px" } : {}}
      // >
      //   <div className="left_questionnaire_content_left11">
      //     <div className="left_questionnaire_content11">
      //       <p>
      //         您的
      //         <span
      //           style={{
      //             fontWeight: "700",
      //             fontSize: "18px",
      //             color: "#FFB11A",
      //           }}
      //         >
      //           一周体验课
      //         </span>
      //         已结束
      //       </p>
      //       <p>立即解锁全部课程继续学习</p>
      //     </div>
      //     <div className="right_fill_button11">
      //       <div style={{ marginLeft: "19px" }}>立即解锁</div>
      //       <img src={icon_card_play_icon}></img>
      //     </div>
      //   </div>
      // </a>
    );
  };

  customizedQuestionnaireEntry = (url, flag) => {
    return (
      <a
        className="customized_questionnaire_entry112"
        href={url}
        style={flag ? { margin: "10px" } : {}}
      >
        <div className="left_questionnaire_content">
          <p>上课体验如何？6个小问题</p>
          <p>帮助我们为您提供更好的教学服务</p>
        </div>
        <div className="right_fill_button1">立即填写</div>
      </a>
    );
  };

  unlockOneWeekButton = (flag) => {
    return (
      <div
        className="unlock_one_week_button"
        onClick={() => {
          window.location.href = `${this.props.unitData?.upgrade_package?.study_package_activity_buy_url}&ref=note`;
        }}
      >
        <img src={icon_back_test_shop}></img>
        <div className="unlock_one_week_button_inner">
          <div>
            <p>解锁全部课程，让孩子思维拓展不断档</p>
            <p style={{ fontSize: "12px", fontWeight: "400" }}>
              {` ${this.props.unitData.upgrade_package?.media_lessons_count}节课，覆盖当前年龄段绝大多数知识点`}
            </p>
          </div>
          {/* <div className="now_unlock">解锁</div> */}
        </div>
      </div>
    );
  };

  onModalOpenClick = () => {
    showUnlockWeekLearnModal(
      this.props.unitData?.upgrade_package?.study_package_activity_buy_url
    );
  };

  goStyudy = (day_study) => {
    // XinYaNativeBridge.navigate({href: day_study.day_study_url})
    // window.location.href = day_study.day_study_url;
    let item =
      day_study.completion_state == 0
        ? "start_day_study"
        : day_study.completion_state == 1
        ? "continue_day_study"
        : "relearn";

    GA(
      "study_package_details",
      item,
      this.props.study_package_activities[0].id
    );
    GA_new(
      "study_package_details",
      item,
      this.props.study_package_activities[0].id,
      this.props.target_type,
      this.props.target_id
    );
    if (UserAgent.isMiniProgram()) {
      onMiniProgram(e, day_study.day_study_url, {});
    } else if (UserAgent.isNativeClient()) {
      XinYaNativeBridge.navigate({ href: day_study.day_study_url });
    } else {
      window.location.href = day_study.day_study_url;
    }
    // window.location.href = day_study.day_study_url;
  };

  handleClick = (i) => {
    GA_new(
      "study_package_details",
      i,
      this.props.study_package_activities[0].id,
      this.props.target_type,
      this.props.target_id
    );
    GA("study_package_details", i, this.props.study_package_activities[0].id);
    window.location.href = this.props.study_url;
  };

  render() {
    console.log(
      "CoursesListSection----props-CustomizedUnitModeStudyPackages",
      this.props
    );
    console.log(
      "CoursesListSection----state-CustomizedUnitModeStudyPackages",
      this.state
    );
    const {
      unitData: {
        study_package_modules: unitModules,
        handbook,
        batch_ecoupons_end_time: batchEcouponsEndTime,
        ecoupons_study_required: ecouponsStudyRequired,
        ecoupons,
        // upgrade_package
        upgrade_package: upgradePackage,
        upgrade_package_required: upgradePackageRequired,
        questionnaire_url: questionnaireUrl,
        trial_unlock_mode: trialUnlockMode,
        discount_get: discountGet,
        old_discount_get: oldDiscountGet,
        get_discount_url: getDiscountUrl,
        study_package_activity_details_url: detailsUrl,
      },
      study_package_system_time,
      unitThemeColor,
      baby,
      reminder_time_hour: reminderTimeHour,
      config_reminder_time_url: configReminderTimeUrl,
      is_parent_class: isParentClass,
      // main_photo_url,
      // description,

      thinkingExam,
      current_study_date: currentStudyDate,
      current_study_day: currentStudyDay,
      is_user_study_package: isUserStudyPackage,
      settings,
    } = this.props;
    const {
      courseDetailStatus,
      courseContentDay,
      ajaxSingleStudies,
    } = this.state;
    // const iconStars = [icon_star_0, icon_star_1, icon_star_2, icon_star_3];

    const currentStudyAnchorExit =
      currentStudyDate != null || currentStudyDay != null;

    let dayStudiesGroups = [];
    if (unitModules && unitModules.length > 0) {
      dayStudiesGroups = this.collectDayStudiesGroups(unitModules);
    }
    // console.log("dayStudiesGroups: ", dayStudiesGroups);

    const module_collection = this.collectStudyPackageModule(dayStudiesGroups);
    // console.log("module_collection: ", module_collection);

    const findFistLockEleIndex = module_collection.findIndex((module) =>
      module.subDayStudies.find((item) => item.lock)
    );

    const subDayStudiesDate =
      module_collection[findFistLockEleIndex]?.dateGroup;
    // console.log("subDayStudiesDate: ", subDayStudiesDate);

    return dayStudiesGroups && dayStudiesGroups.length > 0 ? (
      <>
        <div
          className={`course_list_container_section ${
            this.props.study_mode == "customized"
              ? "customized_list_container_section"
              : ""
          }`}
          style={{ background: "#ECFDE5" }}
        >
          {/* 底部快捷入口 */}
          <div className="start-study">
            <div
              className="start-study-connext"
              style={{
                backgroundColor:
                  this.props.study_state == 0 || this.props.study_state == 1
                    ? "#66D167"
                    : "#FFA752",
              }}
              onClick={() => {
                let i =
                  this.props.study_state == 0
                    ? "start_float"
                    : this.props.study_state == 1
                    ? "continue_float"
                    : this.props.study_state == 2
                    ? "done_float"
                    : 0;

                i
                  ? this.handleClick(i)
                  : (window.location.href = this.props.study_url);
              }}
            >
              {this.props.study_state == 0
                ? "开始学习"
                : this.props.study_state == 1
                ? "继续完成课程"
                : this.props.study_state == 2
                ? "已完成,再学一课"
                : this.props.study_state == 3
                ? "复习"
                : "重学"}
              {/* "开始学习"} */}
            </div>
          </div>

          {!handbook && (
            // 正价课才显示
            <>
              <SubSlideGuide studyPackageModules={dayStudiesGroups} />
              {/* {ecoupons && ecoupons.length > 0 && (
                <CourseAuditionCoupon
                  batchEcouponsEndTime={batchEcouponsEndTime}
                  ecoupons={ecoupons}
                  ecouponsStudyRequired={ecouponsStudyRequired}
                  background={unitThemeColor.background}
                  systemTime={study_package_system_time}
                  flagTrail={true} // 此标志位仅用来显示已学一周 课表中券的展示
                />
              )} */}
            </>
          )}

          {/* 开课设置 */}
          {settings && settings.length ? (
            <div
              className="setting-margin"
              style={{
                marginBottom: discountGet === false ? "20px" : "55px",
              }}
            >
              <div className="setting-box-title">开课须知</div>
              <div className="setting-box">
                {settings.map((item, index) => {
                  if (item.position_type == 1 || item.position_type == 2) {
                    if (
                      item.title != "上课频次设置" ||
                      (item.title == "上课频次设置" &&
                        !this.props.trial_unlock_mode)
                    ) {
                      return (
                        <div
                          className="setting-box-item"
                          key={item.title + index}
                          onClick={() => {
                            let items =
                              item.title == "上课频次设置"
                                ? "setting"
                                : item.title == "联系老师"
                                ? "consultants"
                                : item.title == "常见问题"
                                ? "questions"
                                : "remind";
                            GA_new(
                              "study_package_details",
                              items,
                              this.props.study_package_activities[0].id,
                              this.props.target_type,
                              this.props.target_id
                            );

                            if (item.pick_type == 1) {
                              let url = window.location.origin + item.url;
                              window.location.href = url;
                            } else {
                              // console.log("==================", item);
                              setRemainderPicker(item.url, item.reminder_hour);
                              // console.log(item.title);
                            }
                          }}
                        >
                          {/* <div > */}
                          <img
                            src={item.icon}
                            alt=""
                            className="setting-box-icon"
                          />
                          {/* </div> */}
                          <div className="setting-box-title1">
                            <div>{item.title.substr(0, 4)}</div>
                            {item.title.length > 4 ? (
                              <div style={{ marginTop: "-4px" }}>
                                {item.title.substr(4)}
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      );
                    }
                  }
                })}
              </div>
            </div>
          ) : (
            ""
          )}

          {/* <div style={{width:"100%",height:200,}}></div> */}
          {/* {!setting ? <div className="setting-box">
            {[1,2,3].map(()=>{
              <div className="setting-box-item">
                <div className="setting-box-icon">11111111111</div>
                <div className="setting-box-title">22222222222</div>
              </div>
            })}
          </div> : ""} */}
          {
            // 思维课试听测评入口
            thinkingExam && (
              <div
                className="course_list_container_box trial_course_list_container_box"
                data-id={
                  (isUserStudyPackage ? currentStudyDay : currentStudyDate) ==
                  null
                    ? "current_progress"
                    : ""
                }
              >
                {(isUserStudyPackage ? currentStudyDay : currentStudyDate) ==
                  null &&
                  this.renderActiveTagPrompt(group, true, {
                    day: 1,
                    handbook: true,
                  })}
                <div
                  className="course_list_container"
                  onClick={this.handleTestEntrance}
                  data-url={
                    thinkingExam.report_url
                      ? thinkingExam.report_url
                      : thinkingExam.examination_url
                  }
                  style={{
                    boxShadow:
                      (isUserStudyPackage
                        ? currentStudyDay
                        : currentStudyDate) == null
                        ? `${boxBackColor[item_index]}`
                        : boxBackColorAll[item_index],
                    border:
                      (isUserStudyPackage
                        ? currentStudyDay
                        : currentStudyDate) == null
                        ? `1px solid ${groupBackColor[item_index]}`
                        : "",
                  }}
                >
                  {/* <img src={icon_class_pass} className="class-pass"></img> */}
                  {thinkingExam.report_url && (
                    <div className="already_finish_package">
                      <img src={icon_test_accomplish} alt="" />
                    </div>
                  )}

                  <div
                    className={`media_course_list_content ${
                      handbook ? "media_course_list_content_handbook" : ""
                    }`}
                  >
                    <img src={default_exam_photo} alt="" />
                    <div className="course_list_box">
                      <div>
                        <div className="day_exam_name">
                          心芽数学思维分级测评
                        </div>
                        <div className="day_exam_desc">
                          快速测试{baby?.nickname}适合哪个阶段的课程
                        </div>
                      </div>
                      <div className="now_entrance">
                        {thinkingExam.report_url ? "查看结果" : "立即测评"}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )
          }
          {handbook && (
            <UserBabyInfo
              baby={baby}
              reminderTimeHour={reminderTimeHour}
              configReminderTimeUrl={configReminderTimeUrl}
              handbook={handbook}
            />
          )}

          {
            // 五折优惠券领取入口
            !handbook && trialUnlockMode && !oldDiscountGet && (
              <ActivityDiscountTicketModal
                ecouponsStudyRequired={ecouponsStudyRequired}
                discountGet={discountGet}
                getDiscountUrl={getDiscountUrl}
                detailsUrl={detailsUrl}
              />
            )
          }
          {dayStudiesGroups.map((group, idx) => {
            let item_index = idx ? idx % 4 : 0;
            // let backImgItem = backImg[item_index];
            // let backColorItem = backColor[item_index];
            return (
              <StudyPackageModulesStyle
                key={idx}
                className={
                  idx == 0
                    ? "study_package_modules"
                    : `study_package_modules back-img${item_index}`
                }
                // photoUrl={backImg[item_index]}
                idx={idx}
                unitThemeColor={backColor[item_index]}
                handbook={handbook}
                style={{ backgroundColor: backColor[item_index] }}
              >
                <div
                  style={{
                    width: "100%",
                    textAlign: "center",

                    transform: "translateY(-25%)",
                    marginTop: 22,
                  }}
                >
                  <div className="class-title">
                    <img src={icon_sigln_item}></img>
                    <div>{"单元" + idexUper[idx]}</div>
                    <img src={icon_sigln_item}></img>
                  </div>
                  <div className="class-title-name">{group.name}</div>
                </div>
                {group.study_package_module.map((day_study_group, ind) => {
                  // console.log("-------day_study_group", day_study_group);
                  // console.log(
                  //   day_study_group,
                  //   // "day_study_group------------------------group",
                  //   group
                  // );
                  const isMultiPackage =
                    day_study_group.subDayStudies.length > 1;

                  const anchorMultiEleFirstDate =
                    day_study_group.subDayStudies[0].day == currentStudyDay
                      ? true
                      : false;

                  return (
                    <React.Fragment key={ind}>
                      {/* {
                        // 一项是定位
                        anchorMultiEleFirstDate && (
                          <CurrentProgressText
                            group={group}
                            dayStudy={day_study_group.subDayStudies[0]}
                            unitThemeColor={unitThemeColor}
                          />
                        )
                      } */}
                      {
                        // 针对一天多次课的时候
                        !handbook &&
                          currentStudyAnchorExit &&
                          day_study_group?.subDayStudies.length > 1 &&
                          day_study_group?.dateGroup == subDayStudiesDate && (
                            <>
                              {this.props.trial_unlock_mode &&
                                this.customizedQuestionnaireEntry11(
                                  questionnaireUrl,
                                  true
                                )}
                            </>
                          )
                      }
                      <div
                        className="day_study_groups"
                        // style={
                        //   !handbook && isMultiPackage
                        //     ? {
                        //         boxShadow: `0 0 3px 0 ${groupBackColor[item_index]}`,
                        //       }
                        //     : {}
                        // }
                      >
                        {day_study_group.subDayStudies.map(
                          (day_study, index) => {
                            const day_lock = day_study.day_lock;
                            const activeCurrentProgress =
                              this.props.currentStudyDay === day_study.day;
                            const goToClassJudge = day_lock == false;
                            const pass = day_study.state === "pass";

                            // 找到当前单元数组中的第一项, 并判断当前学习的进度是否为true
                            const dayStudyFirstCurrentProgress =
                              activeCurrentProgress && index === 0;

                            const activePlayStudy = activeCurrentProgress;
                            const day_study_exams_value =
                              day_study.day_study_exams &&
                              day_study.day_study_exams.length > 0;
                            const media_courses_value =
                              day_study.media_courses &&
                              day_study.media_courses.length > 0;
                            const onlyDayStudyExams =
                              day_study_exams_value && !media_courses_value;
                            const thumbnailImg = (day_study) => {
                              if (day_study.handbook && day_study.day === 1) {
                                return day_study.photo_url;
                              }
                              if (day_study.photo_url) {
                                return day_study.photo_url;
                              }
                              if (
                                day_study.media_courses &&
                                day_study.media_courses.length > 0
                              ) {
                                return day_study.media_courses[0].photo_url
                                  ? day_study.media_courses[0].photo_url
                                  : default_photo;
                              }
                              return default_exam_photo;
                            };

                            // 学前体验 第一天 未学状态
                            let handBookFirstDayState;
                            if (
                              day_study.day == 1 &&
                              day_study.handbook &&
                              day_study.state == "blank"
                            ) {
                              handBookFirstDayState = true;
                            }

                            const begainStudy = (
                              activeCurrentProgress,
                              pass,
                              handbook,
                              onlyDayStudyExams
                            ) => {
                              return activeCurrentProgress ? (
                                <div
                                  className="button_begain_study"
                                  style={{
                                    border: activePlayStudy
                                      ? ""
                                      : `1px solid  ${
                                          day_study.completion_state == 4
                                            ? "#FFB017"
                                            : groupBackColor[item_index]
                                        } `,
                                    boxShadow: activePlayStudy
                                      ? boxStateColor[item_index]
                                      : "",
                                    backgroundColor:
                                      day_study.completion_state == 0 &&
                                      !activePlayStudy
                                        ? groupBackColor[item_index]
                                        : "#fff",
                                  }}
                                >
                                  <p
                                    style={{
                                      color:
                                        day_study.completion_state == 4
                                          ? "#FFB017"
                                          : day_study.completion_state == 0 &&
                                            !activePlayStudy
                                          ? "#fff"
                                          : groupBackColor[item_index],
                                    }}
                                  >
                                    {`${
                                      day_study.completion_state == 2 ||
                                      day_study.completion_state == 3
                                        ? "复习"
                                        : day_study.completion_state == 0
                                        ? "开始学习"
                                        : day_study.completion_state == 1
                                        ? "继续学习"
                                        : "重学"
                                    }`}
                                  </p>
                                  <div
                                    className="icon_card_play_back"
                                    style={{
                                      backgroundColor:
                                        day_study.completion_state == 0 &&
                                        !activePlayStudy
                                          ? "#fff"
                                          : day_study.completion_state == 4
                                          ? "#FFB017"
                                          : groupBackColor[item_index],
                                    }}
                                  >
                                    <img
                                      src={
                                        day_study.completion_state == 0 &&
                                        !activePlayStudy
                                          ? backPlayImg[item_index]
                                          : icon_card_play_icon
                                      }
                                      alt=""
                                    />
                                  </div>
                                </div>
                              ) : pass ? (
                                !day_study.handbook && (
                                  <div
                                    className="begain_study_active"
                                    style={{
                                      border: activePlayStudy
                                        ? ""
                                        : `1px solid  ${
                                            day_study.completion_state == 4
                                              ? "#FFB017"
                                              : groupBackColor[item_index]
                                          } `,
                                      boxShadow: activePlayStudy
                                        ? boxStateColor[item_index]
                                        : "",
                                      backgroundColor:
                                        day_study.completion_state == 0 &&
                                        !activePlayStudy
                                          ? groupBackColor[item_index]
                                          : "#fff",
                                    }}
                                  >
                                    <p
                                      style={{
                                        color:
                                          day_study.completion_state == 4
                                            ? "#FFB017"
                                            : day_study.completion_state == 0 &&
                                              !activePlayStudy
                                            ? "#fff"
                                            : groupBackColor[item_index],
                                      }}
                                    >
                                      {`${
                                        day_study.completion_state == 2 ||
                                        day_study.completion_state == 3
                                          ? "复习"
                                          : day_study.completion_state == 0
                                          ? "开始学习"
                                          : day_study.completion_state == 1
                                          ? "继续学习"
                                          : "重学"
                                      }`}
                                    </p>
                                    <div
                                      className="icon_card_play_back"
                                      style={{
                                        backgroundColor:
                                          day_study.completion_state == 0 &&
                                          !activePlayStudy
                                            ? "#fff"
                                            : day_study.completion_state == 4
                                            ? "#FFB017"
                                            : groupBackColor[item_index],
                                      }}
                                    >
                                      <img
                                        src={
                                          day_study.completion_state == 0 &&
                                          !activePlayStudy
                                            ? backPlayImg[item_index]
                                            : icon_card_play_icon
                                        }
                                        alt=""
                                      />
                                    </div>
                                  </div>
                                )
                              ) : (
                                <div
                                  className="begain_study_active"
                                  style={{
                                    border: activePlayStudy
                                      ? ""
                                      : `1px solid  ${
                                          day_study.completion_state == 4
                                            ? "#FFB017"
                                            : groupBackColor[item_index]
                                        } `,
                                    boxShadow: activePlayStudy
                                      ? boxStateColor[item_index]
                                      : "",
                                    backgroundColor:
                                      day_study.completion_state == 0 &&
                                      !activePlayStudy
                                        ? groupBackColor[item_index]
                                        : "#fff",
                                  }}
                                >
                                  {handbook && onlyDayStudyExams ? (
                                    <React.Fragment>
                                      <p>开始测试</p>
                                      <img
                                        src={icon_pen}
                                        alt=""
                                        style={{
                                          width: "16px",
                                          height: "16px",
                                          minWidth: "16px",
                                        }}
                                      />
                                    </React.Fragment>
                                  ) : (
                                    <React.Fragment>
                                      <p
                                        style={{
                                          color:
                                            day_study.completion_state == 4
                                              ? "#FFB017"
                                              : day_study.completion_state ==
                                                  0 && !activePlayStudy
                                              ? "#fff"
                                              : groupBackColor[item_index],
                                        }}
                                      >
                                        {`${
                                          day_study.completion_state == 2 ||
                                          day_study.completion_state == 3
                                            ? "复习"
                                            : day_study.completion_state == 0
                                            ? "开始学习"
                                            : day_study.completion_state == 1
                                            ? "继续学习"
                                            : "重学"
                                        }`}
                                      </p>
                                      <div
                                        className="icon_card_play_back"
                                        style={{
                                          backgroundColor:
                                            day_study.completion_state == 0 &&
                                            !activePlayStudy
                                              ? "#fff"
                                              : day_study.completion_state == 4
                                              ? "#FFB017"
                                              : groupBackColor[item_index],
                                        }}
                                      >
                                        <img
                                          src={
                                            day_study.completion_state == 0 &&
                                            !activePlayStudy
                                              ? backPlayImg[item_index]
                                              : icon_card_play_icon
                                          }
                                          alt=""
                                        />
                                      </div>
                                    </React.Fragment>
                                  )}
                                </div>
                              );
                            };

                            // 解锁形式·天或周
                            const DayOrWeekly = (day_study) => {
                              if (day_study.handbook) {
                                return day_study.name;
                              } else if (day_study.week) {
                                return `第${day_study.week}周`;
                              } else if (day_study.day) {
                                return `第${day_study.day}${
                                  isUserStudyPackage ? "次" : "天"
                                }`;
                              }
                            };

                            const DayOrWeeklyDate = (
                              day_study,
                              isUserStudyPackage
                            ) => {
                              if (day_study.week) {
                                return `${formatDotDate(
                                  day_study.week_start
                                )} - ${formatDotDate(day_study.week_end)}`;
                              } else {
                                const day = showEveryWeekDay(
                                  day_study.date.replace(/-/g, "/")
                                );
                                return isUserStudyPackage
                                  ? `周${day}`
                                  : formatDotDate(day_study.date);
                              }
                            };

                            const showEveryWeekDay = (date) => {
                              const day = new Date(date).getDay();
                              return NUMBER[day];
                            };
                            return day_study.day ? (
                              <React.Fragment key={index}>
                                {
                                  // 一项是定位
                                  day_study.day === currentStudyDay && (
                                    <CurrentProgressText
                                      group={group}
                                      dayStudy={
                                        day_study_group.subDayStudies[0]
                                      }
                                      marginTop={15}
                                      unitThemeColor={unitThemeColor}
                                    />
                                  )
                                }
                                {courseDetailStatus &&
                                Object.keys(ajaxSingleStudies).length > 0 &&
                                day_study.day == courseContentDay ? (
                                  <InnerModal>
                                    <CourseDetailSection
                                      singleDayStudies={ajaxSingleStudies}
                                      handleCloseDetail={
                                        this.handleDetailCloseDialog
                                      }
                                      speedPracticeClick={
                                        this.speedPracticeClick
                                      }
                                      icon_stars={iconStars}
                                      system_time={study_package_system_time}
                                      is_parent_class={isParentClass}
                                      handleArticleRequest={
                                        this.handleArticleRequest
                                      }
                                      isUserStudyPackage={isUserStudyPackage}
                                      studyMode={this.props.study_mode}
                                    />
                                  </InnerModal>
                                ) : null}

                                <div
                                  className={`course_list_container_box course_list_container_box_border ${
                                    activeCurrentProgress &&
                                    !anchorMultiEleFirstDate &&
                                    !handbook
                                      ? "anchor_scroll_bg"
                                      : ""
                                  }`}
                                  style={
                                    (dayStudyFirstCurrentProgress
                                      ? { paddingTop: "10px" }
                                      : {},
                                    handbook ? { marginTop: 15 } : {})
                                  }
                                  data-id={
                                    activeCurrentProgress
                                      ? "current_progress"
                                      : ""
                                  }
                                >
                                  {/* {
                                    // 一天多次 非第一项
                                    currentStudyAnchorExit &&
                                      !anchorMultiEleFirstDate &&
                                      isMultiPackage &&
                                      console.log(
                                        this.props,
                                        "------------+++++++++",
                                        currentStudyAnchorExit,
                                        anchorMultiEleFirstDate,
                                        isMultiPackage
                                      )
                                    // this.renderActiveTagPrompt(
                                    //   group,
                                    //   activeCurrentProgress,
                                    //   day_study,
                                    //   unitThemeColor
                                    // )
                                  } */}

                                  {!handbook &&
                                    currentStudyAnchorExit &&
                                    day_study_group?.subDayStudies.length ==
                                      1 &&
                                    day_study_group?.dateGroup ==
                                      subDayStudiesDate && (
                                      <>
                                        {
                                          // 完成某一天课程后，未提交过问卷时，课表学习卡片下方入口, 始终放在最新学习卡片的下面
                                          // 【只在用户已经填写了问卷后，才不显示】

                                          this.props.trial_unlock_mode &&
                                            this.customizedQuestionnaireEntry11(
                                              questionnaireUrl
                                            )
                                        }
                                        {/* {
                                          // 一周体验部分结束后，解锁框文案
                                          upgradePackage?.study_package_activity_buy_url &&
                                            this.unlockOneWeekButton()
                                        } */}
                                      </>
                                    )}
                                  <div
                                    className={`course_list_container ${
                                      goToClassJudge
                                        ? ""
                                        : "course_list_container_lock"
                                    } ${
                                      isUserStudyPackage && !goToClassJudge
                                        ? "course_lock_remove_line"
                                        : ""
                                    }`}
                                    data-index={index}
                                    data-id={day_study.id}
                                    data-date={day_study.date}
                                    data-day={day_study.day}
                                    data-study-record={
                                      day_study?.articles &&
                                      day_study?.articles.length <= 1
                                        ? day_study?.articles[0]
                                            .create_study_record
                                        : ""
                                    }
                                    onClick={(e) =>
                                      goToClassJudge
                                        ? day_study.completion_state == 2 ||
                                          day_study.completion_state == 3
                                          ? this.handleCourseList(
                                              e,
                                              day_study_group.subDayStudies
                                            )
                                          : this.goStyudy(day_study)
                                        : day_study?.lock
                                        ? this.onModalOpenClick()
                                        : this.handleLockedDialog(day_study)
                                    }
                                    style={{
                                      boxShadow:
                                        activeCurrentProgress &&
                                        (!isMultiPackage || handbook)
                                          ? `${boxBackColor[item_index]}`
                                          : boxBackColorAll[item_index],
                                      marginTop:
                                        activeCurrentProgress ||
                                        handBookFirstDayState
                                          ? "15px"
                                          : "",
                                    }}
                                  >
                                    {day_study.state == "pass" && (
                                      <img
                                        src={icon_class_pass}
                                        className="class-pass"
                                      ></img>
                                    )}
                                    <div
                                      className={`course_list_section ${
                                        goToClassJudge
                                          ? ""
                                          : "course_list_section_lock"
                                      } ${
                                        activePlayStudy
                                          ? "course_list_section_bg"
                                          : ""
                                      } ${
                                        // !goToClassJudge &&
                                        day_study_group.subDayStudies.length ==
                                          1 || handbook
                                          ? "single_list_section"
                                          : ""
                                      }`}
                                      style={{
                                        paddingLeft:
                                          day_study.state == "pass"
                                            ? "43px"
                                            : "15px",
                                        background: activePlayStudy
                                          ? groupBackColor[item_index]
                                          : "",
                                      }}
                                    >
                                      <div className="day_and_study_report_entrance">
                                        <div className="day_or_date ">
                                          {/* 按周解锁 */}
                                          <div
                                            className="correspond_course"
                                            style={{ display: "flex" }}
                                          >
                                            <div>
                                              {day_study.customize_name ||
                                                DayOrWeekly(day_study)}
                                            </div>
                                            <div
                                              className="group-time"
                                              style={{
                                                backgroundColor: !anchorMultiEleFirstDate
                                                  ? backColor_time[item_index]
                                                  : currentStudyBack[
                                                      item_index
                                                    ],
                                                color: day_study.day_lock
                                                  ? "#999"
                                                  : anchorMultiEleFirstDate
                                                  ? "#fff"
                                                  : "333",
                                              }}
                                            >
                                              {"约" +
                                                window.Math.ceil(
                                                  day_study.total_avg_time / 60
                                                ) +
                                                "分钟"}
                                              {/* 11111 */}
                                            </div>
                                          </div>
                                          <div className="date_day_box">
                                            {!day_study.handbook &&
                                              !day_study.customize_name && (
                                                <>
                                                  <div className="date">
                                                    {DayOrWeeklyDate(day_study)}
                                                  </div>
                                                  {isUserStudyPackage && (
                                                    <div className="day_per_week">
                                                      {DayOrWeeklyDate(
                                                        day_study,
                                                        isUserStudyPackage
                                                      )}
                                                    </div>
                                                  )}
                                                </>
                                              )}
                                          </div>
                                        </div>
                                        {goToClassJudge ? (
                                          begainStudy(
                                            activeCurrentProgress,
                                            pass,
                                            day_study.handbook,
                                            onlyDayStudyExams
                                          )
                                        ) : (
                                          <div className="button_begain_lock">
                                            <img src={icon_lock_gray} alt="" />
                                          </div>
                                        )}
                                      </div>
                                      {/* {day_study.study_report && (
                                        <div
                                          className="already_finish_package"
                                          style={{
                                            top:
                                              activeCurrentProgress && "-1px",
                                            left:
                                              activeCurrentProgress && "-1px",
                                          }}
                                        >
                                          <img
                                            src={
                                              index == 0 || handbook
                                                ? icon_accomplish
                                                : icon_accomplish_second
                                            }
                                            alt=""
                                          />
                                        </div>
                                      )} */}
                                    </div>
                                    <div
                                      className="media_course_list_content 111"
                                      style={{
                                        borderBottom:
                                          (goToClassJudge ||
                                            day_study_group.subDayStudies
                                              .length > 1) &&
                                          !handbook
                                            ? ".5px solid #ddd"
                                            : "none",
                                        borderRadius: handbook
                                          ? "0 0 8px 8px"
                                          : "",
                                      }}
                                    >
                                      <img
                                        src={`${thumbnailImg(day_study)}`}
                                        alt=""
                                      />
                                      <div
                                        className="course_list_box"
                                        style={{ width: "100%" }}
                                      >
                                        {day_study.articles && (
                                          <div className="course_list_content">
                                            {day_study.name && (
                                              <div
                                                className="media_course_title"
                                                style={{
                                                  color: goToClassJudge
                                                    ? "#333"
                                                    : "#999",

                                                  backgroundColor:
                                                    backColor[item_index],
                                                }}
                                              >
                                                {day_study.name}
                                              </div>
                                            )}
                                            <ul>
                                              {day_study.articles.map(
                                                (article, index) => (
                                                  <li key={article.id}>
                                                    <span>{index + 1}. </span>
                                                    <p
                                                      className={`${
                                                        goToClassJudge
                                                          ? ""
                                                          : "p_lock"
                                                      }`}
                                                    >
                                                      {article.name}
                                                    </p>
                                                  </li>
                                                )
                                              )}
                                            </ul>
                                          </div>
                                        )}

                                        {day_study.media_courses.map(
                                          (media_course, idx) => (
                                            <div
                                              className="course_list_content"
                                              key={idx}
                                            >
                                              {media_course.name && (
                                                <div
                                                  className="media_course_title"
                                                  style={{
                                                    color: goToClassJudge
                                                      ? "#333"
                                                      : "#999",
                                                    backgroundColor:
                                                      backColor[item_index],
                                                  }}
                                                >
                                                  {media_course.name}
                                                </div>
                                              )}
                                              <ul>
                                                {/* {console.log("---1111111111111-----",media_course)} */}
                                                {media_course.media_lessons.map(
                                                  (media_lesson, index) => (
                                                    <li key={media_lesson.id}>
                                                      {/* {console.log("----222222222222222----",media_lesson)} */}
                                                      {day_study.day_lock ? (
                                                        ""
                                                      ) : (
                                                        <>
                                                          <div
                                                            className={`pass-border `}
                                                            // className={`${media_lesson.rank>1?"pass-border pass-border-suc":"pass-border "}`}
                                                          ></div>
                                                          <div
                                                            className={`${
                                                              media_lesson.rank >
                                                              1
                                                                ? "pass-border-suc"
                                                                : " "
                                                            }`}
                                                          ></div>
                                                        </>
                                                      )}

                                                      {media_lesson.position && (
                                                        <span
                                                          style={{
                                                            color: day_study.day_lock
                                                              ? "#999"
                                                              : media_lesson.rank !=
                                                                  null &&
                                                                media_lesson.rank <=
                                                                  1
                                                              ? "#FFB017"
                                                              : "#333",
                                                            fontSize: "14px",
                                                            paddingLeft:
                                                              media_lesson.rank >
                                                              1
                                                                ? "0"
                                                                : "5px",
                                                          }}
                                                        >
                                                          {
                                                            media_lesson.position
                                                          }
                                                          .{" "}
                                                        </span>
                                                      )}
                                                      <div
                                                        className={`${
                                                          goToClassJudge
                                                            ? "class-item"
                                                            : "class-item p_lock"
                                                        }`}
                                                        style={{
                                                          color: day_study.day_lock
                                                            ? "#999"
                                                            : media_lesson.rank ==
                                                              null
                                                            ? "#333 "
                                                            : media_lesson.rank <=
                                                              1
                                                            ? "#FFB017"
                                                            : "#333",
                                                        }}
                                                      >
                                                        {media_lesson.nickname
                                                          ? `${media_lesson.nickname} - `
                                                          : ""}
                                                        {
                                                          media_lesson.original_name
                                                        }
                                                        {media_lesson.extension
                                                          ? "【延伸课】"
                                                          : ""}

                                                        {media_course.day_lock ? (
                                                          <img
                                                            className="course_star1"
                                                            src={icon_lock_gray}
                                                          />
                                                        ) : iconStars[
                                                            media_lesson.rank
                                                          ] ? (
                                                          <img
                                                            className="course_star1"
                                                            src={
                                                              iconStars[
                                                                media_lesson
                                                                  .rank
                                                              ]
                                                            }
                                                          />
                                                        ) : (
                                                          ""
                                                        )}
                                                      </div>
                                                    </li>
                                                  )
                                                )}
                                              </ul>
                                            </div>
                                          )
                                        )}

                                        {/* 唤醒 */}
                                        {day_study.day_study_exams.length >
                                        0 ? (
                                          <div className="course_list_content">
                                            <StudyExecMoudle
                                              className="media_course_title_exec "
                                              style={{
                                                color: day_study.day_lock
                                                  ? "#999"
                                                  : "#333",
                                              }}
                                              backUrl={backExecImg[item_index]}
                                            >
                                              {
                                                titles[
                                                  day_study.day_study_exams[0]
                                                    .day_exam_type
                                                ]
                                              }
                                              {/* 唤醒复习 */}
                                            </StudyExecMoudle>

                                            {day_study.day_study_exams.map(
                                              (exam, abci) => (
                                                <div
                                                  key={"abc" + abci}
                                                  style={{
                                                    padding: "10px 0 10px 10px",
                                                    display: "flex",
                                                  }}
                                                >
                                                  {day_study.day_lock ? (
                                                    ""
                                                  ) : (
                                                    <>
                                                      <div
                                                        className={`pass-border `}
                                                        // className={`${media_lesson.rank>1?"pass-border pass-border-suc":"pass-border "}`}
                                                      ></div>{" "}
                                                      <div
                                                        className={`${
                                                          exam.rank == 0 ||
                                                          exam.rank
                                                            ? "pass-border-suc"
                                                            : " "
                                                        }`}
                                                      ></div>
                                                    </>
                                                  )}

                                                  <div
                                                    className={`day_exam_name ${
                                                      goToClassJudge
                                                        ? ""
                                                        : "day_exam_name_lock"
                                                    }`}
                                                    key={exam.id}
                                                    style={{
                                                      width: "80%",
                                                      paddingLeft:
                                                        exam.rank == 0 ||
                                                        exam.rank
                                                          ? "2px"
                                                          : "5px",
                                                    }}
                                                  >
                                                    {exam.name}
                                                    {exam.rank == 0 ||
                                                    exam.rank ? (
                                                      <div
                                                        style={{
                                                          float: "right",
                                                          color: "#FFB017",
                                                        }}
                                                      >
                                                        {exam.rank}
                                                        <div
                                                          style={{
                                                            fontSize: "11px",
                                                            display:
                                                              "inline-block",
                                                          }}
                                                        >
                                                          分
                                                        </div>
                                                      </div>
                                                    ) : (
                                                      ""
                                                    )}
                                                  </div>
                                                </div>
                                              )
                                            )}
                                          </div>
                                        ) : (
                                          ""
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </React.Fragment>
                            ) : (
                              <HolidayRestPrompt
                                group={group}
                                key={index}
                                renderActiveTagPrompt={
                                  this.renderActiveTagPrompt
                                }
                                activeCurrentProgress={activeCurrentProgress}
                                day_study={day_study}
                                goToClassJudge={goToClassJudge}
                                // handleLockedDialog={this.handleLockedDialog}
                              />
                            );
                          }
                        )}{" "}
                        {
                          // 完成某一天课程后，未提交过问卷时，课表学习卡片下方入口, 始终放在最新学习卡片的下面
                          // 【只在用户已经填写了问卷后，才不显示】
                          questionnaireUrl &&
                            anchorMultiEleFirstDate &&
                            this.customizedQuestionnaireEntry(questionnaireUrl)
                        }
                      </div>
                    </React.Fragment>
                  );
                })}
              </StudyPackageModulesStyle>
            );
          })}
          <CurrentUnitEnd unitThemeColor={unitThemeColor} />
        </div>

        {upgradePackage?.study_package_activity_buy_url &&
          this.unlockOneWeekButton(true)}
        {/* {
          // 底部浮动文案, 试听体验课才显示底部
          !handbook && upgradePackageRequired && upgradePackage && (
            <UnlockAllCourse
              title="解锁全部课程，让孩子思维拓展不断档"
              subTitle={`${upgradePackage?.media_lessons_count}节课，覆盖当前年龄段绝大多数知识点`}
              url={`${upgradePackage?.study_package_activity_buy_url}&ref=unlock`}
              buttonText="解锁"
            />
          )
        } */}
      </>
    ) : (
      ""
    );
  }
}

const SubSlideGuide = ({ studyPackageModules }) => {
  const slideThemeColor = [
    "#7ac8f4",
    "#7aaef4",
    "#7a89f4",
    "#9488ec",
    "#a377ec",
  ];

  const difficulty_type = ["K0", "K1.0", "K1.5", "K2.0", "K2.5"];
  const handleAnchorFocus = (e) => {
    const anchorIndex = e.currentTarget.getAttribute("data-index");
    console.log("anchorIndex: ", anchorIndex);
    const studyPackageModulesBox = document.getElementsByClassName(
      "study_package_modules"
    );
    const modulesBoxArr = [].slice.call(studyPackageModulesBox);

    if (modulesBoxArr && anchorIndex !== undefined) {
      if (modulesBoxArr && anchorIndex >= 0) {
        modulesBoxArr[anchorIndex].scrollIntoView(true);
        window.scrollBy({
          top: 0,
        });
      }
    }
  };

  if (!studyPackageModules) return "";
  return (
    <div className="sub_slide_theme_guide clear_scroll_bar">
      {studyPackageModules.map((theme, index) => {
        const difficulty_index = difficulty_type.findIndex(
          (item) => item == theme.difficulty_stage
        );
        return (
          <div
            className="poetry_theme"
            key={index}
            data-index={index}
            onClick={handleAnchorFocus}
          >
            <div className="img_container">
              <img
                data-src={theme.main_photo_url}
                className="lazyload"
                alt=""
              />
            </div>
            <div className="theme_name">{index + 1 + ". " + theme.name}</div>
            {theme.difficulty_stage && (
              <div
                className="study_phase"
                style={{
                  background: slideThemeColor[difficulty_index],
                }}
              >
                {`${theme.difficulty_stage} 阶段`}
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
};

// 提示图标

const CurrentProgressText = ({
  group,
  dayStudy,
  unitThemeColor,
  marginTop,
}) => {
  // console.log("------------group", group);
  const includeFirstDay = (dayStudy) => {
    return dayStudy.handbook ? "请先从这里开始" : "开始第1天学习";
  };

  const excludeFirstDay = (dayStudy) => {
    return dayStudy.handbook
      ? "上次学到这里，继续学习"
      : dayStudy.start_rest_day
      ? "今日休息"
      : "上次学到这里，继续学习";
  };
  return (
    <div
      className="current_progress 1111"
      id="current_progress"
      style={{
        background: "#000",
        opacity: "0.6",
        marginTop,
      }}
    >
      <div>
        本单元进度：
        <div style={{ color: " #f9e363", display: "inline-block" }}>
          {group.studied_days + "/" + group.day_studies_count}
        </div>
      </div>
      <div>
        {dayStudy.day == 1
          ? includeFirstDay(dayStudy)
          : excludeFirstDay(dayStudy)}
      </div>
      <span style={{ borderTop: `6px solid  #000` }}></span>
    </div>
  );
};

const CurrentUnitEnd = ({ unitThemeColor }) => {
  return (
    <div className="current_unit_end" style={{ color: unitThemeColor?.font }}>
      本单元结束
    </div>
  );
};

const HolidayRestPrompt = ({
  group,
  goToClassJudge,
  renderActiveTagPrompt,
  activeCurrentProgress,
  day_study,
  handleLockedDialog,
}) => {
  // console.log(
  //   "HolidayRestPrompt---------------studied_media_lessons_count",
  //   group
  // );
  const isSameDay =
    formatDotDate(day_study.start_rest_day) ===
    formatDotDate(day_study.end_rest_day);
  let showDate;
  if (day_study.week) {
    showDate = `${formatDotDate(day_study.week_start)} - ${formatDotDate(
      day_study.week_end
    )}`;
  } else if (isSameDay) {
    showDate = formatDotDate(day_study.start_rest_day);
  } else {
    showDate = `${formatDotDate(day_study.start_rest_day)} - ${formatDotDate(
      day_study.end_rest_day
    )}`;
  }
  return (
    <div
      className="course_list_container_box"
      data-id={activeCurrentProgress ? "current_progress" : ""}
    >
      {renderActiveTagPrompt(group, activeCurrentProgress, day_study)}
      <div
        className={`course_list_container ${
          goToClassJudge ? "" : "course_list_container_lock"
        }`}
        // onClick={goToClassJudge ? null : handleLockedDialog}
      >
        {day_study.state == "pass" && (
          <img src={icon_class_pass} className="class-pass"></img>
        )}

        <div
          className={`course_list_section ${
            goToClassJudge ? "" : "course_list_section_lock"
          }`}
          style={{
            borderBottom: goToClassJudge ? ".5px solid #ddd" : null,
            paddingLeft: day_study.state == "pass" ? "43px" : "15px",
            // background: activePlayStudy ? groupBackColor[item_index] : "",
          }}
        >
          <div className="day_and_study_report_entrance">
            <div className="day_or_date">
              <div className="date_day_box">
                <div className="date">{showDate}</div>
              </div>
            </div>
          </div>
        </div>
        <div className="media_course_list_holiday">
          <img src={icon_package_placeholder} alt="" />
          <div className="rest_content">
            <p>休息日</p>
            <p>补课或复习之前的内容吧</p>
          </div>
        </div>
      </div>
    </div>
  );
};
const getAudioResult = (infoUrl) => {
  return request({
    url: infoUrl,
    method: "get",
  }).then((resp) => {
    const totalTime = parseInt(resp.data.format.duration);
    return formatAudioTime(totalTime);
  });
};

const CourseDetailSection = ({
  singleDayStudies,
  handleCloseDetail,
  icon_stars,
  system_time,
  is_parent_class,
  handleArticleRequest,
  isUserStudyPackage,
  studyMode,
}) => {
  const [audioTimeList, setAudioTimeList] = useState([]);
  const day_study = singleDayStudies;
  // console.log('day_study: ', day_study);
  const now_date = new Date(system_time);
  const articlesArray =
    day_study && day_study.articles && day_study.articles.length > 1;
  const mediaLessons =
    day_study.media_lessons && day_study.media_lessons.length > 0;
  const dayStudyExams =
    day_study.day_study_exams && day_study.day_study_exams.length > 0;
  const calculateTrainingCamps =
    day_study.calculate_training_camps &&
    day_study.calculate_training_camps.length > 0;

  const study_report = day_study.study_report;
  const studyReportUrl = day_study.study_report_url;

  useEffect(() => {
    const { media_lessons } = singleDayStudies;
    if (media_lessons && media_lessons.length > 0) {
      const mediaLessonsAudioInfoUrls = media_lessons.map(
        (mediaLesson) => mediaLesson.audio_info_url
      );
      Promise.all(mediaLessonsAudioInfoUrls.map((url) => getAudioResult(url)))
        .then((audioTimesList) =>
          setAudioTimeList(() => audioTimesList.map((time) => ({ time })))
        )
        .catch((err) => {
          console.log(err);
        });
    }
  }, [singleDayStudies, singleDayStudies.media_lessons]);

  return (
    <div className="course_detail_container">
      <div
        className={`course_detail_section ${
          studyMode == "customized" ? "customized_course_detail_section" : ""
        }`}
      >
        <div className="course_detail_box">
          <div className="head_title">
            <p>学习内容</p>
            <div className="close_dialog" onClick={handleCloseDetail}>
              &times;
            </div>
          </div>
          <div className="calendar_course_list">
            {study_report && (
              <div className="button_study_report">
                <a href={`${studyReportUrl}&ref=daily_course`}>今日学习报告</a>
              </div>
            )}
            {
              // 学前体验 “家长须知”, 单天模块
              articlesArray &&
                day_study.articles.map((article, index) => (
                  <ArticlesSection
                    key={article.id}
                    article={article}
                    index={index}
                    handleArticleRequest={handleArticleRequest}
                    isUserStudyPackage={isUserStudyPackage}
                  />
                ))
            }

            {
              //  * media_lessons 课程学习模块
              mediaLessons &&
                day_study.media_lessons.map((media_lesson, idx) => {
                  return (
                    <MediaLessonsSection
                      key={media_lesson.id}
                      media_lesson={media_lesson}
                      day_lock={day_study.day_lock}
                      icon_stars={icon_stars}
                      lesson_time={audioTimeList[idx]}
                      is_parent_class={is_parent_class}
                      isUserStudyPackage={isUserStudyPackage}
                    />
                  );
                })
            }

            {/* {
              // * 新增速度闯关练习类型
              calculateTrainingCamps && (
                <CalcalateTrainingCamps
                  calculate_training_camps={day_study.calculate_training_camps}
                  lock={day_study.lock}
                  day_lock={day_study.day_lock}
                  speedPracticeClick={this.speedPracticeClick}
                  day_study_start_time={
                    new Date(day_study.day_study_start_time)
                  }
                  now_date={now_date}
                />
              )
            } */}
            {
              // * 随堂测试（原今日练习）模块
              day_study.today_quiz_url && day_study.quizzes_count > 0 && (
                <TodayQuizSection
                  today_quiz_url={day_study.today_quiz_url}
                  day_lock={day_study.day_lock}
                  today_quiz_status={day_study.today_quiz_status}
                  quizzes_count={day_study.quizzes_count}
                  isUserStudyPackage={isUserStudyPackage}
                  avgTime={day_study.avg_time}
                />
              )
            }

            {/* {
              // * 课外练习模块 (随堂测试)
              day_study.exercise_quiz_url &&
                day_study.exercise_quizzes_count > 0 && (
                  <ExamineSection
                    day_lock={day_study.day_lock}
                    exercise_quiz_url={day_study.exercise_quiz_url}
                    exercise_quiz_status={day_study.exercise_quiz_status}
                    exercise_quizzes_count={day_study.exercise_quizzes_count}
                    isUserStudyPackage={isUserStudyPackage}
                    exerciseAvgTime={day_study.exercise_avg_time}
                  />
                )
            } */}
            {
              // * day_study_exams 唤醒模块/单元/期末
              dayStudyExams &&
                day_study.day_study_exams.map(
                  (day_study_exam) =>
                    day_study_exam.quizzes_count > 0 && (
                      <AwakeSection
                        key={day_study_exam.id}
                        day_study_exam={day_study_exam}
                        day_lock={day_study.day_lock}
                        icon_stars={icon_stars}
                        isUserStudyPackage={isUserStudyPackage}
                        avgTime={day_study_exam.avg_time}
                      />
                    )
                )
            }
            {
              // * 课外练习模块 (随堂测试)
              day_study.exercise_quiz_url &&
                day_study.exercise_quizzes_count > 0 && (
                  <ExamineSection
                    day_lock={day_study.day_lock}
                    exercise_quiz_url={day_study.exercise_quiz_url}
                    exercise_quiz_status={day_study.exercise_quiz_status}
                    exercise_quizzes_count={day_study.exercise_quizzes_count}
                    isUserStudyPackage={isUserStudyPackage}
                    exerciseAvgTime={day_study.exercise_avg_time}
                  />
                )
            }
          </div>
        </div>
      </div>
    </div>
  );
};

export default class UnitModeStudyPackage extends Component {
  constructor(props) {
    super(props);
    console.log("props11--UnitModeStudyPackage:", props);
    this.state = {
      createRecommendRequestUrl: props.create_recommend_request_url,
      courseDetailStatus: false,
      writeEvaluateDialogState: false,
    };
  }

  // 点击“写评价” 控制显示
  getWriteEvaluateStatus = () => {
    this.setState({
      writeEvaluateDialogState: true,
    });
    GA("shortcut", "packageComment", this.props.study_package_activity_id);
    GA_new(
      "shortcut",
      "package_comment",
      this.props.study_package_activities[0].id,
      this.props.target_type,
      this.props.target_id
    );
  };

  // 关闭评价弹框
  hideWriteEvaluateState = () => {
    this.setState({
      writeEvaluateDialogState: false,
    });
  };

  // 请求接口数据成功之后, 链接置空
  getCreateRecommendRequestUrl = () => {
    this.setState({
      createRecommendRequestUrl: "",
    });
  };

  onInvitationIconClick = () => {
    const {
      get_shopping_group_data_url,
      poster_photo_template_url: posterTemplate,
      poster_creation: posterCreation,
      study_package_activity_id,
    } = this.props;
    const toast = ToastManager.showLoading("海报加载中...");
    request({
      method: "GET",
      url: get_shopping_group_data_url,
      headers: csrfHeaders,
    })
      .then((res) => res.data)
      .then(
        ({
          bonus,
          had_joined_groupon,
          remaining_shopping_group_count,
          avatar_location,
        }) => {
          new SharePosterProxy({
            // posterUrl: this.props.poster_url,
            // posterImgSrc: this.props.poster_img_src,
            avatar_location,
            posterTemplate,
            posterCreation,
            mode: "front_end",
            free:
              had_joined_groupon && remaining_shopping_group_count > 0
                ? "in_or_open_shopping_group"
                : "none_shopping_group",
            bonus: bonus,
            sale_mode: "shopping_group",
            remaining_shopping_group_count,
          })
            .open({
              isShowToast: false,
            })
            .then(() => {
              toast.cancel();
              // GA("shortcut", "packagePoster", study_package_activity_id);
            });
        }
      )
      .catch((err) => {
        toast.cancel();
        const dialogOptions = {
          text: "生成海报出错，请重新尝试",
          mainLabel: "确定",
          subLabel: "取消",
          mainCallback: () => {
            this.onInvitationIconClick();
          },
          subCallback: () => {},
        };
        showDialog.call(this, dialogOptions);
        console.log(err);
      });
    GA("shortcut", "reportComment", this.props.study_package_activities[0].id);
    GA_new(
      "shortcut",
      "report_comment",
      this.props.study_package_activities[0].id,
      this.props.target_type,
      this.props.target_id
    );
  };

  onStudyReportIconClick = () => {
    GA("shortcut", "study_reports", this.props.study_package_activities[0].id);
    GA_new(
      "shortcut",
      "study_reports",
      this.props.study_package_activities[0].id,
      this.props.target_type,
      this.props.target_id
    );
    window.location.href = `${this.props.study_report_url}&ref=shortcut_course`;
  };
  onSettingIconClick = () => {
    GA("shortcut", "packageSetting", this.props.study_package_activities[0].id);

    GA_new(
      "shortcut",
      "package_setting",
      this.props.study_package_activities[0].id,
      this.props.target_type,
      this.props.target_id
    );
    window.location.href = this.props.study_setting_url;
  };
  // 错题本
  handleWrongBookClick = () => {
    GA(
      "shortcut",
      "package_wrong_quiz",
      this.props.study_package_activities[0].id
    );

    GA_new(
      "shortcut",
      "package_wrong_quiz",
      this.props.study_package_activities[0].id,
      this.props.target_type,
      this.props.target_id
    );
    const activitiesLength = this.props?.study_package_activities?.length;
    const head_menus = document.getElementsByClassName("head_menu_lis");
    if (this.props.get_revisal_quiz_sets_url && head_menus?.length) {
      $(head_menus[activitiesLength]).trigger("click");
    }
  };
  // 返回课程
  handleBackCourse = () => {
    GA("shortcut", "packageBack", this.props.study_package_activities[0].id);
    GA_new(
      "shortcut",
      "package_back",
      this.props.study_package_activities[0].id,
      this.props.target_type,
      this.props.target_id
    );
    const isRegularCourseIndex = this.props.study_package_activities.findIndex(
      (activity) => !activity.handbook
    );
    const head_menus = document.getElementsByClassName("head_menu_lis");
    if (isRegularCourseIndex >= 0 && head_menus?.length) {
      $(head_menus[isRegularCourseIndex]).trigger("click");
    }
  };

  handleBackToTop = () => {
    $("#_scroll_list").animate({ scrollTop: 0 }, 500);
  };

  // 每隔30分钟，当前页面从隐藏到显示，就强制刷新页面
  listenVisibilityEventChange() {
    let lastReloadTime = new Date();
    document.addEventListener(
      "visibilitychange",
      function () {
        const nowTime = new Date();
        const intervel = (nowTime - lastReloadTime) / 1000;
        if (document.visibilityState === "visible" && intervel >= 30 * 60) {
          window.location.reload(true);
          lastReloadTime = new Date();
        }
      },
      false
    );
  }
  componentDidMount() {
    const { study_package_activities, getDefaultActiveUnit } = this.props;

    this.listenVisibilityEventChange();

    const units_title = study_package_activities.map((unit) => ({
      name: unit.name,
      id: unit.id,
      handbook: unit.handbook,
      photoUrl: unit.photo_url,
    }));
    console.log("1752_units_title: ", units_title);

    const { index } = getDefaultActiveUnit();
    console.log("getDefaultActiveUnit_index: ", index);
  }
  componentWillUnmount() {
    window.removeEventListener(
      "visibilitychange",
      this.listenVisibilityEventChange
    );
  }

  // 回调函数获取是否是试听课
  getUpgradePackageRequiredStaus = (boolState) => {
    this.setState({
      upgradePackageRequired: boolState,
    });
  };
  getRevisalQuizzesStatus = (boolState) => {
    this.setState({
      revisalQuizzesStatus: boolState,
    });
  };

  render() {
    console.log("--------this.props------", this.props);
    console.log("--------this.state------", this.state);
    const {
      study_package_activities,
      getUnitStudy,
      parent_guide_url,
      get_study_package_url,
      get_wrong_quiz_sets_url,
      course_user_setting_url,
      jump_to_wrong_quiz_sets,
      getDefaultActiveUnit,
      is_parent_class,
      study_package_system_time,
      study_package_start_time,
      lock,
      themeColor,
      reminder_time_hour,
      config_reminder_time_url,
      upgrade_package,
      thinking_exam,
      current_study_date,
      current_study_day,
      is_user_study_package,
    } = this.props;

    const units_title = study_package_activities.map((unit) => ({
      name: unit.name,
      id: unit.id,
      handbook: unit.handbook,
      photoUrl: unit.photo_url,
    }));

    const {
      // courseDetailStatus,
      writeEvaluateDialogState,
      createRecommendRequestUrl,
      upgradePackageRequired,
      revisalQuizzesStatus,
      unitModules,
    } = this.state;

    const { index } = getDefaultActiveUnit();
    console.log(
      "render_getDefaultActiveUnit_index: ",
      upgrade_package,
      this.props
    );

    // const lastUsedActiveTabId = index;
    let lastUsedActiveTabId = 0;
    if (jump_to_wrong_quiz_sets) {
      lastUsedActiveTabId = 1;
    } else {
      if (
        new Date(study_package_system_time) < new Date(study_package_start_time)
      ) {
        // 系统时间 < 正价课开始时间（还没到正价课课程包开始时间）
        lastUsedActiveTabId = 0;
      } else {
        // 从localStorage获取之前存取activeTabId的值
        // const activeTabId = localStorage.getItem("activeTabId");
        // if (activeTabId) {
        //   lastUsedActiveTabId = activeTabId;
        // } else {
        //   lastUsedActiveTabId = index + 1;
        // }
        lastUsedActiveTabId = index;
      }
    }
    console.log("lastUsedActiveTabId: ", lastUsedActiveTabId);

    return (
      <div
        className={`multi_unit_mode_study_package clear_scroll_bar ${
          upgradePackageRequired ? "comp_trial_unit_mode_study_package" : ""
        }`}
        id="_scroll_list"
      >
        {
          <SliderMenu
            getUnitStudy={getUnitStudy}
            parent_guide_url={parent_guide_url}
            get_study_package_url={get_study_package_url}
            get_wrong_quiz_sets_url={get_wrong_quiz_sets_url}
            units_title={units_title}
            activeTabId={lastUsedActiveTabId}
            is_parent_class={is_parent_class}
            study_package_system_time={study_package_system_time}
            study_package_start_time={study_package_start_time}
            lock={lock}
            target_id={this.props.target_id}
            target_type={this.props.target_type}
            themeColor={themeColor}
            getCourseDetailStatus={this.getCourseDetailStatus}
            getUpgradePackageRequiredStaus={this.getUpgradePackageRequiredStaus}
            getRevisalQuizzesStatus={this.getRevisalQuizzesStatus}
            upgradePackage={upgrade_package}
            thinkingExam={thinking_exam}
            currentStudyDate={current_study_date}
            currentStudyDay={current_study_day}
            renderTabPaneContent={(extraProps) => (
              <CourseListSectionComp {...extraProps} {...this.props} />
            )}
          />
        }
        {!revisalQuizzesStatus ? (
          <RightFloatIcons
            isShowMakeComment={
              this.props.comment_state === "uncommented" &&
              !!createRecommendRequestUrl
            }
            onMakeCommentClick={this.getWriteEvaluateStatus}
            isShowRedMoney={!!this.props.bonus}
            onRedMoneyClick={this.onInvitationIconClick}
            isShowReport
            onReportClick={this.onStudyReportIconClick}
            isShowWrongBook
            onWongBookClick={this.handleWrongBookClick}
            isShowSetting={this.props.trial_unlock_mode ? false : true}
            onSetting={this.onSettingIconClick}
            bottomPosition={this.props.trial_unlock_mode ? 170 : 80}
            // isShowScrollTop
            // onScrollTop={this.handleBackToTop}
          />
        ) : (
          <RightFloatIcons
            isShowBack
            onBackClick={this.handleBackCourse}
            // isShowSetting={this.props.trial_unlock_mode ? false : true}
            // onSetting={this.handleBackToTop}
            // isShowScrollTop
            // onScrollTop={this.handleBackToTop}
            bottomPosition={80}
          />
        )}
        {writeEvaluateDialogState && (
          <StudyPackageCommentModule
            create_recommend_request_url={createRecommendRequestUrl}
            comment_target={this.props.comment_target}
            current_user_comment={this.props.current_user_comment}
            bought_phase={this.props.bought_phase}
            promotion={this.props.promotion}
            rate={this.props.rate}
            name={this.props.study_package_name}
            hideDialog={this.hideWriteEvaluateState}
            getCreateRecommendRequestUrl={this.getCreateRecommendRequestUrl}
          />
        )}
      </div>
    );
  }
}

export class StudyPackageCommentModule extends Component {
  constructor(props) {
    super(props);
    const { rate, current_user_comment } = props;
    this.state = {
      showRuleDialog: false,
      selectIndex: rate ? rate - 1 : -1,
      rate: rate || "",
      recommendText: current_user_comment || "",
    };
  }

  btnCloseRecommendListClick = () => {
    this.props.hideDialog();
  };

  onSelectLimitClick = (e) => {
    const index = e.currentTarget.getAttribute("data-index");
    const value = e.currentTarget.getAttribute("data-value");
    this.setState({
      selectIndex: index,
      rate: value,
    });
  };

  handleChange = (e) => {
    const value = e.target.value;
    this.setState({
      recommendText: value,
    });
  };

  handleSubmit = () => {
    if (this.state.selectIndex === -1) {
      ToptipsManager.warn("请您给课程打个分");
      return;
    } else if (this.state.recommendText === "") {
      ToptipsManager.warn("请先填写您的推荐语");
      return;
    } else {
      if (this.state.recommendText.length >= 20) {
        this.setState({
          recommendText: this.state.recommendText,
        });
        this.toast = ToastManager.showLoading("提交中...");
        const params = {
          text: this.state.recommendText,
          rate: this.state.rate,
          commentable_id: this.props.comment_target.commentable_id,
          commentable_type: this.props.comment_target.commentable_type,
          reply_to_id: this.props.comment_target.reply_to_id,
          note: this.props.bought_phase,
        };
        if (this.props.promotion && this.props.promotion.reward) {
          params.reward_id = this.props.promotion.reward.id;
        }
        var url = this.props.create_recommend_request_url;
        request({
          url: url,
          method: "POST",
          headers: csrfHeaders,
          data: params,
        })
          .then((resp) => {
            this.toast.cancel();
            const data = resp.data;
            console.log(data);
            this.props.hideDialog();
            this.props.getCreateRecommendRequestUrl();
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        ToptipsManager.warn("填写推荐语字数应大于20个字");
        return;
      }
    }
  };

  // 活动规则显示
  activityRuleClick = () => {
    this.setState({
      showRuleDialog: true,
    });
  };

  // 活动规则隐藏
  hideActivityRule = () => {
    this.setState({
      showRuleDialog: false,
    });
  };
  render() {
    const { promotion, name } = this.props;
    const activityCommentRule = () => {
      return promotion && promotion.brief ? (
        <div className="activity_rule_text">
          <img src={iconGift} alt="" />
          {promotion.brief}，
          <span onClick={this.activityRuleClick}>活动规则</span>
        </div>
      ) : (
        ""
      );
    };

    return (
      <React.Fragment>
        <div className="study_packag_comment_module">
          <div className="overlay"></div>
          <div className="comment_list_container">
            <div className="course_name">
              评价
              {name && `《${name}》`}
            </div>
            <div
              className="close_btn_right"
              style={{ top: -4 }}
              onClick={this.btnCloseRecommendListClick}
            >
              &times;
            </div>
            {activityCommentRule()}
            <div className="recommend_score">
              <div className="text">
                您是否愿意把课程推荐给朋友？（1-10分请打分）
              </div>
              <div className="number_list">
                <ul>
                  {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((item, index) => (
                    <li
                      key={index}
                      data-index={index}
                      data-value={item}
                      onClick={this.onSelectLimitClick.bind(this)}
                      className={
                        this.state.selectIndex == index ? "highLight" : ""
                      }
                    >
                      {item}
                    </li>
                  ))}
                </ul>
                <div className="degree">
                  <span>
                    非常
                    <br />
                    不愿意
                  </span>
                  <span>一般</span>
                  <span>
                    非常
                    <br />
                    愿意
                  </span>
                </div>
              </div>
              <div className="fill_recommend">
                <p>请填写评价</p>
                <div className="field_textarea">
                  <textarea
                    type="text"
                    placeholder="说说你对课程的感受或者收获吧，给别人一个参考。(请不少于20字)"
                    value={this.state.recommendText}
                    onChange={this.handleChange.bind(this)}
                  />
                </div>
                <button onClick={this.handleSubmit.bind(this)}>确定</button>
              </div>
            </div>
          </div>
        </div>
        {this.state.showRuleDialog && (
          <ActivityRuleDialog
            promotion={this.props.promotion}
            hideActivityRule={this.hideActivityRule}
          />
        )}
      </React.Fragment>
    );
  }
}

// 活动评论规则
export const ActivityRuleDialog = ({ promotion, hideActivityRule }) => {
  return (
    <div className="activity_rule">
      <div className="activity_rule_content">
        <div className="rule_content">
          <h3>活动规则</h3>
          <div className="react_markdown">
            {promotion && <ReactMarkdown source={promotion.rule} />}
          </div>
          <div
            className="close_btn_right"
            style={{ top: -4 }}
            onClick={() => hideActivityRule()}
          >
            &times;
          </div>
        </div>
      </div>
    </div>
  );
};

const AwakeSection = ({
  day_study_exam,
  day_lock,
  isUserStudyPackage,
  avgTime,
}) => {
  const score = day_study_exam.score >= 0;
  const goPracticeStyle = day_lock == false && !score;

  return (
    <a
      href={day_lock == true ? "javascript:void(0)" : day_study_exam.url}
      className="awake_style"
    >
      <div
        className={`calendar_examine ${
          goPracticeStyle ? "go_practice_style" : ""
        }`}
      >
        {day_lock == false ? (
          score ? (
            <div className="score">
              <i>{day_study_exam.score}</i>分
            </div>
          ) : (
            <div className="go_practice awake_practice">去测试</div>
          )
        ) : (
          <img className="course_star" src={icon_lock_gray} />
        )}

        <div>
          <div className="knowledge_content">
            <h2 style={{ color: day_lock == true && "#999" }}>
              {day_study_exam.name}
            </h2>
          </div>

          <div className="course_subscrible_practice">
            <div
              className={`course_amount_time ${
                isUserStudyPackage
                  ? "course_amount_time_lock awake_course_amount_time_lock"
                  : ""
              }`}
            >
              {isUserStudyPackage ? (
                <>
                  {avgTime > 0 ? (
                    <i>{`约${Math.ceil(avgTime / 60)}分钟`}</i>
                  ) : (
                    ""
                  )}
                  <span>{day_study_exam.quizzes_count}道题</span>
                  {day_study_exam.count_down_mins && (
                    <span>限时：{day_study_exam.count_down_mins}分钟</span>
                  )}
                </>
              ) : (
                <>
                  <i>{day_study_exam.quizzes_count}道题</i>
                  {day_study_exam.count_down_mins && (
                    <span>限时：{day_study_exam.count_down_mins}分钟</span>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </a>
  );
};

const operateArticleUrl = (url, isUserStudyPackage) => {
  const {
    query: { study_package_activity_id },
  } = queryString.parseUrl(window.location.href);

  const studyPackageActivityIdExit = url.includes("?study_package_activity_id");
  // 是个性化课表 + 链接中不存在study_package_activity_id参数
  const flag = isUserStudyPackage && !studyPackageActivityIdExit;

  const article_url = flag
    ? `${
        url.indexOf("?") == -1 ? (url += "?") : (url += "&")
      }study_package_activity_id=${study_package_activity_id}`
    : url;
  return article_url;
};

const ArticlesSection = ({
  article,
  index,
  handleArticleRequest,
  isUserStudyPackage,
}) => {
  const url = operateArticleUrl(article.url, isUserStudyPackage);
  return (
    <a
      target="_blank"
      href={url}
      className="media_lesson_style"
      data-url={article.create_study_record}
      onClick={debounce(handleArticleRequest)}
    >
      <div className="calendar_course go_class_style">
        <div className="go_class lesson_practice">查看</div>
        <div className="knowledge_content">
          <div className="media_lesson_names">
            <span>{index + 1}. </span>
            <h2>{article.name}</h2>
          </div>
        </div>
      </div>
    </a>
  );
};

const MediaLessonsSection = ({
  media_lesson,
  day_lock,
  icon_stars,
  lesson_time,
  is_parent_class,
  isUserStudyPackage,
}) => {
  let courseEverydayTotalStatus;
  const goClassStyle = day_lock == false && !icon_stars[media_lesson.rank];

  if (day_lock == true) {
    courseEverydayTotalStatus = (
      <img className="course_star" src={icon_lock_gray} />
    );
  } else {
    if (icon_stars[media_lesson.rank]) {
      courseEverydayTotalStatus = (
        <img className="course_star" src={icon_stars[media_lesson.rank]} />
      );
    } else {
      courseEverydayTotalStatus = (
        <div className="go_class lesson_practice">去上课</div>
      );
    }
  }

  return (
    <a
      href={day_lock == true ? "javascript:void(0)" : media_lesson.url}
      data-url={day_lock == true ? "" : media_lesson.url}
      className="media_lesson_style"
    >
      <div
        className={`calendar_course ${goClassStyle ? "go_class_style" : ""}`}
      >
        {courseEverydayTotalStatus}
        <div className="knowledge_content">
          {!is_parent_class && (
            <div className="knowledge_small_title">
              {media_lesson.media_course_name}
            </div>
          )}
          <div className="media_lesson_names">
            {media_lesson.position && <span>{media_lesson.position}. </span>}
            <h2 style={{ color: day_lock == true && "#999" }}>
              {media_lesson.nickname ? `${media_lesson.nickname} - ` : ""}
              {media_lesson.original_name}
              {media_lesson.extension ? "【延伸课】" : ""}
            </h2>
          </div>
          {
            // media_lesson.objective && <p>{media_lesson.objective}</p>
          }
          <div className="course_subscrible_practice">
            <div className="course_time_difficulty">
              {isUserStudyPackage ? (
                media_lesson.avg_time > 0 ? (
                  <i>{`约${Math.ceil(media_lesson.avg_time / 60)}分钟`}</i>
                ) : (
                  ""
                )
              ) : (
                <i>{lesson_time?.time}</i>
              )}
              {"resource_quiz_count" in media_lesson && (
                <span>{media_lesson.resource_quiz_count}次互动</span>
              )}
              {
                // “难度”字段 暂不做显示
                // (media_lesson.difficulty != null) && <span>{media_lesson.difficulty}</span>
              }
            </div>
          </div>
        </div>
      </div>
    </a>
  );
};

const CalcalateTrainingCamps = ({
  calculate_training_camps,
  day_lock,
  speedPracticeClick,
  day_study_start_time,
  now_date,
}) => {
  let handleClickSpeedPractice;
  let imgStar;

  if (day_lock === false) {
    handleClickSpeedPractice = day_lock === true ? null : speedPracticeClick;
    imgStar = "";
  } else {
    handleClickSpeedPractice =
      day_study_start_time < now_date ? speedPracticeClick : null;
    if (day_study_start_time > now_date) {
      imgStar = <img className="course_star" src={icon_lock_gray} />;
    }
  }

  return calculate_training_camps.map((camp, position) => (
    <a
      key={position}
      href="javascript:void(0)"
      onClick={handleClickSpeedPractice}
    >
      <div className="calendar_examine">
        {imgStar}
        <div className="knowledge_content">
          <h2
            style={{
              color:
                day_lock == true && day_study_start_time > now_date && "#999",
            }}
          >
            {camp.training_camp_name}（第{camp.position}关）：
            {camp.calculate_training_name}
          </h2>
        </div>
      </div>
    </a>
  ));
};

const TodayQuizSection = ({
  today_quiz_url,
  day_lock,
  today_quiz_status,
  quizzes_count,
  isUserStudyPackage,
  avgTime,
}) => {
  let todayQuizStatus;
  const goPracticeStyle = day_lock === false && today_quiz_status === false;

  if (day_lock == true) {
    todayQuizStatus = <img className="course_star" src={icon_lock_gray} />;
  } else {
    if (today_quiz_status === false) {
      todayQuizStatus = (
        <div className="go_practice now_quiz_practice">去测试</div>
      );
    } else {
      todayQuizStatus = (
        <img className="course_star course_quiz_pass" src={icon_quiz_pass} />
      );
    }
  }
  return (
    <a
      href={day_lock == true ? "javascript:void(0)" : today_quiz_url}
      className="today_quiz_style"
    >
      <div
        className={`calendar_examine ${
          goPracticeStyle ? "go_practice_style" : ""
        }`}
      >
        {todayQuizStatus}
        <div>
          <div className="knowledge_content">
            <h2 style={{ color: day_lock == true && "#999" }}>随堂测试</h2>
          </div>

          <div className="course_subscrible_practice">
            <div
              className={`course_amount_time ${
                isUserStudyPackage ? "course_amount_time_lock" : ""
              }`}
            >
              {isUserStudyPackage ? (
                <>
                  {avgTime > 0 ? (
                    <i>{`约${Math.ceil(avgTime / 60)}分钟`}</i>
                  ) : (
                    ""
                  )}
                  <span>{quizzes_count}道题</span>
                </>
              ) : (
                <i>{quizzes_count}道题</i>
              )}
            </div>
          </div>
        </div>
      </div>
    </a>
  );
};

const ExamineSection = ({
  day_lock,
  exercise_quiz_url,
  exercise_quiz_status,
  exercise_quizzes_count,
  isUserStudyPackage,
  exerciseAvgTime,
}) => {
  let examineStatus;
  const goPracticeStyle = day_lock === false && exercise_quiz_status === false;

  if (day_lock == true) {
    examineStatus = <img className="course_star" src={icon_lock_gray} />;
  } else {
    if (exercise_quiz_status === false) {
      examineStatus = (
        <div className="go_practice now_quiz_practice">去练习</div>
      );
    } else {
      examineStatus = (
        <img className="course_star course_quiz_pass" src={icon_quiz_pass} />
      );
    }
  }
  return (
    <a
      href={day_lock == true ? "javascript:void(0)" : exercise_quiz_url}
      className="examine_style"
    >
      <div
        className={`calendar_examine ${
          goPracticeStyle ? "go_practice_style" : ""
        }`}
      >
        {examineStatus}
        <div>
          <div className="knowledge_content">
            <h2 style={{ color: day_lock == true && "#999" }}>
              课外练习（选做）
            </h2>
          </div>

          <div className="course_subscrible_practice">
            <div
              className={`course_amount_time ${
                isUserStudyPackage ? "course_amount_time_lock" : ""
              }`}
            >
              {isUserStudyPackage ? (
                <>
                  {exerciseAvgTime > 0 ? (
                    <i>{`约${Math.ceil(exerciseAvgTime / 60)}分钟`}</i>
                  ) : (
                    ""
                  )}
                  <span>{exercise_quizzes_count}道题</span>
                </>
              ) : (
                <i>{exercise_quizzes_count}道题</i>
              )}
            </div>
          </div>
        </div>
      </div>
    </a>
  );
};
