import React from "react";
import styled from "styled-components";
import { request, csrfHeaders } from "../../utils/request";
import icon_unit_title0 from "reading/icon_unit_title0.png";
import icon_unit_title1 from "reading/icon_unit_title1.png";
import icon_unit_title2 from "reading/icon_unit_title2.png";
import icon_unit_title3 from "reading/icon_unit_title3.png";
import icon_unit_back0 from "reading/icon_unit_back0.png";
import icon_unit_back1 from "reading/icon_unit_back1.png";
import icon_unit_back2 from "reading/icon_unit_back2.png";
import icon_unit_back3 from "reading/icon_unit_back3.png";
import icon_unit_item0 from "reading/icon_unit_item0.png";
import icon_unit_item1 from "reading/icon_unit_item1.png";
import icon_unit_item2 from "reading/icon_unit_item2.png";
import icon_unit_item3 from "reading/icon_unit_item3.png";
import icon_remind_time from "reading/icon_remind_time.png";
import icon_contact_teacher from "reading/icon_contact_teacher.png";
import icon_question_ask from "reading/icon_question_ask.png";
import icon_cancle_upper from "../../../../assets/images/icon_cancle_upper.png";
import icon_know from "c4/icon_know.png";
import icon_lock_img from "c4/icon_lock_img.png";
import Special from "../components/Special";
import Picker from "better-picker";
import icon_go_study_current from "reading/icon_go_study_current.png";
import icon_go_test_current from "reading/icon_go_test_current.png";
import icon_go_study_current1 from "reading/icon_go_study_current1.png";
import icon_go_test_current1 from "reading/icon_go_test_current1.png";
import icon_go_study_current2 from "reading/icon_go_study_current2.png";
import icon_go_test_current2 from "reading/icon_go_test_current2.png";
import icon_go_study_current3 from "reading/icon_go_study_current3.png";
import icon_go_test_current3 from "reading/icon_go_test_current3.png";
// import RenderButtom from "../../day_studies/courses/common/RenderButtom";
import "./ReadingIndex.scss";
import UpdateApp from "../../study_packages/term_review/components/UpdateApp";
import { ToastManager } from "../../utils/Toast";
import PdfDownloadDialog from "@/components/shared/PdfDownloadDialog";
import { DialogManager } from "@/components/utils/Dialog";
import icon_crystal from "point_rewards/crystal.png";

export const unit_setting = [
  {
    unitBack: icon_unit_back0,
    unitbackColor: "#FFF9E7",
    unitTitle: icon_unit_title0,
    minColor: "#FF973C",
    unitItem: icon_unit_item0,
    iconStudy: icon_go_study_current,
    iconTest: icon_go_test_current,
    specialBorder: "rgba(255, 151, 60, 0.30196078431372547)",
    boxShadow: "0px 2px 4px rgba(255, 186, 68, 0.5)",
    textShadow:
      " 0.7445120215415955px 0.7445120215415955px 0.7445120215415955px #E2A52B",
    indexShadow: "1px 1px 1px #E57D15",
  },
  {
    unitBack: icon_unit_back1,
    unitbackColor: "#E3F6FF",
    unitTitle: icon_unit_title1,
    minColor: "#5AC2F9",
    unitItem: icon_unit_item1,
    iconStudy: icon_go_study_current1,
    iconTest: icon_go_test_current1,
    specialBorder: "rgba(90, 194, 249, 0.30196078431372547)",
    boxShadow: "0px 2px 4px rgba(90, 194, 249, 0.5)",
    textShadow:
      " 0.7445120215415955px 0.7445120215415955px 0.7445120215415955px #2DA5E6;",
    indexShadow: "1px 1px 1px #3F91FD",
  },
  {
    unitBack: icon_unit_back2,
    unitbackColor: "#FFEEEE",
    unitTitle: icon_unit_title2,
    minColor: "#F26266",
    unitItem: icon_unit_item2,
    iconStudy: icon_go_study_current2,
    iconTest: icon_go_test_current2,
    specialBorder: "rgba(242, 98, 102, 0.30196078431372547)",
    boxShadow: "0px 2px 4px rgba(242, 98, 102, 0.5)",
    textShadow:
      " 0.7445120215415955px 0.7445120215415955px 0.7445120215415955px #EB4664",
    indexShadow: " 1px 1px 1px #FF5055",
  },
  {
    unitBack: icon_unit_back3,
    unitbackColor: "#EAEFFF",
    unitTitle: icon_unit_title3,
    minColor: "#6E85E6", //2px solid #5AC2F9
    unitItem: icon_unit_item3,
    iconStudy: icon_go_study_current3,
    iconTest: icon_go_test_current3,
    specialBorder: "rgba(110, 133, 230, 0.30196078431372547)",
    boxShadow: "0px 2px 4px rgba(110, 133, 230, 0.5)",
    textShadow:
      " 0.7445120215415955px 0.7445120215415955px 0.7445120215415955px #2D78D9;",
    indexShadow: "1px 1px 1px #6E85E6;",
  },
];

const settings = [
  {
    name: "联系老师",
    icon: icon_contact_teacher,
    imgBack: "#FFDABB",
    titleBack: "#FF973C",
    imgWidth: 40,
    imgHeight: 30,
  },
  {
    name: "上课提醒设置",
    icon: icon_remind_time,
    imgBack: "#FFD2BE",
    titleBack: "#FF8269",
    imgWidth: 37,
    imgHeight: 38,
  },
  {
    name: "常见问题",
    icon: icon_question_ask,
    imgBack: "#DAE0FF",
    titleBack: "#8FA5FF",
    imgWidth: 43,
    imgHeight: 45,
  },
];
export const idexUper = [
  "一",
  "二",
  "三",
  "四",
  "五",
  "六",
  "七",
  "八",
  "九",
  "十",
  "十一",
  "十二",
  "十三",
  "十四",
  "十五",
  "十六",
  "十七",
  "十八",
  "十九",
  "二十",
  "二十一",
  "二十二",
  "二十三",
  "二十四",
  "二十五",
  "二十六",
  "二十七",
  "二十八",
  "二十九",
  "三十",
  "三十一",
  "三十二",
  "三十三",
  "三十四",
  "三十五",
  "三十六",
  "三十七",
  "三十八",
  "三十九",
  "四十",
  "四十一",
  "四十二",
  "四十三",
  "四十四",
  "四十五",
  "四十六",
  "四十七",
  "四十八",
  "四十九",
  "五十",
];
export const UnitSpecialIndex = styled.div`
  font-size: 18px;
  font-family: PingFang SC;
  font-weight: bold;
  line-height: 23px;
  height: 23px;
  color: #ffffff;
  border-radius: 8px;
  white-space: nowrap;
  padding: 0 6px 0 6px;
  background: ${({ index }) =>
    index == 0 ? unit_setting[0].minColor : unit_setting[index % 4].minColor};
`;
export const UnitSpecial = styled.div`
  position: relative;
  border-radius: 15px;
  overflow: hiden;
  transform-style: preserve-3d;
  box-shadow: ${({ index }) =>
    index == 0 ? unit_setting[0].boxShadow : unit_setting[index % 4].boxShadow};
  border: 2px solid
    ${({ index }) =>
      index == 0 ? unit_setting[0].minColor : unit_setting[index % 4].minColor};
  padding: 53px 0px 0px;
  margin-bottom: ${({ isLast }) => (isLast ? "" : "78px")};
  background: #fff;
  //   border-top: 0;
  &::before {
    content: "";
    position: absolute;
    left: 50%;

    top: -38px;
    background: #fff;
    width: 255px;
    height: 68px;
    border-radius: 32px;
    box-shadow: ${({ index }) =>
      index == 0
        ? unit_setting[0].boxShadow
        : unit_setting[index % 4].boxShadow};
    border: 2px solid
      ${({ index }) =>
        index == 0
          ? unit_setting[0].minColor
          : unit_setting[index % 4].minColor};
    transform: translateX(-50%);
    // width: calc(100% + 34px);
  }
  &::after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: ${({ paddingTop }) => paddingTop};
    border-radius: 15px;
    background: #fff;
    // transform: translate3d(0px, 0px, 1px);
  }
`;
export const UnitTitle = styled.div`
  //   margin: 0 10px;
  width: 326px;
  height: 69px;
  background-image: url(${({ index }) =>
    index == 0
      ? unit_setting[0].unitTitle
      : unit_setting[index % 4].unitTitle});
  background-repeat: no-repeat;
  background-size: 100%;
  margin: 0 auto;
  margin-bottom: 58px;
  position: relative;
`;

const UnitBack = styled.div`
  position: relative;
  padding: 32px 15px 88px;
  background-color: ${({ index }) =>
    index == 0
      ? unit_setting[0].unitbackColor
      : unit_setting[index % 4].unitbackColor};
  &::before {
    content: "";
    width: 100%;
    height: 28px;
    position: absolute;
    top: -28px;
    left: 0px;
    background-image: url(${({ index }) =>
      index == 0
        ? unit_setting[0].unitBack
        : unit_setting[index % 4].unitBack});
    background-repeat: no-repeat;
    background-size: 100% 100%;
  }
`;
export const days = [
  "第1天: 互动课堂",
  "第2天: 考场演练",
  "第3天: 经典阅读",
  "单元练习",
  "第1天: 综合测试",
  "综合测试",
];

export const DateHourData = [
  { text: "不提醒", value: -1 },
  { text: "00点", value: 0 },
  { text: "01点", value: 1 },
  { text: "02点", value: 2 },
  { text: "03点", value: 3 },
  { text: "04点", value: 4 },
  { text: "05点", value: 5 },
  { text: "06点", value: 6 },
  { text: "07点", value: 7 },
  { text: "08点", value: 8 },
  { text: "09点", value: 9 },
  { text: "10点", value: 10 },
  { text: "11点", value: 11 },
  { text: "12点", value: 12 },
  { text: "13点", value: 13 },
  { text: "14点", value: 14 },
  { text: "15点", value: 15 },
  { text: "16点", value: 16 },
  { text: "17点", value: 17 },
  { text: "18点", value: 18 },
  { text: "19点", value: 19 },
  { text: "20点", value: 20 },
  { text: "21点", value: 21 },
  { text: "22点", value: 22 },
  { text: "23点", value: 23 },
];

const setRemainderPicker = (url, reminder_time_hour) => {
  // console.log(reminder_time_hour, "66666666666");
  // const nameEl = dom;
  const picker = new Picker({
    data: [DateHourData],
    selectedIndex: [reminder_time_hour + 1],
    title: "",
  });

  // const config_reminder_time_url = props.config_reminder_time_url;
  // const reminder_time_hour = props.reminder_timeHour;
  picker.on("picker.select", function (selectedVal, selectedIndex) {
    // nameEl.innerText = date_hour_data[selectedIndex[0]].text;
    console.log(selectedIndex, "66666666666");
    // if (selectedIndex[0] - 1 != reminder_time_hour) {
    let params = {
      reminder_hour: selectedIndex[0] - 1,
    };
    // var url = config_reminder_time_url;
    request({
      url: url,
      method: "POST",
      headers: csrfHeaders,
      data: params,
    })
      .then((resp) => {
        window.location.reload();
        // this.props.settings.map(()=>{

        // })
        console.log("正确返回");
      })
      .catch((err) => {
        console.log(err);
      });
    // }
  });
  picker.show();
  // if (props.reminder_time_hour >= -1) {
  //   const time_setup_click = document.getElementById("time_setup_click");

  //   if (time_setup_click) {
  //     time_setup_click.addEventListener("click", function () {
  //       picker.show();
  //       if (UserAgent.isNativeClient()) {
  //         XinYaNativeBridge.requestAuthorization({
  //           authType: "notification",
  //         });
  //       }
  //     });
  //   }
  // }
};

export default class Index extends React.Component {
  constructor(props) {
    console.log(props, "readinggggggggprops");
    super(props);
    this.topicIndex = 0;
    this.state = {
      showLockModel: false,
      readingData: null,
    };
  }
  componentDidMount() {
    this.toast = ToastManager.showLoading("加载中...");
    this.props
      .getData(this.props.getDataUrl, this.props.getDataId)
      .then((res) => {
        console.log("===--===========", res);
        this.setState(
          {
            readingData: res,
          },
          () => {
            this.toast.cancel();
            if ((this.props.study_mode_version !== 4 && this.props.study_mode_version !== 5) || this.props.study_started) {
              this.goStudy(false, "currect_id");
            }
          }
        );
      });
  }
  componentWillUnmount() {
    this.toast.cancel();
  }
  goStudy(flag, id) {
    const {
      get_study_package_units_study,
      current_study_date,
      current_study_day,
    } = this.props;
    let currectItem = document.getElementById(id || current_study_date || "");
    console.log(id || current_study_date || "", "=============", currectItem);
    setTimeout(() => {
      if (currectItem) {
        if (currectItem.scrollIntoView) {
          currectItem.scrollIntoView({
            // block: "star",
            inline: "nearest",
            behavior: "smooth",
          });
        } else {
          console.log(
            "currectItem.offsetTop-------------------------",
            currectItem.offsetTop
          );
          window.scrollTo(0, currectItem.offsetTop - 200);
        }
      }
    }, 500);
    //   document.getElementById(id || current_study_date || "")?.scrollIntoView({
    //     block: "center",
    //     inline: "nearest",
    //     behavior: "smooth",
    //   });
    // }, 500);

    // let currectItem = document.getElementById(id || current_study_date || "");
    // if (flag || (currectItem && current_study_day !== 5)) {
    //   console.log(currectItem, "================000000", currectItem.offsetTop);
    //   setTimeout(() => {
    //     // window.scrollBy({
    //     //   top: 0,
    //     // });
    //     // $("#scroll-box").scrollTop(currectItem.offsetTop - 200);
    //     window.scrollTo(0, currectItem.offsetTop - 200);
    //   }, 1000);
    // }
  }
  contiuneStudy(bottom, isFirst) {
    let text, fontSize, showIcon;
    if ((this.props.study_mode_version === 4 || this.props.study_mode_version === 5) &&
      this.state.readingData?.has_point_reward &&
      this.topicIndex === 0 &&
      this.state.readingData?.point_double_on_first_day) {
      text = '今天学习，奖励双倍';
      fontSize = 13;
      showIcon = true;
    } else {
      text = '上次学到这里，继续';
      fontSize = 12;
      showIcon = false;
    }
    return bottom ? (
      <div
        className="contiune-study"
        id="currect_id"
        // id={this.props.current_study_date}
        style={{
          marginBottom: bottom ? bottom : "",
          // marginTop: isFirst ? -36 : "",
          fontSize,
        }}
      >
        {text}
        {showIcon && <img src={icon_crystal} style={{ width: 18, height: 18, display: 'inline-block' }} />}
      </div>
    ) : (
      ""
    );
  }

  // getDayStatr(pass, rank) {
  //   if (pass) {
  //     let starArr = [];
  //     for (let index = 0; index < 3; index++) {
  //       if (index < rank) {
  //         starArr.push(true);
  //       } else {
  //         starArr.push(false);
  //       }
  //     }
  //     return (
  //       <>
  //         {starArr.map((star_item, star_index) => {
  //           return (
  //             <img
  //               src={star_item ? star_light : star_gery}
  //               key={star_index + "s"}
  //             ></img>
  //           );
  //         })}
  //       </>
  //     );
  //   } else {
  //     return "";
  //   }
  // }

  renderStudyStateLabel() {
    if (this.props.study_mode_version === 4 || this.props.study_mode_version === 5) {
      switch (this.props.study_state) {
        case 0:
          return '开始打卡';
        case 1:
          return '继续打卡';
        default:
          return '已完成，继续下次打卡';
      }
    } else {
      switch (this.props.study_state) {
        case 0:
          return '开始学习';
        case 1:
          return '继续学习';
        default:
          return '已完成,再学一课';
      }
    }
  }

  render() {
    this.topicIndex = 0;
    let hiddenCount = 0;
    if (!this.state.readingData) {
      return ("");
    }
    const study_package_modules = this.state.readingData.study_package_modules;
    const showUnitTitle = study_package_modules && study_package_modules.length > 1;
    return (
      <div className="reading-index1" id="scroll-box">
        <div style={{ height: 50 }}></div>
        <div className="scroll-bar">
          {showUnitTitle && study_package_modules.map((itm, index) => {
              return (
                <div
                  key={itm.id + "=" + index}
                  className="scroll-bar-item"
                  onClick={() => {
                    this.goStudy(true, "unit" + itm.id);
                  }}
                >
                  <img src={itm.navigate_photo_url}></img>
                  <div className="scroll-bar-item-name">{itm.name}</div>
                </div>
              );
            })}
        </div>
        {(this.props.study_mode_version === 4 || this.props.study_mode_version === 5) && !this.props.study_started &&
          <div className="contiune-study" style={{ marginTop: 20, marginBottom: -10 }}>请先完成课前准备</div>
        }
        <div className="course-list-header">
          <div className="course-list-header-title">
            <img src={icon_know}></img>
            开课须知
            <img src={icon_know}></img>
          </div>
          <div className="course-list-header-body">
            {this.props.settings.map((item, index) => {
              if (item.hidden) {
                hiddenCount += 1;
                return "";
              } else {
                let idx = index - hiddenCount;
                return (
                  <div
                    key={item.icon}
                    className="header-body-item"
                    onClick={() => {
                      if (item.title === "上课提醒设置") {
                        setRemainderPicker(item.url, item.reminder_hour);
                      } else if (item.type === 'download') {
                        let dialog;
                        dialog = DialogManager.open(
                          <PdfDownloadDialog
                            unitPdfUrl={item.url}
                            unitName="配套资料"
                            onClose={() => dialog.close()}
                            is_android_os={this.props.is_android_os}
                          />
                        );
                      } else {
                        window.location.href = item.url;
                      }
                    }}
                  >
                    <div className="item-style">
                      <div
                        className="item-style-top"
                        style={{ backgroundColor: settings[idx].imgBack }}
                      >
                        <div
                          className="item-style-top-center"
                          style={{ backgroundColor: settings[idx].titleBack }}
                        >
                          <img
                            style={{
                              width: settings[idx].imgWidth,
                              height: settings[idx].imgHeight,
                            }}
                            src={item.icon}
                          ></img>
                        </div>
                      </div>

                      <div
                        className="item-style-bottom"
                        style={{ backgroundColor: settings[idx].titleBack }}
                      >
                        {item.title}
                      </div>
                    </div>
                  </div>
                );
              }
            })}
          </div>
        </div>
        {study_package_modules && study_package_modules.map((item, index) => {
            return (
              <UnitBack
                index={index}
                key={item.id + "unit"}
                id={"unit" + item.id}
              >
                <div style={{ marginTop: index == 0 ? -32 : "" }}>
                  {showUnitTitle &&
                      <UnitTitle
                        className="unit-title"
                        index={index}
                        // id={"unit" + item.id}
                      >
                        <div
                          className={
                            index >= 10
                              ? "unit-title-index unit-title-index-s"
                              : "unit-title-index"
                          }
                          style={{
                            textShadow: ({ index }) =>
                              index == 0
                                ? unit_setting[0].indexShadow
                                : unit_setting[index % 4].indexShadow,
                          }}
                        >{`单元${idexUper[index]}`}</div>
                        <div
                          className="unit-title-name"
                          style={{
                            textShadow: ({ index }) =>
                              index == 0
                                ? unit_setting[0].textShadow
                                : unit_setting[index % 4].textShadow,
                          }}
                        >
                          {item.name}
                        </div>
                      </UnitTitle>
                  }
                  {console.log(
                    item.study_topics,
                    item.study_topics[0]?.day_studies,
                    "==  this.props.current_study_date=",
                    // item.study_topics[0]?.day_studies[0],
                    this.props.current_study_date
                  )}
                  {item.study_topics &&
                  item.study_topics.length > 0 &&
                  item.study_topics[0].day_studies &&
                  item.study_topics[0].day_studies[0] &&
                  ((this.props.study_mode_version !== 4 && this.props.study_mode_version !== 5) || this.props.study_started) &&
                  this.props.current_study_date ==
                    item.study_topics[0]?.day_studies[0][0]?.date ? (
                    <div className="unit-items" style={{ marginTop: -34 }}>
                      {this.contiuneStudy(57)}
                    </div>
                  ) : (
                    !showUnitTitle && index === 0 ? <div style={{ width: '100%', height: '40px' }}></div> : ""
                  )}
                  {item.study_topics &&
                    item.study_topics.map((topic, topic_index) => {
                      if (
                        topic.day_studies[0] &&
                        topic.day_studies[0][0]?.study_type !== 4 &&
                        topic.day_studies[0][0]?.study_type !== 6
                      ) {
                        this.topicIndex++;
                      }

                      return (
                        <React.Fragment key={topic.id + "topic"}>
                          {topic &&
                          topic_index &&
                          topic.day_studies &&
                          topic.day_studies[0] &&
                          ((this.props.study_mode_version !== 4 && this.props.study_mode_version !== 5) || this.props.study_started) &&
                          topic.day_studies[0][0]?.date ==
                            this.props.current_study_date ? (
                            <div
                              className="unit-items"
                              style={{ marginTop: -34 }}
                            >
                              {this.contiuneStudy(57)}
                            </div>
                          ) : (
                            ""
                          )}
                          {topic && topic.day_studies && topic.day_studies[0] && (
                            <Special
                              {...topic}
                              unitIndex={index == 0 ? index : index % 4}
                              topicIndex={this.topicIndex}
                              isLast={
                                topic_index == item.study_topics.length - 1
                                  ? true
                                  : false
                              }
                              totalSpecial={item.study_topics.length}
                              showModel={(flag) => {
                                this.setState({
                                  showLockModel: true,
                                  // LockModelText: flag
                                  //   ? "课程尚未到开课时间哦"
                                  //   : "",
                                });
                              }}
                              current_study_date={this.props.current_study_date}
                              current_study_day={this.props.current_study_day}
                              study_mode_version={this.props.study_mode_version}
                              study_mode={this.props.study_mode}
                              has_point_reward={this.state.readingData?.has_point_reward}
                              point_double_on_first_day={this.state.readingData?.point_double_on_first_day}
                              without_comment={this.props.without_comment}
                              is_new_reading_comment={this.props.is_new_reading_comment}
                              show_people_profile={this.props.show_people_profile}
                            ></Special>
                          )}
                        </React.Fragment>
                      );
                    })}
                </div>
              </UnitBack>
            );
          })}
        {(this.state.showLockModel || this.state.LockModelText) && (
          <div className="study-lock-back">
            <div className="study-lock-model">
              <img src={icon_lock_img}></img>
              <div className="study-lock-model-tips">
                {/* {this.state.LockModelText || "请先完成前面的内容哦"} */}
                {"请等到开课日期，或先完成前面的内容哦"}
              </div>
              <div
                className="render-bottom"
                onClick={() => {
                  const { showLockModel, LockModelText } = this.state;
                  this.setState(
                    { showLockModel: false, LockModelText: "" },
                    () => {
                      // if (!LockModelText) {
                      //   this.goStudy(true);
                      // }
                    }
                  );
                }}
              >
                {/* {this.state.LockModelText ? "知道了" : "去完成"} */}
                {"知道了"}
              </div>
              {/* <div
                className="cancle"
                onClick={() => {
                  this.setState({
                    showLockModel: false,
                    LockModelText: "",
                  });
                }}
              >
                <img src={icon_cancle_upper}></img>
              </div> */}
            </div>
          </div>
        )}

        {this.props.upgrade_app_url ? (
          <UpdateApp upgrade_app_url={this.props.upgrade_app_url}></UpdateApp>
        ) : (
          ""
        )}
        {this.props.study_url ? (
          <div className="start-study">
            <div
              className="start-study-connext"
              style={{
                background:
                  this.props.study_state == 0 || this.props.study_state == 1
                    ? "#FF973C"
                    : "#FFBA44",
                // boxShadow:
                //   this.props.study_state == 0 || this.props.study_state == 1
                //     ? "0px 4px 0px #39ACE9"
                //     : "0px 4px 0px #E6950B",
              }}
              onClick={() => {
                if (study_package_modules && study_package_modules.length > 0) {
                  let topics = study_package_modules[0].study_topics;
                  if (topics && topics.length > 0) {
                    let day_studies = topics[0].day_studies;
                    if (day_studies && day_studies.length > 0 && day_studies[0] && day_studies[0].length > 0 && day_studies[0][0].day_lock) {
                      this.setState({
                        showLockModel: true,
                      });
                      return;
                    }
                  }
                }
                window.location.href = this.props.study_url;
              }}
            >
              {this.renderStudyStateLabel()}
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
    );
  }
}
