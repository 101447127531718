// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../shared/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../../../../shared/node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../../assets/images/study_package/btn_close.png");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../../../../assets/images/arrow-right-white.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
exports.push([module.id, ".magnifier-with-video .content{position:absolute;top:60px;left:0;right:0;bottom:110px;display:-webkit-flex;display:flex;-webkit-align-items:center;align-items:center}.magnifier-with-video .content .player,.magnifier-with-video .content video{width:100%;height:100%}.magnifier-with-video .btn-close{position:absolute;top:15px;left:15px;width:32px;height:32px;color:#fff;background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat;background-size:32px}.magnifier-with-video .btn-next,.magnifier-with-video .btn-prev{display:block;width:24px;height:24px;background:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") no-repeat}.magnifier-with-video .btn-prev{-webkit-transform:rotate(180deg);transform:rotate(180deg)}.magnifier-with-video .content-image{position:absolute;top:50%;left:50%;-webkit-transform:translate(-50%,-50%);transform:translate(-50%,-50%)}", ""]);
// Exports
module.exports = exports;
